import React from 'react';
import {
  Box,
  Card,
  Typography,
  Grid,
  LinearProgress,
  Stack
} from '@mui/material';
import { companyData } from '../../../data/company';

const StatCard = ({ label, value, total, unit }: { label: string; value: number; total?: number; unit?: string }) => {
  const percentage = total ? (value / total) * 100 : null;

  return (
    <Box>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        {label}
      </Typography>
      <Typography variant="h5" sx={{ mb: 1 }}>
        {value}{unit}
        {total && (
          <Typography component="span" variant="body2" color="text.secondary" sx={{ ml: 1 }}>
            of {total}
          </Typography>
        )}
      </Typography>
      {percentage !== null && (
        <>
          <LinearProgress 
            variant="determinate" 
            value={percentage} 
            sx={{ 
              height: 8, 
              borderRadius: 1,
              bgcolor: 'grey.100',
              '& .MuiLinearProgress-bar': {
                borderRadius: 1,
                bgcolor: 'primary.main'
              }
            }} 
          />
          <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5, display: 'block' }}>
            {percentage.toFixed(0)}% complete
          </Typography>
        </>
      )}
    </Box>
  );
};

const CompanyStats = () => {
  const { stats } = companyData;

  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Company Statistics
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <StatCard 
              label="Total Projects" 
              value={stats.completedProjects} 
              total={stats.totalProjects}
            />
            <StatCard 
              label="Total Units" 
              value={stats.soldUnits} 
              total={stats.totalUnits}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <StatCard 
              label="Ongoing Projects" 
              value={stats.ongoingProjects}
            />
            <StatCard 
              label="Delivery Rate" 
              value={stats.deliveryRate} 
              unit="%"
            />
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CompanyStats; 