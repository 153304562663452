import React from 'react';
import {
  Box,
  Paper,
  Typography,
  LinearProgress,
  Grid,
  Chip,
  Stack,
  Avatar,
  Badge
} from '@mui/material';
import {
  School as SchoolIcon,
  EmojiEvents as TrophyIcon,
  Stars as StarsIcon,
  Timeline as TimelineIcon,
  TrendingUp as TrendingUpIcon
} from '@mui/icons-material';
import { CourseProgress, Achievement } from '../../types/training';

interface ProgressBlockProps {
  progress: CourseProgress;
  achievements: Achievement[];
  totalScore: number;
  level: string;
}

const ProgressBlock: React.FC<ProgressBlockProps> = ({
  progress,
  achievements,
  totalScore,
  level
}) => {
  const completionPercentage = (progress.completed / progress.enrolled) * 100;

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Ваш прогресс
      </Typography>

      <Grid container spacing={3}>
        {/* Основные показатели */}
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <Box>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Общий прогресс
              </Typography>
              <LinearProgress
                variant="determinate"
                value={completionPercentage}
                sx={{ mb: 1, height: 8, borderRadius: 1 }}
              />
              <Typography variant="body2" color="text.secondary">
                {Math.round(completionPercentage)}% завершено
              </Typography>
            </Box>

            <Box>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Средний балл
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TrendingUpIcon color={progress.averageScore >= 80 ? 'success' : 'primary'} />
                <Typography>
                  {progress.averageScore} из 100
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Grid>

        {/* Статистика */}
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              <Chip
                icon={<SchoolIcon />}
                label={`Пройдено курсов: ${progress.completed}`}
                color="primary"
                variant="outlined"
              />
              <Chip
                icon={<TimelineIcon />}
                label={`Всего баллов: ${totalScore}`}
                color="secondary"
                variant="outlined"
              />
              <Chip
                icon={<StarsIcon />}
                label={`Уровень: ${level}`}
                color="info"
                variant="outlined"
              />
            </Box>
          </Stack>
        </Grid>

        {/* Достижения */}
        {achievements.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
              Полученные достижения
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
              {achievements.map((achievement) => (
                <Badge
                  key={achievement.id}
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <Avatar sx={{ width: 22, height: 22, bgcolor: 'success.main' }}>
                      <Typography variant="caption">{achievement.points}</Typography>
                    </Avatar>
                  }
                >
                  <Chip
                    icon={<TrophyIcon />}
                    label={achievement.title}
                    title={achievement.description}
                    sx={{
                      '& .MuiChip-icon': {
                        color: 'primary.main'
                      }
                    }}
                  />
                </Badge>
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default ProgressBlock; 