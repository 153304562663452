import { useState, useEffect } from 'react';
import { Unit, UnitFilters } from '../types/unit';
import { generateUnits } from '../utils/mockDataGenerator';

export const useUnits = (filters: UnitFilters) => {
  const [units, setUnits] = useState<Unit[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUnits = async () => {
      setLoading(true);
      try {
        const allUnits = generateUnits();
        let filteredUnits = [...allUnits];

        // Применяем фильтры
        if (filters.building) {
          filteredUnits = filteredUnits.filter(unit => unit.building === filters.building);
        }
        
        if (filters.availability !== 'all') {
          filteredUnits = filteredUnits.filter(unit => unit.status === filters.availability);
        }

        if (filters.bedrooms !== 'all') {
          filteredUnits = filteredUnits.filter(unit => 
            unit.bedrooms === parseInt(filters.bedrooms)
          );
        }

        if (filters.windowView !== 'all') {
          filteredUnits = filteredUnits.filter(unit => 
            unit.windowView === filters.windowView
          );
        }

        if (filters.furnished !== 'all') {
          filteredUnits = filteredUnits.filter(unit => 
            filters.furnished === 'yes' ? unit.furnished : !unit.furnished
          );
        }

        setUnits(filteredUnits);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch units');
      } finally {
        setLoading(false);
      }
    };

    fetchUnits();
  }, [filters]);

  return { units, loading, error };
}; 