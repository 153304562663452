import React from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Stack,
  Avatar,
  Chip,
  IconButton,
  Button,
  Divider
} from '@mui/material';
import {
  Close as CloseIcon,
  LocationOn,
  Person,
  Home,
  AccessTime,
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import { CalendarEvent } from '../../types/calendar';
import { format } from 'date-fns';

interface EventDetailsModalProps {
  event: CalendarEvent | null;
  open: boolean;
  onClose: () => void;
  onEdit: (event: CalendarEvent) => void;
  onDelete: (eventId: string) => void;
}

const EventDetailsModal = ({ event, open, onClose, onEdit, onDelete }: EventDetailsModalProps) => {
  if (!event) return null;

  const eventColors = {
    viewing: '#7F56D9',
    meeting: '#12B76A',
    reservation: '#F79009',
    signing: '#0BA5EC',
    payment: '#F04438',
    handover: '#6941C6'
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ 
          p: 3,
          bgcolor: `${eventColors[event.type]}10`,
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Chip
              label={event.type.toUpperCase()}
              sx={{
                bgcolor: `${eventColors[event.type]}20`,
                color: eventColors[event.type],
                fontWeight: 600
              }}
            />
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {event.title}
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AccessTime sx={{ fontSize: 20, color: 'grey.500' }} />
              <Typography variant="body2">
                {format(new Date(event.start), 'MMM d, HH:mm')} - 
                {format(new Date(event.end), ' HH:mm')}
              </Typography>
            </Box>
            {event.location && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LocationOn sx={{ fontSize: 20, color: 'grey.500' }} />
                <Typography variant="body2">{event.location}</Typography>
              </Box>
            )}
          </Stack>
        </Box>

        <Box sx={{ p: 3 }}>
          <Stack spacing={3}>
            {event.clientName && (
              <Box>
                <Typography variant="subtitle2" color="grey.600" gutterBottom>
                  Client
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Person sx={{ fontSize: 20, color: 'grey.500' }} />
                  <Typography>{event.clientName}</Typography>
                </Stack>
              </Box>
            )}

            {event.propertyName && (
              <Box>
                <Typography variant="subtitle2" color="grey.600" gutterBottom>
                  Property
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Home sx={{ fontSize: 20, color: 'grey.500' }} />
                  <Typography>{event.propertyName}</Typography>
                </Stack>
              </Box>
            )}

            {event.description && (
              <Box>
                <Typography variant="subtitle2" color="grey.600" gutterBottom>
                  Description
                </Typography>
                <Typography>{event.description}</Typography>
              </Box>
            )}

            <Box>
              <Typography variant="subtitle2" color="grey.600" gutterBottom>
                Assignee
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Avatar
                  src={event.assignee.avatar}
                  sx={{ width: 32, height: 32 }}
                />
                <Typography>{event.assignee.name}</Typography>
              </Stack>
            </Box>
          </Stack>
        </Box>

        <Divider />

        <Box sx={{ p: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => onDelete(event.id)}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() => onEdit(event)}
          >
            Edit
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EventDetailsModal; 