import React from 'react';
import { Card, Box, Stack, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyledCardProps {
  index: number;
}

const SkeletonCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'index'
})<StyledCardProps>(({ theme, index }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  borderRadius: theme.shape.borderRadius * 2,
  transform: `scale(${1 - index * 0.05}) translateY(${index * 10}px)`,
  opacity: 1 - index * 0.2,
  transition: 'all 0.3s ease',
}));

const SwipeCardSkeleton: React.FC = () => {
  return (
    <>
      {[0, 1, 2].map((index) => (
        <SkeletonCard key={index} index={index}>
          <Skeleton
            variant="rectangular"
            sx={{
              height: '100%',
              transform: 'none',
              animation: 'pulse 1.5s ease-in-out 0.5s infinite'
            }}
          />
          
          {/* Имитация контента */}
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              background: 'linear-gradient(to top, rgba(0,0,0,0.8), transparent)',
              padding: 2,
            }}
          >
            <Stack spacing={1}>
              <Skeleton variant="text" width="60%" height={32} sx={{ bgcolor: 'rgba(255,255,255,0.3)' }} />
              <Stack direction="row" spacing={1} alignItems="center">
                <Skeleton variant="circular" width={20} height={20} sx={{ bgcolor: 'rgba(255,255,255,0.3)' }} />
                <Skeleton variant="text" width="40%" height={24} sx={{ bgcolor: 'rgba(255,255,255,0.3)' }} />
              </Stack>
              <Skeleton 
                variant="rectangular" 
                width={100} 
                height={32} 
                sx={{ 
                  bgcolor: 'rgba(255,255,255,0.3)',
                  borderRadius: 1
                }} 
              />
            </Stack>
          </Box>
        </SkeletonCard>
      ))}
    </>
  );
};

export default SwipeCardSkeleton; 