import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const formatPrice = (value, currency) => {
  return `${currency}${value.toFixed(1)}M`;
};

const ProjectPrice = ({ price, discount }) => {
  const theme = useTheme();
  const { min, max, currency } = price;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Typography variant="h6" component="div">
        {formatPrice(min, currency)}–{formatPrice(max, currency)}
      </Typography>
      {discount && (
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.error.main,
            bgcolor: theme.palette.error.light,
            px: 1,
            py: 0.5,
            borderRadius: 1,
            fontWeight: 500,
          }}
        >
          -{discount.percent}%
        </Typography>
      )}
    </Box>
  );
};

export default ProjectPrice; 