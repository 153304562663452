import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';

const AMENITIES = [
  'Кондиционер',
  'Балкон',
  'Парковка',
  'Охрана',
  'Лифт',
  'Мебель',
  'Интернет',
  'Телевидение',
  'Холодильник',
  'Стиральная машина',
  'Посудомоечная машина',
  'Микроволновая печь',
  'Духовка',
  'Теплый пол',
  'Кладовка',
  'Гардеробная'
];

interface AmenitiesSelectProps {
  value: string[];
  onChange: (value: string[]) => void;
}

export const AmenitiesSelect = ({ value, onChange }: AmenitiesSelectProps) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const newValue = event.target.value;
    onChange(typeof newValue === 'string' ? newValue.split(',') : newValue);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Удобства</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label="Удобства" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {AMENITIES.map((amenity) => (
          <MenuItem key={amenity} value={amenity}>
            {amenity}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}; 