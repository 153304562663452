import React from 'react';
import { Box } from '@mui/material';
import { LineChart, Line, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';

const generateVolatileData = (isPositive) => {
  const baseValue = 100;
  const volatility = 30;
  return Array.from({ length: 12 }, (_, i) => ({
    value: baseValue + (isPositive ? 1 : -1) * (
      Math.sin(i * 0.5) * volatility + 
      Math.random() * volatility * 0.5
    )
  }));
};

const SmallChart = ({ isPositive }) => {
  const theme = useTheme();
  const data = generateVolatileData(isPositive);

  return (
    <Box sx={{ width: '100%', height: 40 }}>
      <ResponsiveContainer>
        <LineChart data={data}>
          <Line
            type="monotone"
            dataKey="value"
            stroke={isPositive ? theme.palette.success.main : theme.palette.error.main}
            strokeWidth={2}
            dot={false}
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default SmallChart; 