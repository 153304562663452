import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  Divider
} from '@mui/material';
import { TestData } from '../TestWizard';

interface FinalTestProps {
  testData: TestData;
  onBack: () => void;
}

const FinalTest: React.FC<FinalTestProps> = ({ testData, onBack }) => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {testData.propertyName}
      </Typography>
      
      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
        Тест для проверки знаний
      </Typography>

      <Divider sx={{ my: 3 }} />
      
      <Stack spacing={2} sx={{ mb: 3 }}>
        {testData.questions.map((question, index) => (
          <Card key={question.id}>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>
                {index + 1}. {question.question}
              </Typography>
              
              <Stack spacing={1} sx={{ ml: 2 }}>
                {question.options.map((option, optIndex) => (
                  <Typography key={optIndex} variant="body2">
                    {String.fromCharCode(65 + optIndex)}. {option}
                  </Typography>
                ))}
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>

      <Stack direction="row" spacing={2}>
        <Button onClick={onBack}>
          Назад
        </Button>
        <Button variant="contained" color="primary">
          Сохранить тест
        </Button>
      </Stack>
    </Box>
  );
};

export default FinalTest; 