import React from 'react';
import { Box, Skeleton } from '@mui/material';

const ProjectImage = ({ src, alt }) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  const handleImageLoad = () => {
    console.log('Image loaded:', src); // Для отладки
    setLoading(false);
  };

  const handleImageError = () => {
    console.error('Image failed to load:', src); // Для отладки
    setLoading(false);
    setError(true);
  };

  return (
    <Box 
      sx={{ 
        position: 'relative', 
        paddingTop: '56.25%',
        backgroundColor: 'grey.100',
        borderRadius: '8px 8px 0 0',
        overflow: 'hidden'
      }}
    >
      {loading && (
        <Skeleton 
          variant="rectangular" 
          sx={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }} 
        />
      )}
      <Box
        component="img"
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        onError={handleImageError}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          display: loading ? 'none' : 'block'
        }}
      />
      {error && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'grey.100',
            color: 'text.secondary'
          }}
        >
          Image not found
        </Box>
      )}
    </Box>
  );
};

export default ProjectImage; 