import { Box, Button, ImageList, ImageListItem, IconButton, Typography } from '@mui/material';
import { CloudUpload, Delete, AddPhotoAlternate } from '@mui/icons-material';

interface ImageUploadProps {
  images: File[];
  onChange: (files: File[]) => void;
}

export const ImageUpload = ({ images, onChange }: ImageUploadProps) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      onChange([...images, ...newFiles]);
    }
  };

  const handleDelete = (index: number) => {
    const newFiles = images.filter((_, i) => i !== index);
    onChange(newFiles);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <input
          type="file"
          multiple
          accept="image/*"
          style={{ display: 'none' }}
          id="image-upload"
          onChange={handleFileChange}
        />
        <label htmlFor="image-upload">
          <Button
            variant="outlined"
            component="span"
            startIcon={<CloudUpload />}
            sx={{ mr: 2 }}
          >
            Загрузить фото
          </Button>
        </label>
        <Typography variant="caption" color="text.secondary">
          Поддерживаются форматы: JPG, PNG. Максимальный размер: 5MB
        </Typography>
      </Box>

      {images.length > 0 ? (
        <ImageList sx={{ width: '100%', height: 'auto' }} cols={4} rowHeight={200} gap={8}>
          {images.map((file, index) => (
            <ImageListItem key={index} sx={{ position: 'relative' }}>
              <img
                src={URL.createObjectURL(file)}
                alt={`Preview ${index}`}
                loading="lazy"
                style={{ height: '200px', objectFit: 'cover', borderRadius: '4px' }}
              />
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  },
                }}
                size="small"
                onClick={() => handleDelete(index)}
              >
                <Delete sx={{ color: 'white' }} />
              </IconButton>
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <Box
          sx={{
            border: '2px dashed #ccc',
            borderRadius: 1,
            p: 3,
            textAlign: 'center',
            backgroundColor: '#f8f8f8',
          }}
        >
          <AddPhotoAlternate sx={{ fontSize: 48, color: '#999', mb: 1 }} />
          <Typography color="text.secondary">
            Перетащите фотографии сюда или нажмите кнопку "Загрузить фото"
          </Typography>
        </Box>
      )}
    </Box>
  );
}; 