import { ClientRequest } from '../types/clientRequest';

export const clientRequestsData: ClientRequest[] = [
  {
    id: '0001',
    contactInfo: {
      name: 'John Doe',
      phone: '+66 123 456 789'
    },
    agency: null,
    status: 'viewing_request',
    propertyId: 'P0001',
    assignee: {
      id: 1,
      name: 'Olivia Rhye',
      avatar: '/assets/images/avatars/olivia.jpg'
    },
    viewingDate: '12/11/24',
    reservationDate: null,
    lastUpdate: '01/11/24 14:30'
  },
  {
    id: '0002',
    contactInfo: {
      name: 'Alex Smith',
      phone: '+66 987 654 321'
    },
    agency: 'Agency B',
    status: 'viewed',
    propertyId: 'P0002',
    assignee: {
      id: 1,
      name: 'Olivia Rhye',
      avatar: '/assets/images/avatars/olivia.jpg'
    },
    viewingDate: '10/11/24',
    reservationDate: null,
    lastUpdate: '01/11/24 12:00'
  },
  {
    id: '0003',
    contactInfo: {
      name: 'Tom Johnson',
      phone: '+66 555 012 345'
    },
    agency: null,
    status: 'reservation',
    propertyId: 'P0003',
    assignee: {
      id: 2,
      name: 'Phoenix Baker',
      avatar: '/assets/images/avatars/phoenix.jpg'
    },
    viewingDate: '09/11/24',
    reservationDate: '15/11/24',
    lastUpdate: '01/11/24 11:45'
  },
  {
    id: '0004',
    contactInfo: {
      name: 'Anna White',
      phone: '+66 777 890 123'
    },
    agency: null,
    status: 'pending',
    propertyId: 'P0004',
    assignee: {
      id: 3,
      name: 'Lana Steiner',
      avatar: '/assets/images/avatars/lana.jpg'
    },
    viewingDate: '05/11/24',
    reservationDate: '20/11/24',
    lastUpdate: '01/11/24 09:30'
  },
  {
    id: '0005',
    contactInfo: {
      name: 'Peter Green',
      phone: '+66 888 765 432'
    },
    agency: null,
    status: 'signed',
    propertyId: 'P0005',
    assignee: {
      id: 4,
      name: 'Drew Cano',
      avatar: '/assets/images/avatars/drew.jpg'
    },
    viewingDate: '03/11/24',
    reservationDate: null,
    lastUpdate: '01/11/24 07:50'
  },
  {
    id: '0006',
    contactInfo: {
      name: 'Lucy Black',
      phone: '+66 999 876 543'
    },
    agency: null,
    status: 'paid',
    propertyId: 'P0006',
    assignee: {
      id: 1,
      name: 'Olivia Rhye',
      avatar: '/assets/images/avatars/olivia.jpg'
    },
    viewingDate: '02/11/24',
    reservationDate: '20/11/24',
    lastUpdate: '02/11/24 10:00'
  },
  {
    id: '0007',
    contactInfo: {
      name: 'Emily White',
      phone: '+66 321 654 98'
    },
    agency: 'Agency D',
    status: 'cancelled',
    propertyId: 'P0007',
    assignee: {
      id: 2,
      name: 'Phoenix Baker',
      avatar: '/assets/images/avatars/phoenix.jpg'
    },
    viewingDate: '01/11/24',
    reservationDate: '18/11/24',
    lastUpdate: '02/11/24 15:30'
  },
  {
    id: '0008',
    contactInfo: {
      name: 'Robert Black',
      phone: '+66 234 567 890'
    },
    agency: null,
    status: 'reserved',
    propertyId: 'P0008',
    assignee: {
      id: 5,
      name: 'Candice Wu',
      avatar: '/assets/images/avatars/candice.jpg'
    },
    viewingDate: '15/11/24',
    reservationDate: '22/11/24',
    lastUpdate: '02/11/24 12:45'
  },
  {
    id: '0009',
    contactInfo: {
      name: 'Sarah Green',
      phone: '+66 456 789 012'
    },
    agency: 'Agency E',
    status: 'closing',
    propertyId: 'P0009',
    assignee: {
      id: 1,
      name: 'Olivia Rhye',
      avatar: '/assets/images/avatars/olivia.jpg'
    },
    viewingDate: '09/11/24',
    reservationDate: null,
    lastUpdate: '02/11/24 11:00'
  },
  {
    id: '0010',
    contactInfo: {
      name: 'Michael Brown',
      phone: '+66 567 890 123'
    },
    agency: 'Agency F',
    status: 'viewing_request',
    propertyId: 'P0010',
    assignee: {
      id: 3,
      name: 'Lana Steiner',
      avatar: '/assets/images/avatars/lana.jpg'
    },
    viewingDate: '18/11/24',
    reservationDate: null,
    lastUpdate: '02/11/24 09:15'
  },
  {
    id: '0011',
    contactInfo: {
      name: 'David Wilson',
      phone: '+66 678 901 234'
    },
    agency: 'Agency G',
    status: 'pending',
    propertyId: 'P0011',
    assignee: {
      id: 4,
      name: 'Drew Cano',
      avatar: '/assets/images/avatars/drew.jpg'
    },
    viewingDate: '20/11/24',
    reservationDate: '25/11/24',
    lastUpdate: '02/11/24 14:20'
  },
  {
    id: '0012',
    contactInfo: {
      name: 'Emma Davis',
      phone: '+66 789 012 345'
    },
    agency: null,
    status: 'viewed',
    propertyId: 'P0012',
    assignee: {
      id: 5,
      name: 'Candice Wu',
      avatar: '/assets/images/avatars/candice.jpg'
    },
    viewingDate: '08/11/24',
    reservationDate: null,
    lastUpdate: '02/11/24 16:45'
  }
]; 