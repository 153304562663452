import { Unit, UnitLayout, UnitStatus, WindowView } from '../types/unit';
import { Layout } from '../types/building';

// В начале файла добавим функцию generateId
const generateId = (): string => {
  return Math.random().toString(36).substring(2, 15) + 
         Math.random().toString(36).substring(2, 15);
};

// Функция для генерации номера юнита на основе этажа и позиции
const generateUnitNumber = (floor: number, position: number): string => {
  return `${floor.toString().padStart(2, '0')}${position.toString().padStart(2, '0')}`;
};

// Функция для генерации цены на основе этажа и площади
const generatePrice = (floor: number, area: number): number => {
  const basePrice = 150000; // Базовая цена за м²
  const floorMultiplier = 1 + (floor * 0.01); // Повышение цены на 1% за этаж
  return Math.round(area * basePrice * floorMultiplier);
};

// Шаблоны планировок для разных типов юнитов
const UNIT_LAYOUTS = {
  "studio": {
    area: [35, 40],
    bedrooms: 0,
    path: `M 0,0 h 250 v 300 h -250 Z
      M 20,20 h 210 v 180 h -210 Z
      M 20,220 h 80 v 60 h -80 Z
      M 120,220 h 110 v 60 h -110 Z`,
    rooms: {
      mainRoom: { area: 25.5, label: 'Main Room' },
      bathroom: { area: 4.8, label: 'Bathroom' },
      balcony: { area: 5.0, label: 'Balcony' }
    }
  },
  "1-bedroom": {
    area: [40.27, 45.27],
    bedrooms: 1,
    path: `M 0,0 h 300 v 400 h -300 Z
      M 0,150 h 300
      M 150,0 v 400
      M 20,20 h 110 v 110 h -110 Z
      M 20,130 h 110 v 150 h -110 Z
      M 170,20 h 110 v 180 h -110 Z
      M 170,220 h 50 v 60 h -50 Z
      M 230,220 h 50 v 60 h -50 Z
      M 20,300 h 260 v 80 h -260 Z`,
    rooms: {
      livingRoom: { area: 17.45, label: 'Living Room' },
      bedroom: { area: 14.12, label: 'Bedroom' },
      kitchen: { area: 4.06, label: 'Kitchen' },
      bathroom: { area: 4.64, label: 'Bathroom' },
      balcony: { area: 5.00, label: 'Balcony' }
    }
  },
  "2-bedroom": {
    area: [65, 75],
    bedrooms: 2,
    path: `M 0,0 h 400 v 500 h -400 Z
      M 0,200 h 400
      M 200,0 v 500
      M 20,20 h 160 v 160 h -160 Z
      M 220,20 h 160 v 160 h -160 Z
      M 220,220 h 160 v 160 h -160 Z
      M 20,220 h 70 v 80 h -70 Z
      M 110,220 h 70 v 80 h -70 Z
      M 20,320 h 160 v 80 h -160 Z`,
    rooms: {
      livingRoom: { area: 25.5, label: 'Living Room' },
      masterBedroom: { area: 18.2, label: 'Master Bedroom' },
      bedroom: { area: 14.8, label: 'Bedroom' },
      kitchen: { area: 8.5, label: 'Kitchen' },
      bathroom1: { area: 4.8, label: 'Bathroom 1' },
      bathroom2: { area: 4.2, label: 'Bathroom 2' },
      balcony: { area: 7.0, label: 'Balcony' }
    }
  },
  "3-bedroom": {
    area: [85, 120],
    bedrooms: 3,
    path: `M 0,0 h 500 v 600 h -500 Z
      M 0,250 h 500
      M 250,0 v 600
      M 20,20 h 210 v 210 h -210 Z
      M 270,20 h 210 v 160 h -210 Z
      M 270,200 h 210 v 160 h -210 Z
      M 20,270 h 210 v 160 h -210 Z
      M 270,380 h 80 v 100 h -80 Z
      M 370,380 h 80 v 100 h -80 Z
      M 20,450 h 210 v 80 h -210 Z
      M 270,500 h 210 v 80 h -210 Z`,
    rooms: {
      livingRoom: { area: 35.0, label: 'Living Room' },
      masterBedroom: { area: 22.0, label: 'Master Bedroom' },
      bedroom1: { area: 18.0, label: 'Bedroom 1' },
      bedroom2: { area: 16.0, label: 'Bedroom 2' },
      kitchen: { area: 12.0, label: 'Kitchen' },
      bathroom1: { area: 6.5, label: 'Bathroom 1' },
      bathroom2: { area: 5.5, label: 'Bathroom 2' },
      balcony1: { area: 8.0, label: 'Balcony 1' },
      balcony2: { area: 7.0, label: 'Balcony 2' }
    }
  },
  "penthouse": {
    area: [120, 200],
    bedrooms: 4,
    path: `M 0,0 h 600 v 800 h -600 Z
      M 0,300 h 600
      M 20,20 h 280 v 260 h -280 Z
      M 320,20 h 260 v 200 h -260 Z
      M 320,240 h 260 v 200 h -260 Z
      M 20,340 h 260 v 200 h -260 Z
      M 320,460 h 260 v 200 h -260 Z
      M 20,560 h 100 v 120 h -100 Z
      M 140,560 h 100 v 120 h -100 Z
      M 20,700 h 260 v 80 h -260 Z
      M 320,700 h 260 v 80 h -260 Z`,
    rooms: {
      livingRoom: { area: 45.0, label: 'Living Room' },
      masterBedroom: { area: 28.0, label: 'Master Bedroom' },
      bedroom1: { area: 22.0, label: 'Bedroom 1' },
      bedroom2: { area: 20.0, label: 'Bedroom 2' },
      bedroom3: { area: 18.0, label: 'Bedroom 3' },
      kitchen: { area: 15.0, label: 'Kitchen' },
      bathroom1: { area: 8.5, label: 'Bathroom 1' },
      bathroom2: { area: 7.5, label: 'Bathroom 2' },
      terrace1: { area: 12.0, label: 'Terrace 1' },
      terrace2: { area: 12.0, label: 'Terrace 2' }
    }
  }
} as const;

const generateLayout = (layoutType: 'A' | 'B', area: number): Layout => {
  return {
    id: generateId(),
    name: `Layout ${layoutType}`,
    type: layoutType,
    area: area,
    planImageUrl: `/img/layout-${layoutType}.jpg`,
    specs: {
      bedrooms: layoutType === 'A' ? 1 : 2,
      bathrooms: layoutType === 'A' ? 1 : 2,
      balcony: true
    }
  };
};

const DEMO_IMAGES = {
  layout: [
    '/img/layout-A.jpg',
    '/img/layout-b.jpg',
    '/img/B-1-7-default.webp',
    '/img/floor-choose.webp',
    '/img/building-selection.webp'
  ],
  photo: [
    '/img/floor-plans/plan-choose.png'
  ]
};

const generateUnit = (
  floor: number,
  position: number,
  building: string,
  layout: 'A' | 'B'
): Unit => {
  const area = layout === 'A' ? 36.85 : 56.75;
  const status: UnitStatus = Math.random() > 0.7 ? 'sold' :
                            Math.random() > 0.5 ? 'reserved' :
                            Math.random() > 0.1 ? 'available' : 'unavailable';

  // Определяем вид из окна на основе позиции и этажа
  let windowView: WindowView;
  if (position <= 3) {
    windowView = 'sea';
  } else if (position <= 6) {
    windowView = 'mountain';
  } else if (position <= 9) {
    windowView = 'garden';
  } else {
    windowView = 'city';
  }

  const layoutData = generateLayout(layout, area);

  return {
    id: generateId(),
    building,
    number: `B${floor}0${position}${layout}`,
    floor,
    status,
    area,
    bedrooms: layoutData.specs.bedrooms,
    price: Math.round(generatePrice(floor, area)),
    windowView,
    pricePerSqm: Math.round(generatePrice(floor, area) / area),
    layout: layoutData,
    completion: floor <= 10 ? '2024 Q4' : floor <= 20 ? '2025 Q2' : '2025 Q4',
    furnished: Math.random() > 0.5,
    livingArea: area * 0.85,
    kitchenArea: layout === 'A' ? 4.89 : 6.75,
    balconyArea: layout === 'A' ? 3.38 : 4.25,
    bathrooms: layout === 'A' ? 1 : 2,
    section: '1',
    finishType: 'Чистовая',
    balconies: 1,
    buildingType: 'Блочный',
    constructionStage: 'Строительство',
    parkingType: 'Подземная',
    amenities: {
      handicapFacilities: true,
      elevator: true,
      gym: true,
      laundry: true,
      spa: true,
      pool: true,
      wifi: true,
      garden: true,
      playground: true,
      restaurants: true,
      roofTerrace: true,
      concierge: true,
      reception: true,
      security: true,
      school: true,
      sportsGround: false
    },
    images: [
      {
        id: generateId(),
        url: layout === 'A' ? '/img/layout-A.jpg' : '/img/layout-b.jpg',
        type: 'layout',
        title: 'План квартиры'
      },
      {
        id: generateId(),
        url: '/img/B-1-7-default.webp',
        type: 'layout',
        title: 'План на этаже'
      },
      {
        id: generateId(),
        url: '/img/floor-choose.webp',
        type: 'photo',
        title: 'Общий вид здания'
      },
      {
        id: generateId(),
        url: '/img/building-selection.webp',
        type: 'photo',
        title: 'Расположение здания'
      },
      {
        id: generateId(),
        url: '/img/floor-plans/plan-choose.png',
        type: 'photo',
        title: 'План этажа'
      }
    ],
    description: 'Aura Condominium — уникальный жилой комплекс, расположенный на юге острова Пхукет. Он представляет собой идеальное сочетание современного дизайна, функционала и комфорта, созданное для тех, кто ценит высокий уровень жизни и непревзойденные виды на тропические пейзажи и бирюзовые воды Андаманского моря.'
  };
};

export const generateMockUnits = (): Unit[] => {
  const units: Unit[] = [];
  const buildings = ['B1', 'B2', 'B3'];

  buildings.forEach(building => {
    for (let floor = 1; floor <= 7; floor++) {
      // Генерируем квартиры типа A
      for (let pos = 1; pos <= 4; pos++) {
        units.push(generateUnit(floor, pos, building, 'A'));
      }
      // Генерируем квартиры типа B
      for (let pos = 5; pos <= 7; pos++) {
        units.push(generateUnit(floor, pos, building, 'B'));
      }
    }
  });

  return units.sort((a, b) => {
    if (a.floor !== b.floor) return a.floor - b.floor;
    return a.number.localeCompare(b.number);
  });
};

export const generateUnits = (): Unit[] => {
  return generateMockUnits();
};