import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Stack,
  Button,
  Typography,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Undo as UndoIcon,
  Add as AddIcon
} from '@mui/icons-material';

export interface Point {
  x: number;
  y: number;
}

export interface Polygon {
  id: string;
  points: Point[];
  label: string;
  type: 'floor' | 'unit';
  metadata?: {
    floor?: number;
    unitNumber?: string;
    area?: number;
  };
}

interface BuildingMarkupToolProps {
  imageUrl: string;
  initialPolygons?: Polygon[];
  onSave: (polygons: Polygon[]) => void;
}

export const BuildingMarkupTool: React.FC<BuildingMarkupToolProps> = ({
  imageUrl,
  initialPolygons = [],
  onSave
}) => {
  const [polygons, setPolygons] = useState<Polygon[]>(initialPolygons);
  const [currentPolygon, setCurrentPolygon] = useState<Point[]>([]);
  const [selectedPolygon, setSelectedPolygon] = useState<string | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [scale, setScale] = useState(1);
  const [mode, setMode] = useState<'draw' | 'edit'>('draw');
  const [editingLabel, setEditingLabel] = useState('');
  const [editingType, setEditingType] = useState<'floor' | 'unit'>('floor');

  const svgRef = useRef<SVGSVGElement>(null);
  const imageRef = useRef<SVGImageElement>(null);

  // Преобразование координат из экранных в SVG
  const getMousePosition = (event: React.MouseEvent): Point => {
    if (!svgRef.current) return { x: 0, y: 0 };

    const CTM = svgRef.current.getScreenCTM();
    if (!CTM) return { x: 0, y: 0 };

    const point = svgRef.current.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;
    const transformed = point.matrixTransform(CTM.inverse());

    return {
      x: transformed.x,
      y: transformed.y
    };
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    if (mode !== 'draw') return;

    const point = getMousePosition(event);
    if (isDrawing) {
      setCurrentPolygon([...currentPolygon, point]);
    } else {
      setIsDrawing(true);
      setCurrentPolygon([point]);
    }
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!isDrawing || mode !== 'draw') return;

    const point = getMousePosition(event);
    const updatedPolygon = [...currentPolygon];
    if (updatedPolygon.length > 0) {
      updatedPolygon[updatedPolygon.length - 1] = point;
      setCurrentPolygon(updatedPolygon);
    }
  };

  const handleDoubleClick = () => {
    if (!isDrawing || currentPolygon.length < 3) return;

    const newPolygon: Polygon = {
      id: Date.now().toString(),
      points: currentPolygon,
      label: editingLabel || `Polygon ${polygons.length + 1}`,
      type: editingType,
      metadata: {}
    };

    setPolygons([...polygons, newPolygon]);
    setCurrentPolygon([]);
    setIsDrawing(false);
  };

  const handlePolygonClick = (polygonId: string) => {
    if (mode === 'edit') {
      setSelectedPolygon(polygonId);
    }
  };

  const handleDeletePolygon = (polygonId: string) => {
    setPolygons(polygons.filter(p => p.id !== polygonId));
    setSelectedPolygon(null);
  };

  const handleSave = () => {
    onSave(polygons);
  };

  const handleUndo = () => {
    if (currentPolygon.length > 0) {
      setCurrentPolygon(currentPolygon.slice(0, -1));
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, height: '100%' }}>
      {/* Инструменты */}
      <Paper sx={{ p: 2, width: 300 }}>
        <Stack spacing={2}>
          <Typography variant="h6">Инструменты разметки</Typography>
          
          <Box>
            <Button
              variant={mode === 'draw' ? 'contained' : 'outlined'}
              onClick={() => setMode('draw')}
              fullWidth
              sx={{ mb: 1 }}
            >
              Рисовать
            </Button>
            <Button
              variant={mode === 'edit' ? 'contained' : 'outlined'}
              onClick={() => setMode('edit')}
              fullWidth
            >
              Редактировать
            </Button>
          </Box>

          {mode === 'draw' && (
            <>
              <TextField
                label="Название области"
                value={editingLabel}
                onChange={(e) => setEditingLabel(e.target.value)}
                size="small"
              />
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Тип области:
                </Typography>
                <Button
                  variant={editingType === 'floor' ? 'contained' : 'outlined'}
                  onClick={() => setEditingType('floor')}
                  size="small"
                  sx={{ mr: 1 }}
                >
                  Этаж
                </Button>
                <Button
                  variant={editingType === 'unit' ? 'contained' : 'outlined'}
                  onClick={() => setEditingType('unit')}
                  size="small"
                >
                  Квартира
                </Button>
              </Box>
              {isDrawing && (
                <Button
                  startIcon={<UndoIcon />}
                  onClick={handleUndo}
                  disabled={currentPolygon.length === 0}
                >
                  Отменить точку
                </Button>
              )}
            </>
          )}

          <Divider />

          <Typography variant="subtitle1">Созданные области:</Typography>
          <List>
            {polygons.map((polygon) => (
              <ListItem
                key={polygon.id}
                selected={selectedPolygon === polygon.id}
                onClick={() => handlePolygonClick(polygon.id)}
              >
                <ListItemText
                  primary={polygon.label}
                  secondary={`Тип: ${polygon.type}`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => handleDeletePolygon(polygon.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>

          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSave}
          >
            Сохранить разметку
          </Button>
        </Stack>
      </Paper>

      {/* Область разметки */}
      <Box sx={{ flex: 1, position: 'relative' }}>
        <svg
          ref={svgRef}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            cursor: mode === 'draw' ? 'crosshair' : 'default'
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onDoubleClick={handleDoubleClick}
        >
          <image
            ref={imageRef}
            href={imageUrl}
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid meet"
          />
          
          {/* Существующие полигоны */}
          {polygons.map((polygon) => (
            <polygon
              key={polygon.id}
              points={polygon.points.map(p => `${p.x},${p.y}`).join(' ')}
              fill={selectedPolygon === polygon.id ? "rgba(20, 114, 254, 0.3)" : "rgba(20, 114, 254, 0.1)"}
              stroke={selectedPolygon === polygon.id ? "#1472FE" : "rgba(20, 114, 254, 0.5)"}
              strokeWidth="2"
              onClick={() => handlePolygonClick(polygon.id)}
            />
          ))}

          {/* Текущий ��исуемый полигон */}
          {currentPolygon.length > 0 && (
            <polygon
              points={currentPolygon.map(p => `${p.x},${p.y}`).join(' ')}
              fill="rgba(255, 0, 0, 0.1)"
              stroke="red"
              strokeWidth="2"
              strokeDasharray="5,5"
            />
          )}
        </svg>
      </Box>
    </Box>
  );
}; 