import { CalendarEvent, CalendarEventType } from '../types/calendar';
import { addDays, addHours, setHours, startOfDay } from 'date-fns';

const now = new Date();
const today = startOfDay(now);

// Базовые события
const baseEvents: CalendarEvent[] = [
  {
    id: 'event-001',
    type: 'viewing',
    title: 'VIP Space Odyssey Viewing',
    start: setHours(addDays(today, 1), 10),
    end: setHours(addDays(today, 1), 11),
    clientName: 'John Smith',
    clientId: 'C001',
    propertyId: 'P001',
    propertyName: 'VIP Space Odyssey',
    location: 'Nai Harn, Phuket',
    description: 'Client interested in 2-bedroom unit',
    status: 'scheduled',
    assignee: {
      id: 1,
      name: 'Olivia Rhye',
      avatar: '/assets/images/avatars/olivia.jpg'
    }
  }
  // ... остальные базовые события
];

// Функция для генерации случайных событий
const generateRandomEvents = (count: number): CalendarEvent[] => {
  const eventTypes: CalendarEventType[] = ['viewing', 'meeting', 'reservation', 'signing', 'payment', 'handover'];
  const assignees = [
    { id: 1, name: 'Olivia Rhye', avatar: '/assets/images/avatars/olivia.jpg' },
    { id: 2, name: 'Phoenix Baker', avatar: '/assets/images/avatars/phoenix.jpg' },
    { id: 3, name: 'Lana Steiner', avatar: '/assets/images/avatars/lana.jpg' },
    { id: 4, name: 'Drew Cano', avatar: '/assets/images/avatars/drew.jpg' },
    { id: 5, name: 'Candice Wu', avatar: '/assets/images/avatars/candice.jpg' }
  ];
  const properties = [
    { id: 'P001', name: 'VIP Space Odyssey', location: 'Nai Harn, Phuket' },
    { id: 'P002', name: 'VIP Galaxy Villas', location: 'Rawai, Phuket' },
    { id: 'P003', name: 'VIP Space Villas', location: 'Kata, Phuket' }
  ];
  const clients = [
    { id: 'C001', name: 'John Smith' },
    { id: 'C002', name: 'Emma Wilson' },
    { id: 'C003', name: 'Michael Chen' },
    { id: 'C004', name: 'Sarah Johnson' },
    { id: 'C005', name: 'David Brown' }
  ];

  return Array.from({ length: count }, (_, index) => {
    const eventType = eventTypes[Math.floor(Math.random() * eventTypes.length)];
    const assignee = assignees[Math.floor(Math.random() * assignees.length)];
    const property = properties[Math.floor(Math.random() * properties.length)];
    const client = clients[Math.floor(Math.random() * clients.length)];
    
    // Случайная дата в ноябре или декабре
    const month = Math.random() > 0.5 ? 10 : 11; // 10 = ноябрь, 11 = декабрь
    const day = Math.floor(Math.random() * 28) + 1;
    const hour = Math.floor(Math.random() * 10) + 9; // 9:00 - 19:00
    
    const startDate = new Date(2024, month, day, hour);
    const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // +1 час

    return {
      id: `event-${index + 100}`,
      type: eventType,
      title: `${eventType.charAt(0).toUpperCase() + eventType.slice(1)} - ${property.name}`,
      start: startDate,
      end: endDate,
      clientName: client.name,
      clientId: client.id,
      propertyId: property.id,
      propertyName: property.name,
      location: property.location,
      description: `${eventType.charAt(0).toUpperCase() + eventType.slice(1)} appointment with ${client.name} for ${property.name}`,
      status: 'scheduled',
      assignee
    };
  });
};

// Объединяем базовые и сгенерированные события
export const calendarEventsData: CalendarEvent[] = [
  ...baseEvents,
  ...generateRandomEvents(100)
]; 