import React, { useState } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Typography,
  Container
} from '@mui/material';
import InputForm from './components/InputForm';
import TopicList from './components/TopicList';
import QuestionList from './components/QuestionList';
import FinalTest from './components/FinalTest';

export interface TestData {
  propertyName: string;
  description: string;
  difficulty: 'easy' | 'medium' | 'hard';
  selectedTopics: string[];
  questions: Array<{
    id: string;
    question: string;
    options: string[];
    correctAnswer: number;
  }>;
}

const TestWizard: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [testData, setTestData] = useState<TestData>({
    propertyName: '',
    description: '',
    difficulty: 'medium',
    selectedTopics: [],
    questions: []
  });

  const steps = [
    {
      label: 'Основная информация',
      content: <InputForm testData={testData} setTestData={setTestData} onNext={() => setCurrentStep(1)} />
    },
    {
      label: 'Выбор тем',
      content: <TopicList 
        testData={testData} 
        setTestData={setTestData} 
        onNext={() => setCurrentStep(2)} 
        onBack={() => setCurrentStep(0)} 
      />
    },
    {
      label: 'Вопросы',
      content: <QuestionList 
        testData={testData} 
        setTestData={setTestData} 
        onNext={() => setCurrentStep(3)} 
        onBack={() => setCurrentStep(1)} 
      />
    },
    {
      label: 'Готовый тест',
      content: <FinalTest testData={testData} onBack={() => setCurrentStep(2)} />
    }
  ];

  return (
    <Container maxWidth="lg">
      <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h4" gutterBottom>
          Генератор тестов
        </Typography>
        
        <Box sx={{ mb: 4 }}>
          <Stepper activeStep={currentStep}>
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box sx={{ mt: 4 }}>
          {steps[currentStep].content}
        </Box>
      </Paper>
    </Container>
  );
};

export default TestWizard; 