import React from 'react';
import { Box, Card, Typography, Select, MenuItem, Stack } from '@mui/material';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';

const generateRevenueData = () => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  // Коэффициенты сезонности для Таиланда
  // Высокий сезон: ноябрь-март
  // Низкий сезон: апрель-октябрь
  // Пик продаж: декабрь-февраль
  const seasonality = {
    'Jan': 1.4,  // Высокий сезон
    'Feb': 1.3,  // Высокий сезон
    'Mar': 1.1,  // Конец высокого сезона
    'Apr': 0.7,  // Начало низкого сезона (Сонгкран)
    'May': 0.6,  // Низкий сезон
    'Jun': 0.5,  // Низкий сезон
    'Jul': 0.6,  // Низкий сезон
    'Aug': 0.7,  // Низкий сезон
    'Sep': 0.8,  // Начало роста
    'Oct': 0.9,  // Подготовка к высокому сезону
    'Nov': 1.2,  // Начало высокого сезона
    'Dec': 1.5   // Пик сезона
  };

  // Базовые значения для каждого проекта с учетом их специфики
  const baseValues = {
    'Vip Space Odyssey': { 
      base: 150,
      volatility: 20,
      trend: 1.1  // Растущий тренд
    },
    'Vip Galaxy Villas': { 
      base: 200,
      volatility: 25,
      trend: 1.15 // Сильно растущий тренд
    },
    'Vip Space Villas': { 
      base: 170,
      volatility: 22,
      trend: 1.05 // Умеренный рост
    }
  };

  return months.map((month, index) => {
    const result = { month };
    
    Object.entries(baseValues).forEach(([project, { base, volatility, trend }]) => {
      // Базовое значение с учетом тренда
      const trendValue = base * Math.pow(trend, index / 12);
      
      // Сезонная составляющая
      const seasonal = seasonality[month];
      
      // Случайные колебания
      const noise = (Math.random() - 0.5) * volatility;
      
      // Итоговое значение
      result[project] = Math.round(trendValue * seasonal + noise);
    });
    
    return result;
  });
};

const generateSalesData = () => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  const seasonality = {
    'Jan': 1.3,
    'Feb': 1.2,
    'Mar': 1.1,
    'Apr': 0.8,
    'May': 0.7,
    'Jun': 0.6,
    'Jul': 0.7,
    'Aug': 0.8,
    'Sep': 0.9,
    'Oct': 1.0,
    'Nov': 1.2,
    'Dec': 1.4
  };

  const baseValues = {
    'Vip Space Odyssey': { 
      base: 50,
      growth: 8,
      volatility: 5
    },
    'Vip Galaxy Villas': { 
      base: 80,
      growth: 12,
      volatility: 7
    },
    'Vip Space Villas': { 
      base: 65,
      growth: 10,
      volatility: 6
    }
  };

  return months.map((month, index) => {
    const result = { month };
    
    Object.entries(baseValues).forEach(([project, { base, growth, volatility }]) => {
      // Базовый тренд роста
      const trend = base + (growth * index * 0.1);
      
      // Сезонность
      const seasonal = seasonality[month];
      
      // Случайные колебания
      const noise = (Math.random() - 0.5) * volatility;
      
      // Итоговое значение с учетом всех факторов
      result[project] = Math.max(0, Math.round(trend * seasonal + noise));
    });
    
    return result;
  });
};

const ChartCard = ({ title, children }) => {
  const theme = useTheme();
  
  return (
    <Card sx={{ p: 3, flex: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h6">{title}</Typography>
        <Select
          size="small"
          defaultValue="lastYear"
          sx={{
            minWidth: 120,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey[200],
            },
          }}
        >
          <MenuItem value="lastYear">Last year</MenuItem>
          <MenuItem value="thisYear">This year</MenuItem>
        </Select>
      </Box>
      {children}
    </Card>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  const theme = useTheme();
  
  if (active && payload && payload.length) {
    return (
      <Card 
        sx={{ 
          p: 2, 
          border: `1px solid ${theme.palette.grey[200]}`,
          boxShadow: theme.shadows[2],
          bgcolor: 'background.paper'
        }}
      >
        <Typography 
          variant="subtitle2" 
          sx={{ 
            mb: 1,
            color: theme.palette.grey[700],
            fontWeight: 600 
          }}
        >
          {label}
        </Typography>
        <Stack spacing={1}>
          {payload.map((entry, index) => (
            <Box 
              key={index} 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1 
              }}
            >
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  bgcolor: entry.color,
                }}
              />
              <Typography 
                variant="caption"
                sx={{ 
                  color: theme.palette.grey[600],
                  fontWeight: 500
                }}
              >
                {entry.name}: {entry.value} M THB
              </Typography>
            </Box>
          ))}
        </Stack>
      </Card>
    );
  }
  return null;
};

const ProjectCharts = () => {
  const theme = useTheme();

  // Обновленная цветовая палитра с градациями фиолетового
  const chartColors = {
    'Vip Space Odyssey': '#7F56D9',    // Темный фиолетовый
    'Vip Galaxy Villas': '#9E77ED',     // Средний фиолетовый
    'Vip Space Villas': '#B692F6'       // Светлый фиолетовый
  };

  const commonAxisProps = {
    axisLine: false,
    tickLine: false,
    tick: { fill: theme.palette.grey[600], fontSize: 12 },
    tickMargin: 10
  };

  return (
    <Box sx={{ display: 'flex', gap: 3, mt: 3 }}>
      <ChartCard title="Total revenue, M THB">
        <ResponsiveContainer height={400}>
          <BarChart 
            data={generateRevenueData()} 
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid 
              strokeDasharray="3 3" 
              vertical={false}
              stroke={theme.palette.grey[200]}
            />
            <XAxis 
              dataKey="month" 
              {...commonAxisProps}
              dy={10}
            />
            <YAxis 
              {...commonAxisProps}
              dx={-10}
            />
            <Tooltip 
              content={<CustomTooltip />}
              cursor={{ fill: 'transparent' }}
            />
            <Legend 
              verticalAlign="top"
              height={36}
              iconType="circle"
              iconSize={8}
              formatter={(value) => (
                <span style={{ color: theme.palette.grey[700], fontSize: 12 }}>
                  {value}
                </span>
              )}
            />
            {Object.entries(chartColors).reverse().map(([key, color]) => (
              <Bar 
                key={key}
                dataKey={key}
                fill={color}
                stackId="revenue"
                radius={[0, 0, 0, 0]}
                barSize={24}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </ChartCard>

      <ChartCard title="Total sales, units">
        <ResponsiveContainer height={400}>
          <LineChart data={generateSalesData()}>
            <CartesianGrid 
              strokeDasharray="3 3" 
              vertical={false}
              stroke={theme.palette.grey[200]}
            />
            <XAxis 
              dataKey="month" 
              {...commonAxisProps}
            />
            <YAxis 
              {...commonAxisProps}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend 
              verticalAlign="top" 
              height={36}
              iconType="circle"
              iconSize={8}
            />
            {Object.entries(chartColors).map(([key, color]) => (
              <Line 
                key={key}
                type="monotone"
                dataKey={key}
                stroke={color}
                strokeWidth={2}
                dot={{ r: 4, fill: color }}
                activeDot={{ r: 6 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </ChartCard>
    </Box>
  );
};

export default ProjectCharts;