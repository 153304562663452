import React, { useState } from 'react';
import { Box, Paper, Typography, Stack, Tooltip, CircularProgress } from '@mui/material';
import { Section, BuildingState } from '../../../types/building';

interface BuildingSelectorProps {
  sections: Section[];
  buildingState: BuildingState;
  onSectionSelect: (sectionId: string) => void;
}

export const BuildingSelector: React.FC<BuildingSelectorProps> = ({
  sections,
  buildingState,
  onSectionSelect,
}) => {
  const [hoveredSection, setHoveredSection] = useState<string | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // SVG пути для секций, повторяющие форму зданий
  const sectionPaths = {
    // Левое здание (волнообразная форма)
    'A': `M 100,150 
          L 300,150
          C 320,150 330,160 330,180
          L 330,500
          C 330,520 320,530 300,530
          L 100,530
          C 80,530 70,520 70,500
          L 70,180
          C 70,160 80,150 100,150
          Z`,
    
    // Центральное здание (изогнутая форма)
    'B': `M 350,200
          L 750,200
          C 780,200 800,220 800,250
          L 800,450
          C 800,480 780,500 750,500
          L 350,500
          C 320,500 300,480 300,450
          L 300,250
          C 300,220 320,200 350,200
          Z`,
    
    // Правое здание (волнообразная форма)
    'C': `M 800,150
          L 1000,150
          C 1020,150 1030,160 1030,180
          L 1030,500
          C 1030,520 1020,530 1000,530
          L 800,530
          C 780,530 770,520 770,500
          L 770,180
          C 770,160 780,150 800,150
          Z`
  };

  const handleSectionSelect = (sectionId: string) => {
    setIsTransitioning(true);
    // Добавляем небольшую задержку для анимации
    setTimeout(() => {
      onSectionSelect(sectionId);
      setIsTransitioning(false);
    }, 300);
  };

  return (
    <Box 
      sx={{ 
        width: '100%',
        position: 'relative',
        backgroundColor: '#f5f5f5',
        minHeight: '400px',
        borderRadius: 2,
        overflow: 'hidden',
        transition: 'transform 0.3s ease-out',
        transform: isTransitioning ? 'scale(1.05)' : 'scale(1)',
      }}
    >
      <Box
        component="img"
        src="/img/building-selection.webp"
        alt="Building complex"
        onLoad={() => setImageLoaded(true)}
        sx={{
          width: '100%',
          height: 'auto',
          display: 'block',
          objectFit: 'cover'
        }}
      />

      <svg
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        }}
        viewBox="0 0 1100 600"
        preserveAspectRatio="xMidYMid slice"
      >
        <defs>
          {/* Градиент для подсветки */}
          <linearGradient id="hoverGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="rgba(33, 150, 243, 0.3)" />
            <stop offset="100%" stopColor="rgba(33, 150, 243, 0.1)" />
          </linearGradient>
          
          {/* Эффект свечения */}
          <filter id="glow">
            <feGaussianBlur stdDeviation="3" result="coloredBlur"/>
            <feMerge>
              <feMergeNode in="coloredBlur"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>

        {/* Секции зданий */}
        {Object.entries(sectionPaths).map(([id, path]) => (
          <g key={id}>
            {/* Подсветка при наведении */}
            {hoveredSection === id && (
              <path
                d={path}
                fill="url(#hoverGradient)"
                filter="url(#glow)"
                opacity="0.5"
              />
            )}
            
            {/* Основной контур секции */}
            <path
              d={path}
              fill={
                buildingState.selectedSection === id
                  ? 'rgba(33, 150, 243, 0.3)'
                  : 'transparent'
              }
              stroke={
                buildingState.selectedSection === id
                  ? '#2196f3'
                  : hoveredSection === id
                  ? '#2196f3'
                  : 'rgba(255, 255, 255, 0.5)'
              }
              strokeWidth={2}
              style={{ 
                pointerEvents: 'auto', 
                cursor: 'pointer',
                transition: 'all 0.3s ease'
              }}
              onMouseEnter={() => setHoveredSection(id)}
              onMouseLeave={() => setHoveredSection(null)}
              onClick={() => handleSectionSelect(id)}
            />
          </g>
        ))}
      </svg>

      {/* Информация о секции при наведении */}
      {hoveredSection && (
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            p: 2,
            minWidth: '300px',
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            backdropFilter: 'blur(8px)',
            borderRadius: 2,
            animation: 'fadeIn 0.3s ease',
            '@keyframes fadeIn': {
              from: { opacity: 0, transform: 'translateY(-10px)' },
              to: { opacity: 1, transform: 'translateY(0)' }
            }
          }}
        >
          <Typography variant="h6" gutterBottom>
            Секция {hoveredSection}
          </Typography>
          
          <Typography variant="subtitle1" gutterBottom>
            Доступно {sections.find(s => s.id === hoveredSection)?.stats.available} помещений
          </Typography>

          <Stack spacing={1} mt={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography>1-к</Typography>
              <Typography>54 шт.</Typography>
              <Typography color="primary">от THB 3,482,325</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography>2-к</Typography>
              <Typography>30 шт.</Typography>
              <Typography color="primary">от THB 5,537,700</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography>Студии</Typography>
              <Typography>9 шт.</Typography>
              <Typography color="primary">от THB 2,762,235</Typography>
            </Box>
          </Stack>
        </Paper>
      )}
    </Box>
  );
}; 