import React from 'react';
import { Box, Paper, Typography, Grid } from '@mui/material';
import { Layout } from '../../../types/building';
import { Unit } from '../../../types/unit';

interface LayoutViewerProps {
  layout: Layout;
  unit: Unit;
}

export const LayoutViewer: React.FC<LayoutViewerProps> = ({ layout, unit }) => {
  if (!layout || !unit) return null;

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <img
            src={layout.planImageUrl}
            alt={layout.name}
            style={{ width: '100%', height: 'auto' }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">
            {layout.name}
          </Typography>
          <Typography variant="body1">
            Area: {layout.area} m²
          </Typography>
          <Typography variant="body1">
            Bedrooms: {layout.specs.bedrooms}
          </Typography>
          <Typography variant="body1">
            Bathrooms: {layout.specs.bathrooms}
          </Typography>
          {unit && (
            <>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Unit Details
              </Typography>
              <Typography variant="body1">
                Unit №: {unit.number}
              </Typography>
              <Typography variant="body1">
                Price: {unit.price.toLocaleString()} ฿
              </Typography>
              <Typography variant="body1">
                Status: {unit.status}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}; 