export const tableStyles = {
    root: {
        '& .MuiTableCell-head': {
            backgroundColor: 'grey.50',
            fontWeight: 600,
            color: 'grey.600',
            fontSize: '12px',
            lineHeight: '18px',
            padding: '12px 24px',
        },
        '& .MuiTableCell-body': {
            fontSize: '14px',
            lineHeight: '20px',
            padding: '16px 24px',
            color: 'grey.900',
        },
        '& .MuiTableRow-root': {
            '&:hover': {
                backgroundColor: 'grey.50',
            },
        },
    },
};