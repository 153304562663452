import React from 'react';
import { Box, Card, Typography, IconButton } from '@mui/material';
import { ZoomIn, ZoomOut, MyLocation } from '@mui/icons-material';
import { companyData } from '../../../data/company';

// Добавляем интерфейс
interface CompanyProject {
  id: string;
  name: string;
  location: {
    lat: number;
    lng: number;
  };
}

// Заглушка для Google Maps API
const MapPlaceholder = () => (
  <Box
    sx={{
      width: '100%',
      height: '400px',
      bgcolor: 'grey.100',
      borderRadius: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      overflow: 'hidden',
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/map-placeholder.png)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    {/* Map Controls */}
    <Box
      sx={{
        position: 'absolute',
        top: 16,
        right: 16,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <IconButton
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 1,
          '&:hover': { bgcolor: 'grey.100' }
        }}
      >
        <ZoomIn />
      </IconButton>
      <IconButton
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 1,
          '&:hover': { bgcolor: 'grey.100' }
        }}
      >
        <ZoomOut />
      </IconButton>
      <IconButton
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 1,
          '&:hover': { bgcolor: 'grey.100' }
        }}
      >
        <MyLocation />
      </IconButton>
    </Box>

    {/* Project Markers */}
    {companyData.projects?.map((project: CompanyProject, index: number) => (
      <Box
        key={project.id}
        sx={{
          position: 'absolute',
          // Рандомное позиционирование маркеров для демо
          top: `${20 + (index * 15)}%`,
          left: `${20 + (index * 20)}%`,
          transform: 'translate(-50%, -50%)',
          cursor: 'pointer',
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            width: 12,
            height: 12,
            bgcolor: 'primary.main',
            borderRadius: '50%',
            border: '2px solid white',
            boxShadow: 2,
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: '100%',
              left: '50%',
              transform: 'translateX(-50%)',
              borderLeft: '6px solid transparent',
              borderRight: '6px solid transparent',
              borderTop: '6px solid',
              borderTopColor: 'primary.main',
            },
            '&:hover': {
              bgcolor: 'primary.dark',
              '& + .tooltip': {
                opacity: 1,
                visibility: 'visible',
              }
            }
          }}
        />
        <Box
          className="tooltip"
          sx={{
            position: 'absolute',
            bottom: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            marginBottom: 2,
            padding: '8px 12px',
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 2,
            whiteSpace: 'nowrap',
            opacity: 0,
            visibility: 'hidden',
            transition: 'all 0.2s',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: '100%',
              left: '50%',
              transform: 'translateX(-50%)',
              borderLeft: '6px solid transparent',
              borderRight: '6px solid transparent',
              borderTop: '6px solid white',
            }
          }}
        >
          <Typography variant="caption" sx={{ fontWeight: 500 }}>
            {project.name}
          </Typography>
        </Box>
      </Box>
    ))}
  </Box>
);

const ProjectsMap = () => {
  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Projects Location
      </Typography>
      <MapPlaceholder />
    </Card>
  );
};

export default ProjectsMap; 