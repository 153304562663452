import React, { useState } from 'react';
import { Box, Paper, Typography, Stack, Drawer, IconButton, Grid, Button, Divider, Chip } from '@mui/material';
import { Close as CloseIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, Share as ShareIcon, Favorite as FavoriteIcon } from '@mui/icons-material';
import { Unit } from '../../../types/unit';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { SwipeableDrawer, BottomNavigation, BottomNavigationAction } from '@mui/material';
import {
  Apartment as ApartmentIcon,
  ViewQuilt as ViewQuiltIcon,
  Info as InfoIcon,
  AccessibleForward as AccessibleIcon,
  Elevator as ElevatorIcon,
  FitnessCenter as GymIcon,
  LocalLaundryService as LaundryIcon,
  Spa as SpaIcon,
  Pool as PoolIcon,
  Wifi as WifiIcon,
  Park as GardenIcon,
  Toys as PlaygroundIcon,
  Restaurant as RestaurantIcon,
  Deck as RoofTerraceIcon,
  SupportAgent as ConciergeIcon,
  Info as ReceptionIcon,
  Security as SecurityIcon,
  School as SchoolIcon,
  SportsHandball as SportsGroundIcon
} from '@mui/icons-material';

interface ViewSettings {
  showPrices: boolean;
  showArea: boolean;
  showStatus: boolean;
  showLabels: boolean;
}

interface FloorPlansProps {
  units: Unit[];
  onUnitClick: (unit: Unit) => void;
  viewSettings: ViewSettings;
  selectedFloor: number;
  onFloorChange: (floor: number) => void;
  sectionId: string | null;
  floorPlanUrl: string;
  onClose?: () => void;
}

// Add new interface for unit details drawer
interface UnitDetailsDrawerProps {
  unit: Unit | null;
  open: boolean;
  onClose: () => void;
}

// Add UnitDetailsDrawer component
const UnitDetailsDrawer: React.FC<UnitDetailsDrawerProps> = ({ unit, open, onClose }) => {
  if (!unit) return null;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imgError, setImgError] = useState<Record<string, boolean>>({});
  const [failedImages, setFailedImages] = useState<Set<string>>(new Set());

  const handleNextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % unit.images.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 + unit.images.length) % unit.images.length);
  };

  const handleImageError = (imageId: string) => {
    console.error(`Failed to load image with id: ${imageId}`);
    
    setFailedImages(prev => {
      const newSet = new Set(prev);
      newSet.add(imageId);
      return newSet;
    });
    
    const nextValidImageIndex = unit.images.findIndex((img, index) => 
      index > currentImageIndex && !failedImages.has(img.id)
    );
    
    if (nextValidImageIndex !== -1) {
      setCurrentImageIndex(nextValidImageIndex);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '40%',
          maxWidth: '600px',
          p: 0,
          borderLeft: '1px solid rgba(0,0,0,0.12)'
        }
      }}
    >
      <Box>
        {/* Header */}
        <Box sx={{ p: 2, borderBottom: '1px solid rgba(0,0,0,0.12)' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography>
              <Typography component="span" color="text.secondary">Квартира</Typography>
              <Typography component="span" ml={1}>№ {unit.number}</Typography>
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        {/* Status */}
        <Box sx={{ p: 2 }}>
          <Chip
            label={
              unit.status === 'available' ? 'Свободно' :
              unit.status === 'reserved' ? 'Бронь' :
              unit.status === 'sold' ? 'Продано' : 'Недоступно'
            }
            color={
              unit.status === 'available' ? 'success' :
              unit.status === 'reserved' ? 'warning' : 'error'
            }
            sx={{ borderRadius: 1 }}
          />
        </Box>

        {/* Image Slider */}
        <Box sx={{ position: 'relative', mb: 2 }}>
          <Box
            sx={{
              position: 'relative',
              paddingTop: '56.25%', // 16:9 Aspect Ratio
              backgroundColor: '#f5f5f5'
            }}
          >
            {!failedImages.has(unit.images[currentImageIndex].id) ? (
              <img
                src={unit.images[currentImageIndex].url}
                alt={unit.images[currentImageIndex].title}
                onError={() => handleImageError(unit.images[currentImageIndex].id)}
                onLoad={() => console.log('Image loaded successfully:', unit.images[currentImageIndex].url)}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            ) : (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: '#f5f5f5'
                }}
              >
                <Typography color="text.secondary">
                  Изображение недоступно
                </Typography>
              </Box>
            )}
            
            {/* Navigation buttons */}
            <IconButton
              onClick={handlePrevImage}
              sx={{
                position: 'absolute',
                left: 8,
                top: '50%',
                transform: 'translateY(-50%)',
                bgcolor: 'white',
                '&:hover': { bgcolor: 'white' }
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              onClick={handleNextImage}
              sx={{
                position: 'absolute',
                right: 8,
                top: '50%',
                transform: 'translateY(-50%)',
                bgcolor: 'white',
                '&:hover': { bgcolor: 'white' }
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>

          {/* Dots navigation */}
          <Box sx={{
            position: 'absolute',
            bottom: 8,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            gap: 1,
            zIndex: 1
          }}>
            {unit.images.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  bgcolor: index === currentImageIndex ? 'primary.main' : 'white',
                  cursor: 'pointer',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                }}
                onClick={() => setCurrentImageIndex(index)}
              />
            ))}
          </Box>
        </Box>

        {/* Price */}
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h5">
              THB {unit.price.toLocaleString()}
            </Typography>
            <Typography color="text.secondary">
              THB {unit.pricePerSqm.toLocaleString()}/м²
            </Typography>
          </Box>

          {/* Tags */}
          <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mb: 2 }}>
            <Chip label={`Секция ${unit.section}`} variant="outlined" size="small" />
            <Chip label={`${unit.floor} этаж`} variant="outlined" size="small" />
          </Stack>

          <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mb: 3 }}>
            <Chip label={`${unit.bedrooms} комната`} />
            <Chip label={`${unit.area} м²`} />
          </Stack>

          {/* Actions */}
          <Stack direction="row" spacing={1} sx={{ mb: 3 }}>
            <Button variant="contained" fullWidth>
              Оставить заявку
            </Button>
            <IconButton>
              <FavoriteIcon />
            </IconButton>
          </Stack>

          {/* Additional Actions */}
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" startIcon={<ShareIcon />}>
              Поделиться
            </Button>
            <Button variant="outlined">
              Презентация
            </Button>
          </Stack>
        </Box>

        <Divider />

        {/* Characteristics */}
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Характеристики:
          </Typography>
          
          <Stack spacing={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography color="text.secondary">Жилая площадь:</Typography>
              <Typography>{unit.livingArea} м²</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography color="text.secondary">Вид из окон:</Typography>
              <Typography>{unit.windowView}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography color="text.secondary">Кол-во санузлов:</Typography>
              <Typography>{unit.bathrooms}</Typography>
            </Box>
          </Stack>
        </Box>

        <Divider />

        {/* Additional Info */}
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Дополнительно:
          </Typography>
          
          <Stack spacing={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography color="text.secondary">Площадь балкона:</Typography>
              <Typography>{unit.balconyArea} м²</Typography>
            </Box>
          </Stack>
        </Box>

        <Divider />

        {/* Building Info */}
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            О доме:
          </Typography>
          
          <Typography paragraph>
            {unit.description}
          </Typography>
          
          <Stack spacing={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography color="text.secondary">Тип дома:</Typography>
              <Typography>{unit.buildingType}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography color="text.secondary">Стадия строительства:</Typography>
              <Typography>{unit.constructionStage}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography color="text.secondary">Тип парковки:</Typography>
              <Typography>{unit.parkingType}</Typography>
            </Box>
          </Stack>
        </Box>

        {/* Amenities */}
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Удобства:
          </Typography>
          
          <Grid container spacing={2}>
            {Object.entries(unit.amenities).map(([key, value]) => {
              if (!value) return null;
              const config = AMENITY_CONFIG[key as keyof typeof AMENITY_CONFIG];
              const Icon = config.icon;
              
              return (
                <Grid item xs={6} key={key}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1,
                    color: 'text.secondary'
                  }}>
                    <Icon sx={{ fontSize: 20 }} />
                    <Typography>{config.label}</Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

// Добавляем объект с маппингом удобств на иконки и названия
const AMENITY_CONFIG = {
  handicapFacilities: {
    icon: AccessibleIcon,
    label: 'Удобства для инвалидов'
  },
  elevator: {
    icon: ElevatorIcon,
    label: 'Лифт'
  },
  gym: {
    icon: GymIcon,
    label: 'Тренажерный зал'
  },
  laundry: {
    icon: LaundryIcon,
    label: 'Прачечная'
  },
  spa: {
    icon: SpaIcon,
    label: 'Спа'
  },
  pool: {
    icon: PoolIcon,
    label: 'Бассейн'
  },
  wifi: {
    icon: WifiIcon,
    label: 'Wi-Fi'
  },
  garden: {
    icon: GardenIcon,
    label: 'Сад'
  },
  playground: {
    icon: PlaygroundIcon,
    label: 'Детская площадка'
  },
  restaurants: {
    icon: RestaurantIcon,
    label: 'Рестораны'
  },
  roofTerrace: {
    icon: RoofTerraceIcon,
    label: 'Терраса на крыше'
  },
  concierge: {
    icon: ConciergeIcon,
    label: 'Консьерж'
  },
  reception: {
    icon: ReceptionIcon,
    label: 'Ресепшн'
  },
  security: {
    icon: SecurityIcon,
    label: 'Охрана'
  },
  school: {
    icon: SchoolIcon,
    label: 'Школа'
  },
  sportsGround: {
    icon: SportsGroundIcon,
    label: 'Спортивная площадка'
  }
} as const;

export const FloorPlans: React.FC<FloorPlansProps> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileView, setMobileView] = useState<'floors' | 'units' | 'details'>('floors');
  const [hoveredFloor, setHoveredFloor] = useState<number | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [hoveredUnit, setHoveredUnit] = useState<string | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);
  const [isUnitDetailsOpen, setIsUnitDetailsOpen] = useState(false);
  const [mobileImageIndex, setMobileImageIndex] = useState(0);

  // Координаты полигонов для каждого этажа (сверху вниз)
  const floorPolygons = [
    // 7 этаж (верхний)
    "432.3427157164699,701.6457701808188 1339.62461445666,582.9200654871324 1397.8234697574944,595.9566225077772 1397.357818465427,689.5404107969811 1338.8486339172089,683.642948202418 432.3427157164699,744.480179316318",
    // 6 этаж
    "432.3427157164699,744.480179316318 1339.158963164593,683.4877311050623 1397.202706315399,689.0748119785775 1396.5818379259758,836.5120065266191 1341.7973389776585,846.134207194747 432.4979328138257,803.4550151566035",
    // 5 этаж
    "432.4979328138257,659.1217952400311 1339.158963164593,481.1108205113476 1398.133903952206,502.06240002386176 1397.8234697574944,595.8014054104215 1339.62461445666,582.9200654871324 432.187524855946,701.6457701808188",
    // 4 этаж
    "433.434010501361,490.89978248285496 1341.6306563898606,84.40261993188892 1399.9433008254384,131.17485840396105 1391.3592391208288,141.10016007229214 1392.700465966134,214.86925012925093 1340.123219209559,178.38707971357115 433.1657651322999,528.991464468149",
    // 3 этаж
    "433.1760762072257,528.9490657478436 1340.3982861549068,178.24094056004316 1392.548187393948,214.74587142737207 1392.3619058876557,218.8433692897607 1397.576843537896,223.31335145423856 1398.694427628323,313.27194421000075 1339.6532650770646,279.3745084046239 432.8035656683046,572.3452579273898",
    // 2 этаж
    "1339.6532650770646,279.3745084046239 1398.694427628323,313.08571517737204 1398.694427628323,406.9555374076464 1339.466983570772,380.6943315186652 432.43105512938354,614.9964290290936 432.8035656683046,572.3452579273898",
    // 1 этаж (нижний)
    "432.4979328138257,614.890642122985 1339.3141802619486,380.54312865658585 1398.5601571546438,406.8050960558497 1398.3366378710966,502.4713494140385 1339.4693973593044,481.1108205113476 432.3427157164699,659.277012337387"
  ];

  // Добавим константу с полигонами квартир
  const unitPolygons = {
    // Левая сторона (снизу вверх)
    'B501A': "379.04762268066406,420.0032176971435 537.052438620098,419.8237095107159 536.5159226974005,619.4076327541488 493.8629068429571,635.2348524737222 468.64665847617937,659.6463269564539 417.4093878585776,659.3780689951053 408.5529098510742,632.1283416748047 378.3770446777344,620.5050621032715",
    'B502A': "536.7438392639159,420.00350761413574 695.6187858581543,419.6310005187988 695.2462425231934,620.2269592285156 652.5940170288086,635.1272430419922 627.1871136332612,658.8415530724079 576.7885150909424,659.5265846252441 564.683008639014,631.7474989761891 536.5575675964355,619.1094017028809",
    'B503A': "695.5588455200195,419.55620193481445 853.0603170888668,419.8237095107159 853.1438789367676,619.3874320983887 810.6741561889648,635.9282836914062 785.1910528676457,659.1098110337566 734.2286262512207,659.1748428344727 723.7599797187934,631.4792410148405 695.335319519043,620.0580101013184",
    'B504A': "852.9995727539062,419.8237228393554 1011.5400276184082,419.8237228393554 1012.137288168646,621.5536964449384 966.8016927007158,634.9665945123735 943.9390678405762,658.3050193786621 893.5672692525205,657.768521227013 881.4956609918291,632.0157569375378 853.0603170888668,619.1393747928001",
    
    // Правая сторона (снизу вверх)
    'B501B': "1011.5400276184082,419.8237228393554 1167.9344444274902,419.5554409027099 1168.2634216735898,619.6758907154975 1125.073889896449,635.5031104350709 1102.7477684020996,659.6463203430176 1049.632656097412,659.1098289489746 1038.097583770752,630.6744804382324 1011.8083457946776,621.2854461669922",
    'B502B': "1167.6661987304688,419.5554409027099 1327.0113906860352,420.09196853637695 1326.2065811157229,620.4806365966797 1283.285385131836,635.5031204223633 1258.8739395141602,658.5732650756836 1209.246166229248,659.1098289489746 1197.1745300292969,630.9427261352539 1168.2026901245115,619.4076538085938",
    'B503B': "1326.7432174682615,420.3602142333984 1474.553337097168,419.5554409027099 1474.4988413084084,434.6989508574318 1484.931616128987,435.08534992486057 1485.0154266357422,632.5522727966309 1442.8141177792447,632.5352733809918 1421.70654296875,658.8415832519531 1366.713638305664,658.5732650756836 1355.4467391967771,632.0157814025879 1326.5079984831673,619.7841041558404"
  };

  const handleFloorClick = (floor: number) => {
    props.onFloorChange(floor);
    setIsDrawerOpen(true);
  };

  // Получаем юниты для выбранного этажа
  const selectedFloorUnits = props.units.filter(u => u.floor === props.selectedFloor);

  // Update handleUnitClick
  const handleUnitClick = (unit: Unit) => {
    setSelectedUnit(unit);
    setIsUnitDetailsOpen(true);
  };

  // Мобильная версия компонента
  const MobileView = () => {
    return (
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        {/* Header */}
        <Box sx={{ 
          p: 2, 
          borderBottom: 1, 
          borderColor: 'divider',
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}>
          <IconButton onClick={() => props.onClose?.()}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="h6">
            {mobileView === 'floors' ? 'Выбор этажа' : 
             mobileView === 'units' ? `${props.selectedFloor} этаж` : 
             'Информация о квартире'}
          </Typography>
        </Box>

        {/* Основной контент */}
        <Box sx={{ flex: 1, overflow: 'auto', bgcolor: 'background.default' }}>
          {mobileView === 'floors' && (
            <Box sx={{ position: 'relative', height: '100%' }}>
              {/* Изображение здания */}
              <Box
                component="img"
                src="/img/floor-choose.webp"
                alt="Building section"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
              
              {/* SVG overlay для этажей с увеличенной областью касания */}
              <svg
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                viewBox="0 0 1900 860"
                preserveAspectRatio="xMidYMid slice"
              >
                {floorPolygons.map((points, index) => {
                  const floor = 7 - index;
                  return (
                    <polygon
                      key={floor}
                      points={points}
                      fill={hoveredFloor === floor ? "#1472FE33" : "transparent"}
                      stroke={hoveredFloor === floor ? "#1472FEff" : "rgba(20, 114, 254, 0.5)"}
                      strokeWidth="3"
                      style={{
                        cursor: 'pointer',
                        touchAction: 'none'
                      }}
                      onClick={() => {
                        props.onFloorChange(floor);
                        setMobileView('units');
                      }}
                    />
                  );
                })}
              </svg>

              {/* Упрощенная информация об этажах */}
              <Box sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                p: 2,
                bgcolor: 'rgba(255,255,255,0.9)',
                borderTop: 1,
                borderColor: 'divider'
              }}>
                <Typography variant="subtitle2" gutterBottom>
                  Нажмите на этаж для просмотра квартир
                </Typography>
              </Box>
            </Box>
          )}

          {mobileView === 'units' && (
            <Stack spacing={0} divider={<Divider />}>
              {selectedFloorUnits.map(unit => (
                <Box
                  key={unit.id}
                  sx={{
                    p: 2,
                    '&:active': { bgcolor: 'action.selected' }
                  }}
                  onClick={() => {
                    setSelectedUnit(unit);
                    setMobileView('details');
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography variant="subtitle1">
                      № {unit.number}
                    </Typography>
                    <Chip
                      size="small"
                      label={
                        unit.status === 'available' ? 'Свободно' :
                        unit.status === 'reserved' ? 'Бронь' : 'Продано'
                      }
                      color={
                        unit.status === 'available' ? 'success' :
                        unit.status === 'reserved' ? 'warning' : 'error'
                      }
                    />
                  </Box>
                  <Typography variant="h6" color="primary">
                    THB {unit.price.toLocaleString()}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {unit.bedrooms} комн. • {unit.area} м²
                  </Typography>
                </Box>
              ))}
            </Stack>
          )}

          {mobileView === 'details' && selectedUnit && (
            <Box sx={{ p: 2 }}>
              {/* Упрощенная галерея */}
              <Box sx={{ position: 'relative', mb: 3 }}>
                <Box
                  component="img"
                  src={selectedUnit.images[mobileImageIndex].url}
                  alt={selectedUnit.images[mobileImageIndex].title}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: 1,
                    mb: 1
                  }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                  {selectedUnit.images.map((_, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        bgcolor: index === mobileImageIndex ? 'primary.main' : 'action.disabled'
                      }}
                      onClick={() => setMobileImageIndex(index)}
                    />
                  ))}
                </Box>
              </Box>

              {/* Основная информация */}
              <Stack spacing={3}>
                <Box>
                  <Typography variant="h6" gutterBottom>
                    THB {selectedUnit.price.toLocaleString()}
                  </Typography>
                  <Typography color="text.secondary">
                    THB {selectedUnit.pricePerSqm.toLocaleString()}/м²
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="subtitle2" gutterBottom>
                    Характеристики
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography color="text.secondary">Площадь</Typography>
                      <Typography>{selectedUnit.area} м²</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="text.secondary">Комнат</Typography>
                      <Typography>{selectedUnit.bedrooms}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="text.secondary">Этаж</Typography>
                      <Typography>{selectedUnit.floor}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="text.secondary">Вид</Typography>
                      <Typography>{selectedUnit.windowView}</Typography>
                    </Grid>
                  </Grid>
                </Box>

                {/* Кнопки действий */}
                <Stack spacing={1}>
                  <Button 
                    variant="contained" 
                    size="large"
                    fullWidth
                  >
                    Оставить заявку
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    fullWidth
                    startIcon={<ShareIcon />}
                  >
                    Поделиться
                  </Button>
                </Stack>
              </Stack>

              {/* Amenities in mobile view */}
              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Удобства
                </Typography>
                <Grid container spacing={2}>
                  {Object.entries(selectedUnit.amenities).map(([key, value]) => {
                    if (!value) return null;
                    const config = AMENITY_CONFIG[key as keyof typeof AMENITY_CONFIG];
                    const Icon = config.icon;
                    
                    return (
                      <Grid item xs={6} key={key}>
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          gap: 1,
                          color: 'text.secondary',
                          fontSize: '0.875rem'
                        }}>
                          <Icon sx={{ fontSize: 18 }} />
                          <Typography variant="body2">{config.label}</Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          )}
        </Box>

        {/* Нижняя навигация только для списка квартир */}
        {mobileView === 'units' && (
          <Box sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setMobileView('floors')}
              startIcon={<ApartmentIcon />}
            >
              Выбрать другой этаж
            </Button>
          </Box>
        )}
      </Box>
    );
  };

  // Основной рендер с учетом типа устройства
  return isMobile ? (
    <MobileView />
  ) : (
    <Box sx={{ position: 'relative', width: '100%', height: '600px' }}>
      {/* Основное изображение и SVG разметка */}
      <Box
        component="img"
        src="/img/floor-choose.webp"
        alt="Building section"
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />

      {/* SVG для этажей */}
      <svg
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        }}
        viewBox="0 0 1900 860"
        preserveAspectRatio="xMidYMid slice"
      >
        {floorPolygons.map((points, index) => {
          const floor = 7 - index;
          return (
            <polygon
              key={floor}
              points={points}
              fill={hoveredFloor === floor ? "#1472FE33" : "transparent"}
              stroke={hoveredFloor === floor ? "#1472FEff" : "rgba(20, 114, 254, 0.5)"}
              strokeWidth="2"
              style={{
                pointerEvents: 'auto',
                cursor: 'pointer',
                transition: 'all 0.3s ease'
              }}
              onMouseEnter={() => setHoveredFloor(floor)}
              onMouseLeave={() => setHoveredFloor(null)}
              onClick={() => handleFloorClick(floor)}
            />
          );
        })}
      </svg>

      {/* Всплывающая информация при наведении */}
      {hoveredFloor && !isDrawerOpen && (
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            p: 2,
            minWidth: '300px',
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            backdropFilter: 'blur(8px)',
            borderRadius: 2,
            animation: 'fadeIn 0.3s ease',
            '@keyframes fadeIn': {
              from: { opacity: 0, transform: 'translateY(-10px)' },
              to: { opacity: 1, transform: 'translateY(0)' }
            }
          }}
        >
          <Typography variant="h6" gutterBottom>
            Этаж №{hoveredFloor}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Доступно {props.units.filter(u => u.floor === hoveredFloor && u.status === 'available').length} помещений
          </Typography>
          <Stack spacing={1} mt={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>1-к</Typography>
              <Typography>{props.units.filter(u => u.floor === hoveredFloor && u.bedrooms === 1).length} шт.</Typography>
              <Typography color="primary">от THB 3,979,800</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>2-к</Typography>
              <Typography>{props.units.filter(u => u.floor === hoveredFloor && u.bedrooms === 2).length} шт.</Typography>
              <Typography color="primary">от THB 6,328,800</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Студии</Typography>
              <Typography>{props.units.filter(u => u.floor === hoveredFloor && u.bedrooms === 0).length} шт.</Typography>
              <Typography color="primary">от THB 3,156,840</Typography>
            </Box>
          </Stack>
        </Paper>
      )}

      {/* Drawer с планом этажа */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: '50%',
            maxWidth: '800px',
            p: 3
          }
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <IconButton
            onClick={() => setIsDrawerOpen(false)}
            sx={{ position: 'absolute', right: 0, top: 0 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h5" gutterBottom>
            Этаж №{props.selectedFloor}/7
          </Typography>

          {/* План этажа с квартирами */}
          <Box sx={{ position: 'relative', width: '100%', mb: 4 }}>
            <Box
              component="img"
              src="/img/B-1-7-default.webp"
              alt={`План ${props.selectedFloor} этажа`}
              sx={{
                width: '100%',
                height: 'auto',
                display: 'block'
              }}
            />
            
            {/* SVG overlay для квартир */}
            <svg
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
              }}
              viewBox="0 0 1900 860"
              preserveAspectRatio="xMidYMid slice"
            >
              {Object.entries(unitPolygons).map(([unitNumber, points]) => {
                const unit = selectedFloorUnits.find(u => u.number === unitNumber);
                if (!unit) return null;
                
                return (
                  <polygon
                    key={unitNumber}
                    points={points}
                    fill={hoveredUnit === unitNumber ? "#1472FE33" : "transparent"}
                    stroke={
                      selectedUnit?.number === unitNumber ? "#1472FEff" :
                      hoveredUnit === unitNumber ? "#1472FEaa" : "#1472FE55"
                    }
                    strokeWidth={selectedUnit?.number === unitNumber ? "3" : "2"}
                    style={{
                      cursor: 'pointer',
                      pointerEvents: 'auto',
                      transition: 'all 0.3s ease'
                    }}
                    onMouseEnter={() => setHoveredUnit(unitNumber)}
                    onMouseLeave={() => setHoveredUnit(null)}
                    onClick={() => handleUnitClick(unit)}
                  >
                    <title>
                      {`Квартира ${unitNumber}\n${unit.bedrooms} спальни\n${unit.area} м²`}
                    </title>
                  </polygon>
                );
              })}
            </svg>
          </Box>

          {/* Список квартир */}
          <Stack spacing={2}>
            {selectedFloorUnits.map(unit => (
              <Paper
                key={unit.id}
                sx={{
                  p: 2,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  cursor: 'pointer',
                  bgcolor: hoveredUnit === unit.number ? 'action.hover' : 'background.paper',
                  '&:hover': {
                    bgcolor: 'action.hover'
                  }
                }}
                onClick={() => handleUnitClick(unit)}
                onMouseEnter={() => setHoveredUnit(unit.number)}
                onMouseLeave={() => setHoveredUnit(null)}
              >
                <Box sx={{ width: 80, height: 80 }}>
                  <img
                    src={unit.layout.planImageUrl}
                    alt={`План ${unit.number}`}
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="subtitle1" color="text.secondary">
                    Квартира № {unit.number}
                  </Typography>
                  <Typography variant="h6" color="primary">
                    THB {unit.price.toLocaleString()}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                    <Typography variant="body2">
                      {unit.bedrooms} комната
                    </Typography>
                    <Typography variant="body2">
                      {unit.area} м²
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      THB {unit.pricePerSqm.toLocaleString()}/м²
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            ))}
          </Stack>
        </Box>
      </Drawer>

      {/* Drawer с деталями квартиры */}
      <UnitDetailsDrawer
        unit={selectedUnit}
        open={isUnitDetailsOpen}
        onClose={() => setIsUnitDetailsOpen(false)}
      />
    </Box>
  );
};

export default FloorPlans;