import React, { useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Button,
  IconButton,
  Stack,
  Grid,
  Avatar,
  Chip,
  Dialog,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  Add as AddIcon,
  LocationOn,
  Person,
  Home,
  AccessTime
} from '@mui/icons-material';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isSameDay, isToday } from 'date-fns';
import { useCalendar } from '../../hooks/useCalendar';
import { CalendarEvent, CalendarEventType } from '../../types/calendar';
import EventDetailsModal from '../../components/Calendar/EventDetailsModal';

const eventTypes: { value: CalendarEventType; label: string }[] = [
  { value: 'viewing', label: 'Viewing' },
  { value: 'meeting', label: 'Meeting' },
  { value: 'reservation', label: 'Reservation' },
  { value: 'signing', label: 'Signing' },
  { value: 'payment', label: 'Payment' },
  { value: 'handover', label: 'Handover' }
];

const Calendar = () => {
  const {
    currentDate,
    setCurrentDate,
    events,
    upcomingEvents,
    selectedEvent,
    isEventModalOpen,
    setIsEventModalOpen,
    handleAddEvent,
    handleUpdateEvent,
    handleDeleteEvent,
    handleEventClick,
    getEventColor
  } = useCalendar();

  const [newEvent, setNewEvent] = useState<Partial<CalendarEvent>>({
    type: 'viewing',
    status: 'scheduled'
  });

  const handlePrevMonth = () => setCurrentDate(subMonths(currentDate, 1));
  const handleNextMonth = () => setCurrentDate(addMonths(currentDate, 1));

  const handleNewEventSubmit = () => {
    if (newEvent.title && newEvent.start && newEvent.end) {
      handleAddEvent(newEvent as Omit<CalendarEvent, 'id'>);
      setIsEventModalOpen(false);
      setNewEvent({ type: 'viewing', status: 'scheduled' });
    }
  };

  // Получаем все дни текущего месяца
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);
  const monthDays = eachDayOfInterval({ start: monthStart, end: monthEnd });

  // Функция для получения событий на конкретный день
  const getDayEvents = (day: Date) => {
    return events.filter(event => isSameDay(new Date(event.start), day));
  };

  const handleEditEvent = (event: CalendarEvent) => {
    // TODO: Implement edit functionality
    console.log('Edit event:', event);
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Box>
          <Typography variant="h5">Calendar</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Manage your appointments and events
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsEventModalOpen(true)}
        >
          Add Event
        </Button>
      </Box>

      {/* Calendar Header */}
      <Card sx={{ p: 2, mb: 3 }}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={2} alignItems="center">
            <IconButton onClick={handlePrevMonth}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h6">
              {format(currentDate, 'MMMM yyyy')}
            </Typography>
            <IconButton onClick={handleNextMonth}>
              <ChevronRight />
            </IconButton>
          </Stack>
          <Stack direction="row" spacing={1}>
            {eventTypes.map(type => (
              <Chip
                key={type.value}
                label={type.label}
                size="small"
                sx={{
                  bgcolor: `${getEventColor(type.value)}20`,
                  color: getEventColor(type.value),
                  '& .MuiChip-label': { px: 2 }
                }}
              />
            ))}
          </Stack>
        </Stack>
      </Card>

      {/* Calendar Grid and Upcoming Events */}
      <Grid container spacing={3}>
        {/* Calendar */}
        <Grid item xs={12} md={9}>
          <Card sx={{ p: 2 }}>
            <Grid container spacing={1}>
              {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
                <Grid item xs={12/7} key={day}>
                  <Typography
                    align="center"
                    sx={{ 
                      py: 1,
                      color: 'text.secondary',
                      fontSize: '0.875rem'
                    }}
                  >
                    {day}
                  </Typography>
                </Grid>
              ))}
              {monthDays.map(day => {
                const dayEvents = getDayEvents(day);
                return (
                  <Grid item xs={12/7} key={day.toString()}>
                    <Box
                      sx={{
                        p: 1,
                        minHeight: 120,
                        border: '1px solid',
                        borderColor: 'grey.200',
                        bgcolor: isToday(day) ? 'primary.50' : 'background.paper',
                        ...(isSameMonth(day, currentDate) ? {} : { opacity: 0.5 }),
                      }}
                    >
                      <Typography
                        align="center"
                        sx={{
                          fontWeight: isToday(day) ? 600 : 400,
                          color: isToday(day) ? 'primary.main' : 'text.primary',
                        }}
                      >
                        {format(day, 'd')}
                      </Typography>
                      <Stack spacing={0.5} mt={1}>
                        {dayEvents.map(event => (
                          <Chip
                            key={event.id}
                            label={event.title}
                            size="small"
                            onClick={() => handleEventClick(event)}
                            sx={{
                              bgcolor: `${getEventColor(event.type)}20`,
                              color: getEventColor(event.type),
                              '& .MuiChip-label': {
                                px: 1,
                                fontSize: '0.75rem',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }
                            }}
                          />
                        ))}
                      </Stack>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Card>
        </Grid>

        {/* Upcoming Events */}
        <Grid item xs={12} md={3}>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Upcoming Events
            </Typography>
            <Stack spacing={2}>
              {upcomingEvents.map(event => (
                <Box
                  key={event.id}
                  sx={{
                    p: 2,
                    border: '1px solid',
                    borderColor: 'grey.200',
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: 'grey.50'
                    }
                  }}
                  onClick={() => handleEventClick(event)}
                >
                  <Stack spacing={1}>
                    <Typography variant="subtitle2">
                      {event.title}
                    </Typography>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <AccessTime sx={{ fontSize: 16, color: 'grey.500' }} />
                        <Typography variant="caption">
                          {format(new Date(event.start), 'HH:mm')}
                        </Typography>
                      </Box>
                      {event.location && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <LocationOn sx={{ fontSize: 16, color: 'grey.500' }} />
                          <Typography variant="caption">
                            {event.location}
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                    {event.assignee && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Avatar
                          src={event.assignee.avatar}
                          sx={{ width: 24, height: 24 }}
                        />
                        <Typography variant="caption">
                          {event.assignee.name}
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Box>
              ))}
            </Stack>
          </Card>
        </Grid>
      </Grid>

      {/* Event Modal */}
      <Dialog
        open={isEventModalOpen}
        onClose={() => setIsEventModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ mb: 3 }}>
            {selectedEvent ? 'Edit Event' : 'Add New Event'}
          </Typography>
          
          <Stack spacing={3}>
            <FormControl fullWidth>
              <InputLabel>Event Type</InputLabel>
              <Select
                value={newEvent.type}
                onChange={(e) => setNewEvent(prev => ({ ...prev, type: e.target.value as CalendarEventType }))}
              >
                {eventTypes.map(type => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Title"
              value={newEvent.title || ''}
              onChange={(e) => setNewEvent(prev => ({ ...prev, title: e.target.value }))}
            />

            <TextField
              fullWidth
              label="Client Name"
              value={newEvent.clientName || ''}
              onChange={(e) => setNewEvent(prev => ({ ...prev, clientName: e.target.value }))}
              InputProps={{
                startAdornment: <Person sx={{ mr: 1, color: 'action.active' }} />
              }}
            />

            <TextField
              fullWidth
              label="Property"
              value={newEvent.propertyName || ''}
              onChange={(e) => setNewEvent(prev => ({ ...prev, propertyName: e.target.value }))}
              InputProps={{
                startAdornment: <Home sx={{ mr: 1, color: 'action.active' }} />
              }}
            />

            <TextField
              fullWidth
              label="Location"
              value={newEvent.location || ''}
              onChange={(e) => setNewEvent(prev => ({ ...prev, location: e.target.value }))}
              InputProps={{
                startAdornment: <LocationOn sx={{ mr: 1, color: 'action.active' }} />
              }}
            />

            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                type="datetime-local"
                label="Start Time"
                value={newEvent.start ? format(new Date(newEvent.start), "yyyy-MM-dd'T'HH:mm") : ''}
                onChange={(e) => setNewEvent(prev => ({ ...prev, start: new Date(e.target.value) }))}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                type="datetime-local"
                label="End Time"
                value={newEvent.end ? format(new Date(newEvent.end), "yyyy-MM-dd'T'HH:mm") : ''}
                onChange={(e) => setNewEvent(prev => ({ ...prev, end: new Date(e.target.value) }))}
                InputLabelProps={{ shrink: true }}
              />
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => setIsEventModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleNewEventSubmit}
              >
                Save Event
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Dialog>

      {/* Event Details Modal */}
      <EventDetailsModal
        event={selectedEvent}
        open={isEventModalOpen}
        onClose={() => setIsEventModalOpen(false)}
        onEdit={handleEditEvent}
        onDelete={handleDeleteEvent}
      />
    </Box>
  );
};

export default Calendar; 