import React from 'react';
import {
  Box,
  Card,
  Typography,
  Stack,
  Chip,
  IconButton,
  Avatar,
  LinearProgress,
  Grid
} from '@mui/material';
import {
  LocationOn,
  CalendarToday,
  MoreVert,
  ArrowUpward
} from '@mui/icons-material';
import { companyData } from '../../../data/company';

interface ProjectCardProps {
  id: string;
  name: string;
  location: string;
  status: string;
  progress: number;
  sales: {
    total: number;
    sold: number;
    growth: number;
  };
  team: Array<{
    id: number;
    name: string;
    avatar: string;
  }>;
}

const ProjectCard = ({ name, location, status, progress, sales, team }: ProjectCardProps) => {
  return (
    <Card sx={{ p: 2 }}>
      <Stack spacing={2}>
        {/* Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Box>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              {name}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <LocationOn sx={{ fontSize: 16, color: 'grey.500' }} />
              <Typography variant="body2" color="text.secondary">
                {location}
              </Typography>
            </Stack>
          </Box>
          <IconButton size="small">
            <MoreVert fontSize="small" />
          </IconButton>
        </Box>

        {/* Status and Progress */}
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Chip
              label={status}
              size="small"
              sx={{
                bgcolor: 'primary.50',
                color: 'primary.main',
                fontWeight: 500
              }}
            />
            <Typography variant="body2" color="text.secondary">
              {progress}% completed
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: 6,
              borderRadius: 1,
              bgcolor: 'grey.100',
              '& .MuiLinearProgress-bar': {
                borderRadius: 1
              }
            }}
          />
        </Box>

        {/* Sales Info */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Box>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Sales
            </Typography>
            <Typography variant="h6">
              {sales.sold} / {sales.total} units
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'right' }}>
            <Stack direction="row" spacing={0.5} alignItems="center" sx={{ mb: 0.5 }}>
              <ArrowUpward sx={{ fontSize: 16, color: 'success.main' }} />
              <Typography
                variant="body2"
                sx={{
                  color: 'success.main',
                  fontWeight: 500
                }}
              >
                {sales.growth}%
              </Typography>
            </Stack>
            <Typography variant="caption" color="text.secondary">
              vs last month
            </Typography>
          </Box>
        </Box>

        {/* Team */}
        <Box>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Team
          </Typography>
          <Stack direction="row" spacing={-1}>
            {team.map((member) => (
              <Avatar
                key={member.id}
                src={member.avatar}
                alt={member.name}
                sx={{
                  width: 32,
                  height: 32,
                  border: '2px solid white'
                }}
              />
            ))}
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};

const ProjectsList = () => {
  const projects: ProjectCardProps[] = [
    {
      id: 'p1',
      name: 'VIP Space Odyssey',
      location: 'Nai Harn, Phuket',
      status: 'In Progress',
      progress: 75,
      sales: {
        total: 600,
        sold: 450,
        growth: 12
      },
      team: [
        { id: 1, name: 'Olivia Rhye', avatar: '/assets/images/avatars/olivia.jpg' },
        { id: 2, name: 'Phoenix Baker', avatar: '/assets/images/avatars/phoenix.jpg' },
        { id: 3, name: 'Lana Steiner', avatar: '/assets/images/avatars/lana.jpg' }
      ]
    },
    {
      id: 'p2',
      name: 'VIP Galaxy Villas',
      location: 'Rawai, Phuket',
      status: 'Planning',
      progress: 25,
      sales: {
        total: 19,
        sold: 8,
        growth: 8
      },
      team: [
        { id: 4, name: 'Drew Cano', avatar: '/assets/images/avatars/drew.jpg' },
        { id: 5, name: 'Candice Wu', avatar: '/assets/images/avatars/candice.jpg' }
      ]
    },
    // Добавьте больше проектов по необходимости
  ];

  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Active Projects
      </Typography>

      <Grid container spacing={3}>
        {projects.map((project) => (
          <Grid item xs={12} md={6} key={project.id}>
            <ProjectCard {...project} />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default ProjectsList; 