import React from 'react';
import { Box, Card, Typography, IconButton, Stack } from '@mui/material';
import { MoreVert, LocationOn } from '@mui/icons-material';
import ProjectImage from './ProjectImage';
import ProjectBadge from './ProjectBadge';
import ProjectPrice from './ProjectPrice';
import ProjectDetails from './ProjectDetails';

const ProjectCard = ({ project }) => {
  const {
    type,
    name,
    location,
    price,
    details,
    guarantee,
    discount,
    image
  } = project;

  return (
    <Card 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <ProjectImage src={image} alt={name} />
        {guarantee && (
          <ProjectBadge 
            type="guarantee" 
            value={`${guarantee.percent}% guaranteed return for ${guarantee.years} years`}
          />
        )}
      </Box>

      <Box sx={{ p: 3, flexGrow: 1 }}>
        <Stack spacing={2}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Box>
              <Typography 
                variant="caption" 
                color="primary.main" 
                sx={{ fontWeight: 500, display: 'block', mb: 1 }}
              >
                {type}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                {name}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <LocationOn sx={{ fontSize: 16, color: 'grey.500' }} />
                <Typography variant="body2" color="text.secondary">
                  {`${location.area}, ${location.city}, ${location.country}`}
                </Typography>
              </Stack>
            </Box>
            <IconButton size="small">
              <MoreVert fontSize="small" />
            </IconButton>
          </Box>

          <ProjectPrice price={price} discount={discount} />
          <ProjectDetails details={details} />
        </Stack>
      </Box>
    </Card>
  );
};

export default ProjectCard; 