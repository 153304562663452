import { useState, useCallback, useMemo } from 'react';
import { CalendarEvent, CalendarEventType } from '../types/calendar';
import { format, addHours, startOfMonth, endOfMonth, isWithinInterval } from 'date-fns';
import { calendarEventsData } from '../data/calendarEvents';

interface UseCalendarOptions {
  defaultView?: 'month' | 'week' | 'day';
  defaultDate?: Date;
}

export const useCalendar = (options: UseCalendarOptions = {}) => {
  const [currentDate, setCurrentDate] = useState(options.defaultDate || new Date());
  const [view, setView] = useState(options.defaultView || 'month');
  const [events, setEvents] = useState<CalendarEvent[]>(calendarEventsData);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);

  const visibleEvents = useMemo(() => {
    const start = startOfMonth(currentDate);
    const end = endOfMonth(currentDate);
    
    return events.filter(event => 
      isWithinInterval(new Date(event.start), { start, end })
    );
  }, [events, currentDate]);

  const upcomingEvents = useMemo(() => {
    const now = new Date();
    return events
      .filter(event => new Date(event.start) > now)
      .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
      .slice(0, 5);
  }, [events]);

  const handleAddEvent = useCallback((event: Omit<CalendarEvent, 'id'>) => {
    const newEvent: CalendarEvent = {
      ...event,
      id: Date.now().toString()
    };
    setEvents(prev => [...prev, newEvent]);
  }, []);

  const handleUpdateEvent = useCallback((eventId: string, updates: Partial<CalendarEvent>) => {
    setEvents(prev => prev.map(event => 
      event.id === eventId ? { ...event, ...updates } : event
    ));
  }, []);

  const handleDeleteEvent = useCallback((eventId: string) => {
    setEvents(prev => prev.filter(event => event.id !== eventId));
  }, []);

  const handleEventClick = useCallback((event: CalendarEvent) => {
    setSelectedEvent(event);
    setIsEventModalOpen(true);
  }, []);

  const getEventColor = useCallback((type: CalendarEventType) => {
    const colors = {
      viewing: '#7F56D9',      // Фиолетовый
      meeting: '#12B76A',      // Зеленый
      reservation: '#F79009',   // Оранжевый
      signing: '#0BA5EC',      // Голубой
      payment: '#F04438',      // Красный
      handover: '#6941C6'      // Темно-фиолетовый
    };
    return colors[type];
  }, []);

  return {
    currentDate,
    setCurrentDate,
    view,
    setView,
    events: visibleEvents,
    upcomingEvents,
    selectedEvent,
    isEventModalOpen,
    setIsEventModalOpen,
    handleAddEvent,
    handleUpdateEvent,
    handleDeleteEvent,
    handleEventClick,
    getEventColor
  };
}; 