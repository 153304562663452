import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  Stack,
  Chip,
  Button,
  Divider,
  IconButton,
  Link,
  Tab,
  Tabs,
  ImageList,
  ImageListItem,
  Skeleton
} from '@mui/material';
import {
  LocationOn,
  Home,
  Business,
  AttachMoney,
  Construction,
  Pool,
  Share,
  Favorite,
  OpenInNew,
  YouTube,
  Facebook,
  Language,
  NavigateBefore,
  NavigateNext
} from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';

// Компонент для карты
const PropertyMap = ({ lat, long }: { lat: number; long: number }) => (
  <Box sx={{ height: 400, width: '100%', bgcolor: 'grey.100', borderRadius: 1 }}>
    <iframe
      width="100%"
      height="100%"
      frameBorder="0"
      src={`https://www.openstreetmap.org/export/embed.html?bbox=${long-0.01}%2C${lat-0.01}%2C${long+0.01}%2C${lat+0.01}&layer=mapnik&marker=${lat}%2C${long}`}
    />
  </Box>
);

// Компонент галереи
const Gallery = ({ images }: { images: any[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
      {loading && (
        <Skeleton 
          variant="rectangular" 
          width="100%" 
          height="100%" 
          sx={{ position: 'absolute', top: 0, left: 0 }}
        />
      )}
      
      <Box
        component="img"
        src={`https://restate.kdigital.pro${images[currentIndex]?.attributes.url}`}
        alt=""
        onLoad={handleImageLoad}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          display: loading ? 'none' : 'block'
        }}
      />
      
      {/* Навигация */}
      <Stack 
        direction="row" 
        spacing={2} 
        sx={{ 
          position: 'absolute', 
          bottom: 16, 
          left: '50%', 
          transform: 'translateX(-50%)',
          bgcolor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: 2,
          p: 1
        }}
      >
        {images.map((img, index) => (
          <Box
            key={index}
            onClick={() => setCurrentIndex(index)}
            sx={{
              width: 60,
              height: 40,
              borderRadius: 1,
              overflow: 'hidden',
              cursor: 'pointer',
              border: index === currentIndex ? '2px solid white' : 'none',
              opacity: index === currentIndex ? 1 : 0.6,
              transition: 'all 0.2s',
              '&:hover': { opacity: 1 }
            }}
          >
            <Box
              component="img"
              src={`https://restate.kdigital.pro${img.attributes.formats.thumbnail.url}`}
              alt=""
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          </Box>
        ))}
      </Stack>

      {/* Стрелки навигации */}
      <IconButton
        onClick={() => setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1))}
        sx={{
          position: 'absolute',
          left: 16,
          top: '50%',
          transform: 'translateY(-50%)',
          bgcolor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' }
        }}
      >
        <NavigateBefore />
      </IconButton>

      <IconButton
        onClick={() => setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1))}
        sx={{
          position: 'absolute',
          right: 16,
          top: '50%',
          transform: 'translateY(-50%)',
          bgcolor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' }
        }}
      >
        <NavigateNext />
      </IconButton>
    </Box>
  );
};

// Компонент для шиммера
const PropertyDetailsSkeleton = () => (
  <Box>
    {/* Шиммер галереи */}
    <Skeleton variant="rectangular" height={500} sx={{ mb: 4 }} />
    
    {/* Шиммер заголовка */}
    <Container maxWidth="lg">
      <Stack spacing={2} sx={{ mb: 4 }}>
        <Skeleton variant="text" width="60%" height={40} />
        <Skeleton variant="text" width="40%" height={24} />
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Stack spacing={3}>
            {[1, 2, 3].map((i) => (
              <Card key={i} sx={{ p: 3 }}>
                <Stack spacing={2}>
                  <Skeleton variant="text" width="30%" height={24} />
                  <Skeleton variant="text" width="100%" height={20} />
                  <Skeleton variant="text" width="100%" height={20} />
                  <Skeleton variant="text" width="80%" height={20} />
                </Stack>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            {[1, 2].map((i) => (
              <Card key={i} sx={{ p: 3 }}>
                <Stack spacing={2}>
                  <Skeleton variant="text" width="40%" height={24} />
                  <Skeleton variant="text" width="100%" height={20} />
                  <Skeleton variant="rectangular" height={40} />
                </Stack>
              </Card>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

const PropertyDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [property, setProperty] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      try {
        const response = await fetch(`https://restate.kdigital.pro/api/objects/${id}?populate=*`);
        const data = await response.json();
        setProperty(data.data);
      } catch (error) {
        console.error('Error fetching property:', error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchPropertyDetails();
    }
  }, [id]);

  if (loading || !property) {
    return <PropertyDetailsSkeleton />;
  }

  const { attributes: p } = property;

  const formatDescription = (text: string) => {
    return text?.split('\n').map((paragraph, index) => (
      <Typography key={index} paragraph>
        {paragraph}
      </Typography>
    ));
  };

  return (
    <Box>
      {/* Галерея на всю ширину */}
      <Box sx={{ width: '100%', height: 500, bgcolor: 'grey.100', mb: 4 }}>
        <Gallery images={p.image?.data || []} />
      </Box>

      <Container maxWidth="lg">
        {/* Header */}
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
          <Stack spacing={1}>
            <Typography variant="h4">{p.name}</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <LocationOn color="action" />
              <Typography color="text.secondary">{p.address}</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button startIcon={<Share />}>Share</Button>
            <Button startIcon={<Favorite />}>Save</Button>
          </Stack>
        </Stack>

        {/* Main Content */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            {/* Key Features */}
            <Card sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>Key Features</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <Stack spacing={1}>
                    <Typography color="text.secondary" variant="caption">Type</Typography>
                    <Typography>{p.type}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Stack spacing={1}>
                    <Typography color="text.secondary" variant="caption">Class</Typography>
                    <Typography>{p.house_class}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Stack spacing={1}>
                    <Typography color="text.secondary" variant="caption">Construction</Typography>
                    <Typography>{p.house_type}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Stack spacing={1}>
                    <Typography color="text.secondary" variant="caption">Floors</Typography>
                    <Typography>{p.floor_count}</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Card>

            {/* Description */}
            <Card sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>Description</Typography>
              {formatDescription(p.description)}
            </Card>

            {/* Location Description */}
            <Card sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>Location</Typography>
              {formatDescription(p.description_location)}
            </Card>

            {/* Map */}
            <Card sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>Map</Typography>
              <PropertyMap lat={p.location_lat} long={p.location_long} />
            </Card>
          </Grid>

          {/* Sidebar */}
          <Grid item xs={12} md={4}>
            <Stack spacing={3}>
              {/* Price Card */}
              <Card sx={{ p: 3 }}>
                <Stack spacing={2}>
                  <Typography variant="h5">
                    {new Intl.NumberFormat('ru-RU').format(Number(p.price))} ₽
                  </Typography>
                  {p.commission && (
                    <Typography variant="body2" color="text.secondary">
                      Commission: {p.commission}
                    </Typography>
                  )}
                  <Button variant="contained" fullWidth>Contact Agent</Button>
                </Stack>
              </Card>

              {/* Links Card */}
              <Card sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>Links</Typography>
                <Stack spacing={2}>
                  {p.site && (
                    <Link href={p.site} target="_blank" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Language /> Website
                    </Link>
                  )}
                  {p.video && (
                    <Link href={p.video} target="_blank" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <YouTube /> Video
                    </Link>
                  )}
                  {p.social && (
                    <Link href={p.social} target="_blank" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Facebook /> Social Media
                    </Link>
                  )}
                </Stack>
              </Card>

              {/* Construction Dates - добавляем проверку на существование и тип данных */}
              {p.date_built && Array.isArray(p.date_built) && p.date_built.length > 0 && (
                <Card sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom>Construction Period</Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap">
                    {p.date_built.map((year: number) => (
                      <Chip 
                        key={year} 
                        label={year} 
                        sx={{ m: 0.5 }}
                      />
                    ))}
                  </Stack>
                </Card>
              )}

              {/* Purpose Card - также добавляем проверку */}
              {p.purpose && Array.isArray(p.purpose) && p.purpose.length > 0 && (
                <Card sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom>Purpose</Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap">
                    {p.purpose.map((purpose: string) => (
                      <Chip 
                        key={purpose} 
                        label={purpose}
                        sx={{ m: 0.5 }}
                      />
                    ))}
                  </Stack>
                </Card>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PropertyDetails; 