import { Box, Typography, Paper, TextField, Button, Grid, CircularProgress, FormControl, InputLabel, Select, MenuItem, Divider, Checkbox, FormControlLabel, FormGroup, IconButton, Tooltip, LinearProgress } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';
import { NewObject } from '../../types/object';
import { ImageUpload } from './ImageUpload';
import { MapLocation } from './MapLocation';
import { DocumentUpload } from './DocumentUpload';
import { AmenitiesSelect } from './AmenitiesSelect';
import { Breadcrumbs } from './Breadcrumbs';
import { Home, Info, LocationOn, Description, PhotoCamera, AttachFile, AccountBox, Public, Star, Payment } from '@mui/icons-material';

export const NewObjectPage = () => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0); // Прогресс заполнения формы
  const [formData, setFormData] = useState<NewObject>({
    title: '',
    description: '',
    location: {
      lat: 0,
      lng: 0
    },
    images: [],
    propertyType: '',
    category: '',
    dealType: 'sale',
    address: '',
    city: '',
    postalCode: '',
    country: '',
    totalArea: '',
    livingArea: '',
    floors: '',
    currentFloor: '',
    bedrooms: '',
    bathrooms: '',
    parking: '',
    yearBuilt: '',
    price: '',
    currency: 'USD',
    paymentTerms: '',
    amenities: [],
    status: 'available',
    documents: [],
    // Контактная информация
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    contactPosition: '',
    // Публичная информация
    isPublished: false,
    showPrice: true,
    showContacts: true,
    // Дополнительные опции
    isVip: false,
    isHot: false,
    isNewBuilding: false,
    isExclusive: false
  });

  const calculateProgress = () => {
    const requiredFields = ['title', 'propertyType', 'address', 'city', 'country', 'totalArea', 'price'] as const;
    type RequiredField = typeof requiredFields[number];
    
    const filledFields = requiredFields.filter((field: RequiredField) => {
      const value = formData[field];
      return value !== undefined && value !== '' && value !== null;
    });
    
    setProgress((filledFields.length / requiredFields.length) * 100);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    calculateProgress();
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLocationChange = (location: { lat: number; lng: number }) => {
    setFormData(prev => ({
      ...prev,
      location
    }));
  };

  const handleImageChange = (files: File[]) => {
    setFormData(prev => ({
      ...prev,
      images: files
    }));
  };

  const handleDocumentChange = (files: File[]) => {
    setFormData(prev => ({
      ...prev,
      documents: files
    }));
  };

  const handleAmenitiesChange = (amenities: string[]) => {
    setFormData(prev => ({
      ...prev,
      amenities
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      // TODO: Добавить API запрос
      console.log('Отправка данных:', formData);
    } catch (error) {
      console.error('Ошибка:', error);
    } finally {
      setLoading(false);
    }
  };

  const SectionTitle = ({ icon, title }: { icon: React.ReactNode; title: string }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      {icon}
      <Typography variant="h6" sx={{ ml: 1 }}>
        {title}
      </Typography>
    </Box>
  );

  const FieldWithTooltip = ({ children, tooltip }: { children: React.ReactNode; tooltip: string }) => (
    <Box sx={{ display: 'flex', alignItems: 'start' }}>
      <Box flexGrow={1}>{children}</Box>
      <Tooltip title={tooltip}>
        <IconButton size="small" sx={{ ml: 1, mt: 1 }}>
          <Info fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', mt: 4 }}>
      <Breadcrumbs />
      
      <Paper sx={{ position: 'relative' }}>
        <LinearProgress 
          variant="determinate" 
          value={progress} 
          sx={{ 
            position: 'absolute', 
            top: 0, 
            left: 0, 
            right: 0,
            height: 4,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4
          }} 
        />
        
        <Box sx={{ p: 3, pt: 4 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Основная информация */}
              <Grid item xs={12}>
                <SectionTitle icon={<Home color="primary" />} title="Основная информация" />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FieldWithTooltip tooltip="Введите название, которое будет отображаться в списке объектов">
                      <TextField
                        fullWidth
                        required
                        label="Название объекта"
                        name="title"
                        value={formData.title}
                        onChange={handleInputChange}
                      />
                    </FieldWithTooltip>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth required>
                      <InputLabel>Тип сделки</InputLabel>
                      <Select
                        name="dealType"
                        value={formData.dealType}
                        onChange={handleSelectChange}
                        label="Тип сделки"
                      >
                        <MenuItem value="sale">Продажа</MenuItem>
                        <MenuItem value="rent">Аренда</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth required>
                      <InputLabel>Категория</InputLabel>
                      <Select
                        name="category"
                        value={formData.category}
                        onChange={handleSelectChange}
                        label="Категория"
                      >
                        <MenuItem value="residential">Жилая</MenuItem>
                        <MenuItem value="commercial">Коммерческая</MenuItem>
                        <MenuItem value="industrial">Промышленная</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth required>
                      <InputLabel>Тип недвижимости</InputLabel>
                      <Select
                        name="propertyType"
                        value={formData.propertyType}
                        onChange={handleSelectChange}
                        label="Тип недвижимости"
                      >
                        <MenuItem value="apartment">Квартира</MenuItem>
                        <MenuItem value="house">Дом</MenuItem>
                        <MenuItem value="commercial">Коммерческая недвижимость</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="Адрес"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      required
                      label="Город"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Почтовый индекс"
                      name="postalCode"
                      value={formData.postalCode}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      required
                      label="Страна"
                      name="country"
                      value={formData.country}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Местоположение */}
              <Grid item xs={12}>
                <SectionTitle icon={<LocationOn color="primary" />} title="Местоположение на карте" />
                <MapLocation 
                  location={formData.location}
                  onChange={handleLocationChange}
                />
              </Grid>

              <Grid item xs={12}>
                <SectionTitle icon={<Description color="primary" />} title="Оп��сание" />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      required
                      label="Общая площадь"
                      name="totalArea"
                      type="number"
                      value={formData.totalArea}
                      onChange={handleInputChange}
                      InputProps={{ endAdornment: 'м²' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="Жилая площадь"
                      name="livingArea"
                      type="number"
                      value={formData.livingArea}
                      onChange={handleInputChange}
                      InputProps={{ endAdornment: 'м²' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="Этажей"
                      name="floors"
                      type="number"
                      value={formData.floors}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="Спален"
                      name="bedrooms"
                      type="number"
                      value={formData.bedrooms}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="Санузлов"
                      name="bathrooms"
                      type="number"
                      value={formData.bathrooms}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="Парковочных мест"
                      name="parking"
                      type="number"
                      value={formData.parking}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="Год постройки"
                      name="yearBuilt"
                      type="number"
                      value={formData.yearBuilt}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <InputLabel>Статус</InputLabel>
                      <Select
                        name="status"
                        value={formData.status}
                        onChange={handleSelectChange}
                        label="Статус"
                      >
                        <MenuItem value="available">Доступен</MenuItem>
                        <MenuItem value="reserved">Забронирован</MenuItem>
                        <MenuItem value="sold">Продан</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="Этаж"
                      name="currentFloor"
                      type="number"
                      value={formData.currentFloor}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Удобства */}
              <Grid item xs={12}>
                <Typography variant="h6" mb={2}>Удобства</Typography>
                <AmenitiesSelect
                  value={formData.amenities}
                  onChange={handleAmenitiesChange}
                />
              </Grid>

              <Grid item xs={12}>
                <SectionTitle icon={<Description color="primary" />} title="Описание" />
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Описание объекта"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <SectionTitle icon={<PhotoCamera color="primary" />} title="Фотографии" />
                <ImageUpload 
                  images={formData.images}
                  onChange={handleImageChange}
                />
              </Grid>

              <Grid item xs={12}>
                <SectionTitle icon={<AttachFile color="primary" />} title="Документы" />
                <DocumentUpload
                  documents={formData.documents}
                  onChange={handleDocumentChange}
                />
              </Grid>

              <Grid item xs={12}>
                <SectionTitle icon={<AccountBox color="primary" />} title="Контактная информация" />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      label="Имя контактного лица"
                      name="contactName"
                      value={formData.contactName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      label="Должность"
                      name="contactPosition"
                      value={formData.contactPosition}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      label="Телефон"
                      name="contactPhone"
                      value={formData.contactPhone}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      label="Email"
                      name="contactEmail"
                      type="email"
                      value={formData.contactEmail}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <SectionTitle icon={<Public color="primary" />} title="Публичная информация" />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.isPublished}
                        onChange={handleCheckboxChange}
                        name="isPublished"
                      />
                    }
                    label="Опубликовать объект"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.showPrice}
                        onChange={handleCheckboxChange}
                        name="showPrice"
                      />
                    }
                    label="Показывать цену"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.showContacts}
                        onChange={handleCheckboxChange}
                        name="showContacts"
                      />
                    }
                    label="Показывать контакты"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <SectionTitle icon={<Star color="primary" />} title="Дополнительные опции" />
                <FormGroup>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.isVip}
                            onChange={handleCheckboxChange}
                            name="isVip"
                          />
                        }
                        label="VIP объект"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.isHot}
                            onChange={handleCheckboxChange}
                            name="isHot"
                          />
                        }
                        label="Горячее предложение"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.isNewBuilding}
                            onChange={handleCheckboxChange}
                            name="isNewBuilding"
                          />
                        }
                        label="Новостройка"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.isExclusive}
                            onChange={handleCheckboxChange}
                            name="isExclusive"
                          />
                        }
                        label="Эксклюзив"
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <SectionTitle icon={<Payment color="primary" />} title="Финансовая информация" />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      label="Цена"
                      name="price"
                      type="number"
                      value={formData.price}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Валюта</InputLabel>
                      <Select
                        name="currency"
                        value={formData.currency}
                        onChange={handleSelectChange}
                        label="Валюта"
                      >
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="EUR">EUR</MenuItem>
                        <MenuItem value="RUB">RUB</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Условия оплаты"
                      name="paymentTerms"
                      value={formData.paymentTerms}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Кнопки действий */}
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                  <Button 
                    variant="outlined" 
                    color="inherit"
                    disabled={loading}
                  >
                    Отмена
                  </Button>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button 
                      variant="outlined"
                      disabled={loading}
                    >
                      Сохранить черновик
                    </Button>
                    <Button 
                      type="submit"
                      variant="contained"
                      disabled={loading || progress < 100}
                      startIcon={loading && <CircularProgress size={20} />}
                    >
                      Опубликовать
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Box>
  );
}; 