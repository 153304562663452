import React from 'react';
import { Box, Container } from '@mui/material';
import CompanyCover from './components/CompanyCover';
import CompanyInfo from './components/CompanyInfo';
import LicenseList from './components/LicenseList';
import BrokerageAgreements from './components/BrokerageAgreements';
import CompanyStats from './components/CompanyStats';
import ProjectFilters from './components/ProjectFilters';
import ProjectsMap from './components/ProjectsMap';
import ProjectsList from './components/ProjectsList';

const CompanyPage = () => {
  return (
    <Box>
      <CompanyCover />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <CompanyInfo />
          <BrokerageAgreements />
          <LicenseList />
          <CompanyStats />
          <ProjectFilters />
          <ProjectsMap />
          <ProjectsList />
        </Box>
      </Container>
    </Box>
  );
};

export default CompanyPage; 