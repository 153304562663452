import { Box, Button, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { UploadFile, Description, Delete } from '@mui/icons-material';

interface DocumentUploadProps {
  documents: File[];
  onChange: (files: File[]) => void;
}

export const DocumentUpload = ({ documents, onChange }: DocumentUploadProps) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      onChange([...documents, ...newFiles]);
    }
  };

  const handleDelete = (index: number) => {
    const newFiles = documents.filter((_, i) => i !== index);
    onChange(newFiles);
  };

  return (
    <Box>
      <input
        type="file"
        multiple
        accept=".pdf,.doc,.docx,.xls,.xlsx"
        style={{ display: 'none' }}
        id="document-upload"
        onChange={handleFileChange}
      />
      <label htmlFor="document-upload">
        <Button
          component="span"
          variant="outlined"
          startIcon={<UploadFile />}
        >
          Загрузить документы
        </Button>
      </label>

      {documents.length > 0 && (
        <List sx={{ mt: 2 }}>
          {documents.map((file, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton edge="end" onClick={() => handleDelete(index)}>
                  <Delete />
                </IconButton>
              }
            >
              <ListItemIcon>
                <Description />
              </ListItemIcon>
              <ListItemText
                primary={file.name}
                secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
}; 