import { Breadcrumbs as MuiBreadcrumbs, Link, Typography, Box, Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export const Breadcrumbs = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
      <Button
        startIcon={<ArrowBack />}
        onClick={() => navigate(-1)}
        sx={{ mr: 3 }}
      >
        Назад
      </Button>
      <MuiBreadcrumbs>
        <Link color="inherit" href="/properties">
          Объекты
        </Link>
        <Typography color="text.primary">Добавление объекта</Typography>
      </MuiBreadcrumbs>
    </Box>
  );
}; 