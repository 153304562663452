import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider } from '@mui/material';

const messages = [
  {
    id: 1,
    from: 'John Doe',
    subject: 'Viewing Request',
    message: 'I would like to schedule a viewing...',
    date: '2024-03-20 10:30',
    unread: true
  },
  // Add more messages
];

const Messages = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5">Messages</Typography>
      <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 3 }}>
        Manage your communications
      </Typography>
      
      <List>
        {messages.map((message) => (
          <React.Fragment key={message.id}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar>{message.from[0]}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={message.subject}
                secondary={message.message}
              />
            </ListItem>
            <Divider component="li" />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default Messages; 