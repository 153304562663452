import React from 'react';
import { Box, IconButton, Typography, Stack, Button } from '@mui/material';
import { Edit as EditIcon, Share as ShareIcon } from '@mui/icons-material';
import { companyData } from '../../../data/company';
import { projectImages } from '../../../assets/images';

const CompanyCover = () => {
  return (
    <Box sx={{ position: 'relative', height: 320 }}>
      {/* Cover Image */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '100%',
          backgroundImage: `url(${projectImages['cover']})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%)'
          }
        }}
      />

      {/* Actions */}
      <Box
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          display: 'flex',
          gap: 1
        }}
      >
        <IconButton
          sx={{
            bgcolor: 'background.paper',
            '&:hover': { bgcolor: 'grey.100' }
          }}
        >
          <ShareIcon />
        </IconButton>
        <IconButton
          sx={{
            bgcolor: 'background.paper',
            '&:hover': { bgcolor: 'grey.100' }
          }}
        >
          <EditIcon />
        </IconButton>
      </Box>

      {/* Company Info */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          p: 3,
          color: 'white'
        }}
      >
        <Stack direction="row" alignItems="flex-end" spacing={3}>
          {/* Logo */}
          <Box
            sx={{
              width: 96,
              height: 96,
              bgcolor: 'background.paper',
              borderRadius: 2,
              mb: -2,
              overflow: 'hidden',
              boxShadow: 1,
              '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }
            }}
          >
            <img src={companyData.logo} alt={companyData.name} />
          </Box>

          {/* Info */}
          <Box sx={{ flex: 1 }}>
            <Typography variant="h4" sx={{ mb: 1, textShadow: '0 2px 4px rgba(0,0,0,0.3)' }}>
              {companyData.name}
            </Typography>
            <Typography variant="subtitle1" sx={{ textShadow: '0 1px 2px rgba(0,0,0,0.3)' }}>
              {companyData.description}
            </Typography>
          </Box>

          {/* Contact Button */}
          <Button
            variant="contained"
            sx={{
              bgcolor: 'background.paper',
              color: 'text.primary',
              '&:hover': {
                bgcolor: 'grey.100'
              }
            }}
          >
            Contact Us
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default CompanyCover; 