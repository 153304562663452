import React, { useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  Avatar,
  Button,
  Stack,
  Chip,
  LinearProgress,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Rating
} from '@mui/material';
import {
  School as SchoolIcon,
  Person as PersonIcon,
  Assignment as AssignmentIcon,
  EmojiEvents as TrophyIcon,
  Quiz as QuizIcon,
  Timeline as TimelineIcon,
  ArrowForward as ArrowForwardIcon,
  Leaderboard as LeaderboardIcon,
  PlayArrow as PlayArrowIcon,
  AccessTime as TimeIcon,
  MenuBook as BookIcon,
  Preview as PreviewIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { mockCourseModules } from '../../data/mockTrainingData';
import { mockMaterials } from '../../data/materials';

interface StatCardProps {
  title: string;
  value: number | string;
  icon: React.ReactNode;
  color?: string;
}

const StatCard: React.FC<StatCardProps> = ({ title, value, icon, color = 'primary.main' }) => (
  <Card>
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Avatar sx={{ bgcolor: color, mr: 2 }}>{icon}</Avatar>
        <Typography variant="h6" color="text.secondary">
          {title}
        </Typography>
      </Box>
      <Typography variant="h4">{value}</Typography>
    </CardContent>
  </Card>
);

const TrainingDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [selectedCourse, setSelectedCourse] = useState<any | null>(null);
  const [previewOpen, setPreviewOpen] = useState(false);

  const mockAgent = {
    name: 'Иван Иванов',
    email: 'ivan.ivanov@example.com',
    completedCourses: 3,
    totalScore: 850,
    rank: 'Старший агент',
    currentCourse: mockCourseModules[0].title,
    progress: 67
  };

  const stats = [
    { title: 'Пройдено курсов', value: mockAgent.completedCourses, icon: <SchoolIcon /> },
    { title: 'Общий балл', value: mockAgent.totalScore, icon: <TrophyIcon /> },
    { title: 'Тестов сдано', value: 12, icon: <AssignmentIcon /> },
    { title: 'Текущий ранг', value: mockAgent.rank, icon: <PersonIcon /> }
  ];

  const handleStartCourse = (course: any) => {
    navigate(`/agent-training/course/${course.id}`);
  };

  const handlePreviewCourse = (course: any) => {
    setSelectedCourse(course);
    setPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
    setSelectedCourse(null);
  };

  const handleStartTest = () => {
    navigate('/training/test-generator');
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Панель обучения
      </Typography>

      {/* Статистика */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StatCard {...stat} />
          </Grid>
        ))}
      </Grid>

      {/* Основной контент */}
      <Grid container spacing={3}>
        {/* Курсы */}
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h5">
                Активные курсы
              </Typography>
              <Button
                variant="contained"
                startIcon={<QuizIcon />}
                onClick={handleStartTest}
              >
                Создать тест
              </Button>
            </Box>

            <Stack spacing={2}>
              {mockCourseModules.map((course) => (
                <Card key={course.id}>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                      <Box>
                        <Typography variant="h6" gutterBottom>
                          {course.title}
                        </Typography>
                        <Typography color="text.secondary" gutterBottom>
                          {course.description}
                        </Typography>
                        <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                          <Chip 
                            icon={<TimeIcon />} 
                            label={course.duration} 
                            variant="outlined" 
                            size="small" 
                          />
                          <Chip 
                            icon={<BookIcon />} 
                            label={`${course.lessons?.length || 0} уроков`} 
                            variant="outlined" 
                            size="small" 
                          />
                          <Chip 
                            label={course.level} 
                            color="primary" 
                            size="small" 
                          />
                        </Stack>
                      </Box>
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="outlined"
                          startIcon={<PreviewIcon />}
                          onClick={() => handlePreviewCourse(course)}
                        >
                          Предпросмотр
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<PlayArrowIcon />}
                          onClick={() => handleStartCourse(course)}
                        >
                          Начать
                        </Button>
                      </Stack>
                    </Box>
                    {course.progress && (
                      <>
                        <LinearProgress variant="determinate" value={course.progress.enrolled} sx={{ mb: 1 }} />
                        <Typography variant="body2" color="text.secondary">
                          Прогресс: {course.progress.enrolled}%
                        </Typography>
                      </>
                    )}
                  </CardContent>
                </Card>
              ))}
            </Stack>
          </Paper>
        </Grid>

        {/* Прогресс агента */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              Ваш прогресс
            </Typography>

            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar sx={{ mr: 2 }}>{mockAgent.name[0]}</Avatar>
                <Box>
                  <Typography variant="subtitle1">{mockAgent.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {mockAgent.email}
                  </Typography>
                </Box>
              </Box>

              <Typography variant="body2" color="text.secondary" gutterBottom>
                Текущий курс: {mockAgent.currentCourse}
              </Typography>
              <LinearProgress variant="determinate" value={mockAgent.progress} />
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Прогресс: {mockAgent.progress}%
              </Typography>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Typography variant="h6" gutterBottom>
              Достижения
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', overflowX: 'auto', py: 2 }}>
              {mockCourseModules.map((course, index) => (
                <React.Fragment key={course.id}>
                  <Card sx={{ minWidth: 200, mr: 2 }}>
                    <CardContent>
                      <Typography variant="subtitle2">{course.title}</Typography>
                      <Rating value={course.progress ? 5 : 0} readOnly size="small" />
                    </CardContent>
                  </Card>
                  {index < mockCourseModules.length - 1 && (
                    <ArrowForwardIcon color="action" sx={{ mx: 1 }} />
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Диалог предпросмотра курса */}
      <Dialog
        open={previewOpen}
        onClose={handleClosePreview}
        maxWidth="md"
        fullWidth
      >
        {selectedCourse && (
          <>
            <DialogTitle>
              <Typography variant="h5">{selectedCourse.title}</Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {selectedCourse.description}
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Информация о курсе
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="text.secondary">
                      Длительность
                    </Typography>
                    <Typography variant="body1">
                      {selectedCourse.duration}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="text.secondary">
                      Уровень
                    </Typography>
                    <Typography variant="body1">
                      {selectedCourse.level}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="text.secondary">
                      Количество уроков
                    </Typography>
                    <Typography variant="body1">
                      {selectedCourse.lessons?.length || 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Typography variant="h6" gutterBottom>
                Программа курса
              </Typography>
              <List>
                {selectedCourse.lessons?.map((lesson: any, index: number) => (
                  <ListItem key={lesson.id}>
                    <ListItemIcon>
                      <Typography variant="body2" color="text.secondary">
                        {index + 1}.
                      </Typography>
                    </ListItemIcon>
                    <ListItemText
                      primary={lesson.title}
                      secondary={`Примерное время: ${lesson.estimatedTime || 45} минут`}
                    />
                  </ListItem>
                ))}
              </List>

              {selectedCourse.materials?.length > 0 && (
                <>
                  <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                    Материалы курса
                  </Typography>
                  <List>
                    {selectedCourse.materials.map((materialId: string) => {
                      const material = mockMaterials.find(m => m.id === materialId);
                      return material ? (
                        <ListItem key={material.id}>
                          <ListItemIcon>
                            <BookIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={material.title}
                            secondary={`Тип: ${material.type}`}
                          />
                        </ListItem>
                      ) : null;
                    })}
                  </List>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePreview}>
                Закрыть
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleClosePreview();
                  handleStartCourse(selectedCourse);
                }}
                startIcon={<PlayArrowIcon />}
              >
                Начать курс
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default TrainingDashboard; 