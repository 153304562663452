import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  LinearProgress,
  Alert,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Stack,
  Zoom,
  Fade,
  CircularProgress,
  Tooltip
} from '@mui/material';
import {
  Timer as TimerIcon,
  Help as HelpIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  EmojiEvents as TrophyIcon,
  Speed as SpeedIcon,
  Psychology as PsychologyIcon
} from '@mui/icons-material';
import { useCourses } from '../../hooks/useCourses';
import { Question } from '../../types/training';

const TestPage: React.FC = () => {
  const { courseId, lessonId } = useParams<{ courseId: string; lessonId: string }>();
  const navigate = useNavigate();
  const { currentCourse, loading, error, startCourse } = useCourses();
  
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [testCompleted, setTestCompleted] = useState(false);
  const [testResults, setTestResults] = useState<{
    score: number;
    maxScore: number;
    correctAnswers: number;
    totalQuestions: number;
  } | null>(null);
  const [showHint, setShowHint] = useState<string | null>(null);
  const [showHintDialog, setShowHintDialog] = useState(false);
  const [currentHint, setCurrentHint] = useState<string | null>(null);

  useEffect(() => {
    if (courseId) {
      startCourse(courseId);
    }
  }, [courseId, startCourse]);

  const currentLesson = currentCourse?.lessons.find(l => l.id === lessonId);
  const questions = currentLesson?.test.questions || [];

  useEffect(() => {
    if (currentLesson) {
      setTimeLeft(currentLesson.test.timeLimit);
    }
  }, [currentLesson]);

  useEffect(() => {
    if (timeLeft > 0 && !testCompleted) {
      const timer = setInterval(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeLeft === 0 && !testCompleted) {
      handleSubmitTest();
    }
  }, [timeLeft, testCompleted]);

  const handleAnswerChange = (questionId: string, answer: string) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: answer
    }));
  };

  const calculateResults = () => {
    let score = 0;
    let correctAnswers = 0;
    const maxScore = questions.reduce((sum, q) => sum + q.points, 0);

    questions.forEach(question => {
      if (answers[question.id] === question.correctAnswer) {
        score += question.points;
        correctAnswers++;
      }
    });

    return {
      score,
      maxScore,
      correctAnswers,
      totalQuestions: questions.length
    };
  };

  const handleSubmitTest = () => {
    const results = calculateResults();
    setTestResults(results);
    setTestCompleted(true);
  };

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleShowHint = (questionId: string) => {
    const question = questions.find(q => q.id === questionId);
    if (question?.hint) {
      setCurrentHint(question.hint);
      setShowHintDialog(true);
    }
  };

  const renderQuestion = (question: Question) => {
    const isCorrect = answers[question.id] === question.correctAnswer;
    const hasAnswered = testCompleted && answers[question.id];

    return (
      <Fade in={true} timeout={500}>
        <Card key={question.id} sx={{ mb: 2, position: 'relative' }}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">
                {currentQuestion + 1}. {question.text}
              </Typography>
              {!testCompleted && question.hint && (
                <Tooltip title="Показать подсказку">
                  <Button
                    size="small"
                    startIcon={<HelpIcon />}
                    onClick={() => handleShowHint(question.id)}
                  >
                    Подсказка
                  </Button>
                </Tooltip>
              )}
            </Box>

            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <RadioGroup
                value={answers[question.id] || ''}
                onChange={(e) => handleAnswerChange(question.id, e.target.value)}
              >
                {question.options.map((option, index) => (
                  <Zoom in={true} style={{ transitionDelay: `${index * 100}ms` }}>
                    <FormControlLabel
                      key={index}
                      value={option}
                      control={
                        <Radio 
                          color={hasAnswered ? (option === question.correctAnswer ? 'success' : 'error') : 'primary'}
                        />
                      }
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            sx={{
                              color: hasAnswered
                                ? option === question.correctAnswer
                                  ? 'success.main'
                                  : option === answers[question.id]
                                  ? 'error.main'
                                  : 'text.primary'
                                : 'text.primary'
                            }}
                          >
                            {option}
                          </Typography>
                          {hasAnswered && option === question.correctAnswer && (
                            <CheckCircleIcon color="success" sx={{ ml: 1 }} />
                          )}
                        </Box>
                      }
                      disabled={testCompleted}
                      sx={{
                        p: 1,
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: 'action.hover'
                        }
                      }}
                    />
                  </Zoom>
                ))}
              </RadioGroup>
            </FormControl>

            {testCompleted && (
              <Box sx={{ mt: 2 }}>
                <Alert
                  severity={isCorrect ? 'success' : 'error'}
                  icon={isCorrect ? <CheckCircleIcon /> : <WarningIcon />}
                  sx={{ mb: 1 }}
                >
                  {isCorrect ? 'Правильно!' : 'Неправильно. Правильный ответ: ' + question.correctAnswer}
                </Alert>
                {question.explanation && (
                  <Alert severity="info" icon={<PsychologyIcon />}>
                    {question.explanation}
                  </Alert>
                )}
              </Box>
            )}
          </CardContent>
        </Card>
      </Fade>
    );
  };

  if (loading) {
    return (
      <Box sx={{ p: 3 }}>
        <LinearProgress />
      </Box>
    );
  }

  if (error || !currentLesson) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">
          {error || 'Урок не найден'}
        </Alert>
      </Box>
    );
  }

  if (testCompleted && testResults) {
    return (
      <Box sx={{ p: 3 }}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Результаты теста
          </Typography>
          
          <Stack spacing={2} sx={{ mb: 3 }}>
            <Alert 
              severity={testResults.score >= currentCourse!.requiredScore ? "success" : "error"}
              sx={{ mb: 2 }}
            >
              {testResults.score >= currentCourse!.requiredScore 
                ? "Поздравляем! Вы успешно прошли тест!" 
                : "К сожалению, тест не пройден. Необходимо набрать больше баллов."}
            </Alert>

            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
              <Chip
                icon={<HelpIcon />}
                label={`Правильных ответов: ${testResults.correctAnswers} из ${testResults.totalQuestions}`}
                color="primary"
              />
              <Chip
                label={`Набрано баллов: ${testResults.score} из ${testResults.maxScore}`}
                color="secondary"
              />
              <Chip
                label={`Процент выполнения: ${Math.round((testResults.score / testResults.maxScore) * 100)}%`}
                color="info"
              />
            </Box>
          </Stack>

          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => navigate(`/agent-training/course/${courseId}`)}
            >
              Вернуться к курсу
            </Button>
            {testResults.score < currentCourse!.requiredScore && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.location.reload()}
              >
                Пройти тест заново
              </Button>
            )}
          </Box>
        </Paper>

        <Paper sx={{ p: 3, mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            Разбор ответов
          </Typography>
          {questions.map(question => renderQuestion(question))}
        </Paper>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h5">
            Тест: {currentLesson.title}
          </Typography>
          <Chip
            icon={<TimerIcon />}
            label={`Осталось времени: ${formatTime(timeLeft)}`}
            color={timeLeft < 300 ? 'error' : 'default'}
            variant="outlined"
          />
        </Box>

        <LinearProgress 
          variant="determinate" 
          value={(currentQuestion + 1) / questions.length * 100} 
          sx={{ mb: 2 }}
        />

        <Typography variant="body2" color="text.secondary" gutterBottom>
          Вопрос {currentQuestion + 1} из {questions.length}
        </Typography>
      </Paper>

      {renderQuestion(questions[currentQuestion])}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Button
          variant="outlined"
          disabled={currentQuestion === 0}
          onClick={() => setCurrentQuestion(prev => prev - 1)}
        >
          Предыдущий вопрос
        </Button>
        
        {currentQuestion === questions.length - 1 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitTest}
          >
            Завершить тест
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => setCurrentQuestion(prev => prev + 1)}
          >
            Следующий вопрос
          </Button>
        )}
      </Box>

      <Dialog open={showExitDialog} onClose={() => setShowExitDialog(false)}>
        <DialogTitle>Прервать тест?</DialogTitle>
        <DialogContent>
          <Typography>
            Если вы прервете тест, все ответы будут потеряны. Вы уверены?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowExitDialog(false)}>
            Отмена
          </Button>
          <Button 
            onClick={() => navigate(`/agent-training/course/${courseId}`)}
            color="error"
          >
            Прервать тест
          </Button>
        </DialogActions>
      </Dialog>

      {/* Hint Dialog */}
      <Dialog
        open={showHintDialog}
        onClose={() => setShowHintDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <HelpIcon color="primary" />
            <Typography>Подсказка</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>{currentHint}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowHintDialog(false)}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TestPage;