import React from 'react';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Stack
} from '@mui/material';
import { TestData } from '../TestWizard';
import { SelectChangeEvent } from '@mui/material/Select';

interface InputFormProps {
  testData: TestData;
  setTestData: (data: TestData) => void;
  onNext: () => void;
}

const InputForm: React.FC<InputFormProps> = ({ testData, setTestData, onNext }) => {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onNext();
  };

  const handleTextFieldChange = (field: 'propertyName' | 'description') => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTestData({
      ...testData,
      [field]: event.target.value
    });
  };

  const handleDifficultyChange = (event: SelectChangeEvent<string>) => {
    setTestData({
      ...testData,
      difficulty: event.target.value as 'easy' | 'medium' | 'hard'
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          label="Название объекта недвижимости"
          value={testData.propertyName}
          onChange={handleTextFieldChange('propertyName')}
          required
        />

        <TextField
          fullWidth
          label="Описание объекта"
          multiline
          rows={4}
          value={testData.description}
          onChange={handleTextFieldChange('description')}
          required
        />

        <FormControl fullWidth>
          <InputLabel>Сложность теста</InputLabel>
          <Select
            value={testData.difficulty}
            onChange={handleDifficultyChange}
            label="Сложность теста"
            required
          >
            <MenuItem value="easy">Легкий</MenuItem>
            <MenuItem value="medium">Средний</MenuItem>
            <MenuItem value="hard">Сложный</MenuItem>
          </Select>
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            type="submit"
            size="large"
          >
            Далее
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default InputForm; 