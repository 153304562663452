import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon, LatLngExpression } from 'leaflet';
import { Box, Typography, Stack, Chip } from '@mui/material';
import 'leaflet/dist/leaflet.css';

// Импортируем изображения маркеров как статические ресурсы
const markerIconUrl = 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png';
const markerShadowUrl = 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png';

const defaultIcon = new Icon({
  iconUrl: markerIconUrl,
  shadowUrl: markerShadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41]
});

interface PropertyMapProps {
  properties: any[];
  favorites: number[];
  onMarkerClick: (id: number) => void;
  center?: LatLngExpression;
  zoom?: number;
}

const PropertyMap = ({ 
  properties, 
  favorites, 
  onMarkerClick,
  center = [7.8804, 98.3923] as LatLngExpression,
  zoom = 11 
}: PropertyMapProps) => {
  return (
    <Box sx={{ height: '100%', minHeight: 600 }}>
      <MapContainer 
        center={center}
        zoom={zoom}
        style={{ height: '100%', width: '100%' }}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {properties.map((property) => {
          const position: LatLngExpression = [
            property.attributes.location_lat,
            property.attributes.location_long
          ];
          
          return (
            <Marker
              key={property.id}
              position={position}
              icon={defaultIcon}
              eventHandlers={{
                click: () => onMarkerClick(property.id)
              }}
            >
              <Popup>
                <Box sx={{ minWidth: 200 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {property.attributes.name}
                  </Typography>
                  <Stack direction="row" spacing={1} mb={1}>
                    <Chip 
                      label={property.attributes.type} 
                      size="small" 
                      color="primary" 
                      variant="outlined"
                    />
                    {favorites.includes(property.id) && (
                      <Chip 
                        label="Favorite" 
                        size="small" 
                        color="secondary" 
                        variant="outlined"
                      />
                    )}
                  </Stack>
                  {property.attributes.price && (
                    <Typography variant="body2" color="text.secondary">
                      Price: {new Intl.NumberFormat('ru-RU').format(Number(property.attributes.price))} ₽
                    </Typography>
                  )}
                </Box>
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </Box>
  );
};

export default PropertyMap;