import { useState, useCallback } from 'react';
import { CourseModule, Lesson, TestResult } from '../types/training';
import { mockCourseModules } from '../data/mockTrainingData';

interface UseCourses {
  courses: CourseModule[];
  currentCourse: CourseModule | null;
  loading: boolean;
  error: string | null;
  startCourse: (courseId: string) => Promise<void>;
  completeCourse: (courseId: string, testResults: TestResult) => Promise<void>;
  getCourseProgress: (courseId: string) => number;
  checkPrerequisites: (courseId: string) => boolean;
  getCurrentLesson: (courseId: string) => Lesson | null;
}

export const useCourses = (): UseCourses => {
  const [courses, setCourses] = useState<CourseModule[]>(mockCourseModules);
  const [currentCourse, setCurrentCourse] = useState<CourseModule | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const startCourse = useCallback(async (courseId: string) => {
    setLoading(true);
    try {
      const course = courses.find(c => c.id === courseId);
      if (!course) {
        throw new Error('Курс не найден');
      }
      
      // Проверка предварительных требований
      if (course.prerequisites?.length > 0) {
        const hasPrerequisites = course.prerequisites.every(prereqId => {
          const prereqCourse = courses.find(c => c.id === prereqId);
          // Проверяем наличие прогресса и завершенности курса
          return (prereqCourse?.progress?.completed ?? 0) > 0;
        });
        
        if (!hasPrerequisites) {
          throw new Error('Необходимо пройти предварительные курсы');
        }
      }

      setCurrentCourse(course);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Произошла ошибка');
    } finally {
      setLoading(false);
    }
  }, [courses]);

  const completeCourse = useCallback(async (courseId: string, testResults: TestResult) => {
    setLoading(true);
    try {
      const courseIndex = courses.findIndex(c => c.id === courseId);
      if (courseIndex === -1) {
        throw new Error('Курс не найден');
      }

      // Проверка проходного балла
      const course = courses[courseIndex];
      if (testResults.score < course.requiredScore) {
        throw new Error('Не достигнут проходной балл');
      }

      // Обновление прогресса курса
      const updatedCourses = [...courses];
      const currentProgress = course.progress || { enrolled: 0, completed: 0, averageScore: 0 };
      
      updatedCourses[courseIndex] = {
        ...course,
        progress: {
          ...currentProgress,
          completed: currentProgress.completed + 1,
          averageScore: Math.round(
            ((currentProgress.averageScore * currentProgress.completed) + testResults.score) /
            (currentProgress.completed + 1)
          )
        }
      };

      setCourses(updatedCourses);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Произошла ошибка');
    } finally {
      setLoading(false);
    }
  }, [courses]);

  const getCourseProgress = useCallback((courseId: string): number => {
    const course = courses.find(c => c.id === courseId);
    if (!course?.progress) return 0;
    return Math.round((course.progress.completed / course.progress.enrolled) * 100);
  }, [courses]);

  const checkPrerequisites = useCallback((courseId: string): boolean => {
    const course = courses.find(c => c.id === courseId);
    if (!course || !course.prerequisites?.length) return true;
    return course.prerequisites.every(prereqId => {
      const prereqCourse = courses.find(c => c.id === prereqId);
      // Используем оператор нулевого слияния для безопасной проверки
      return (prereqCourse?.progress?.completed ?? 0) > 0;
    });
  }, [courses]);

  const getCurrentLesson = useCallback((courseId: string): Lesson | null => {
    const course = courses.find(c => c.id === courseId);
    if (!course) return null;
    
    // Находим первый непройденный урок
    return course.lessons[0] || null;
  }, [courses]);

  return {
    courses,
    currentCourse,
    loading,
    error,
    startCourse,
    completeCourse,
    getCourseProgress,
    checkPrerequisites,
    getCurrentLesson
  };
}; 