import React, { useState, useMemo } from 'react';
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  Stack,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  TablePagination,
  TableSortLabel,
  Tooltip,
  FormControlLabel,
  Switch
} from '@mui/material';
import {
  GridView as GridViewIcon,
  List as ListIcon,
  ViewQuilt as FloorPlansIcon,
  ViewInAr as WalkthroughIcon,
  Download as DownloadIcon,
  Print as PrintIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import { useUnits } from '../../hooks/useUnits';
import type { UnitFilters, Unit, PriceRange, AreaRange } from '../../types/unit';
import { PRICE_RANGES, AREA_RANGES } from '../../types/unit';
import { styled } from '@mui/material/styles';
import FloorPlans from './components/FloorPlans';
import { BuildingSelector } from './components/BuildingSelector';
import { LayoutViewer } from './components/LayoutViewer';
import { BuildingState } from '../../types/building';
import { useBuilding } from '../../hooks/useBuilding';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`units-tabpanel-${index}`}
      aria-labelledby={`units-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

type UnitStatusType = 'available' | 'reserved' | 'sold' | 'unavailable';

const UnitStatus = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'status'
})<{ status: UnitStatusType }>(({ theme, status }) => {
  const colors: Record<UnitStatusType, string> = {
    available: theme.palette.success.main,
    reserved: theme.palette.warning.main,
    sold: theme.palette.error.main,
    unavailable: theme.palette.grey[500]
  };

  return {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: colors[status],
    marginRight: theme.spacing(1)
  };
});

const getStatusStyles = (status: UnitStatusType) => {
  const styles: Record<UnitStatusType, any> = {
    available: {
      backgroundColor: 'rgba(84, 214, 44, 0.08)',
      borderColor: 'success.main',
      '&:hover': {
        backgroundColor: 'rgba(84, 214, 44, 0.16)',
        boxShadow: '0 0 0 1px #54D62C'
      }
    },
    reserved: {
      backgroundColor: 'rgba(255, 171, 0, 0.08)',
      borderColor: 'warning.main',
      '&:hover': {
        backgroundColor: 'rgba(255, 171, 0, 0.16)',
        boxShadow: '0 0 0 1px #FFAB00'
      }
    },
    sold: {
      backgroundColor: 'rgba(255, 86, 48, 0.08)',
      borderColor: 'error.main',
      '&:hover': {
        backgroundColor: 'rgba(255, 86, 48, 0.16)',
        boxShadow: '0 0 0 1px #FF5630'
      }
    },
    unavailable: {
      backgroundColor: 'rgba(145, 158, 171, 0.08)',
      borderColor: 'grey.500',
      '&:hover': {
        backgroundColor: 'rgba(145, 158, 171, 0.16)',
        boxShadow: '0 0 0 1px #919EAB'
      }
    }
  };
  return styles[status];
};

interface HeadCell {
  id: keyof Unit;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'number', label: 'Unit №', numeric: false },
  { id: 'floor', label: 'Floor', numeric: true },
  { id: 'status', label: 'Status', numeric: false },
  { id: 'area', label: 'Area', numeric: true },
  { id: 'bedrooms', label: 'Bedrooms', numeric: true },
  { id: 'layout', label: 'Layout', numeric: false },
  { id: 'windowView', label: 'Window View', numeric: false },
  { id: 'price', label: 'Price', numeric: true },
  { id: 'pricePerSqm', label: 'Price/m²', numeric: true },
  { id: 'completion', label: 'Completion', numeric: false }
];

const UnitsList = ({ units }: { units: Unit[] }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState<keyof Unit>('number');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const handleSort = (property: keyof Unit) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedUnits = [...units].sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return order === 'asc' 
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return order === 'asc'
        ? aValue - bValue
        : bValue - aValue;
    }

    return 0;
  });

  const paginatedUnits = sortedUnits.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={() => handleSort(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUnits.map((unit) => (
              <TableRow
                key={unit.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  ...getStatusStyles(unit.status),
                  '&:hover': {
                    cursor: 'pointer',
                    ...getStatusStyles(unit.status)['&:hover']
                  }
                }}
              >
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <UnitStatus status={unit.status} />
                    №{unit.number}
                  </Box>
                </TableCell>
                <TableCell>{unit.floor}</TableCell>
                <TableCell>
                  <Chip
                    label={unit.status}
                    size="small"
                    color={
                      unit.status === 'available' ? 'success' :
                      unit.status === 'reserved' ? 'warning' :
                      unit.status === 'sold' ? 'error' : 'default'
                    }
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>{unit.area} m²</TableCell>
                <TableCell>{unit.bedrooms}</TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {unit.layout.type.replace('-', ' ')}
                  </Typography>
                </TableCell>
                <TableCell>{unit.windowView}</TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    sx={{
                      color: unit.status === 'available' ? 'success.main' :
                             unit.status === 'reserved' ? 'warning.main' :
                             unit.status === 'sold' ? 'error.main' : 'text.secondary',
                      fontWeight: 600
                    }}
                  >
                    {new Intl.NumberFormat('th-TH', {
                      style: 'currency',
                      currency: 'THB',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }).format(unit.price)}
                  </Typography>
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat('th-TH', {
                    style: 'currency',
                    currency: 'THB',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }).format(unit.pricePerSqm)}
                </TableCell>
                <TableCell>{unit.completion}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={units.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
      />
    </>
  );
};

interface ViewSettings {
  showPrices: boolean;
  showArea: boolean;
  showStatus: boolean;
  showLabels: boolean;
}

export const UnitsGrid: React.FC = () => {
  const [view, setView] = useState<'building' | 'floor'>('building');
  const [filters, setFilters] = useState<UnitFilters>({
    building: '',
    availability: 'all',
    bedrooms: 'all',
    priceRange: 'all',
    floorArea: 'all',
    windowView: 'all',
    layout: 'all',
    furnished: 'all',
    pricePerSqm: 'all',
    completion: 'all',
    floor: 'all'
  });

  const [viewSettings, setViewSettings] = useState<ViewSettings>({
    showPrices: true,
    showArea: true,
    showStatus: true,
    showLabels: true,
  });

  const [buildingState, setBuildingState] = useState<BuildingState>({
    selectedSection: null,
    selectedFloor: null,
    selectedUnit: null,
    selectedLayout: null
  });

  const handleSectionSelect = (sectionId: string) => {
    setBuildingState(prev => ({
      ...prev,
      selectedSection: sectionId,
      selectedFloor: null
    }));
    setView('floor');
  };

  const handleFloorSelect = (floor: number) => {
    setBuildingState(prev => ({
      ...prev,
      selectedFloor: floor
    }));
  };

  const handleBackToBuilding = () => {
    setView('building');
    setBuildingState(prev => ({
      ...prev,
      selectedFloor: null
    }));
  };

  const { units, loading, error } = useUnits(filters);

  const handleUnitClick = (unit: Unit) => {
    console.log('Clicked unit:', unit);
    // Здесь можно добавить открытие модального окна с деталями юнита
  };

  const handleExportData = () => {
    // Логика экспорта данных
  };

  const handlePrintView = () => {
    // Логика печати страницы
  };

  const { building, sections } = useBuilding(filters.building);
  const [currentFloorPlan, setCurrentFloorPlan] = useState<string>('');

  const selectedUnit = useMemo(() => 
    units.find(unit => unit.id === buildingState.selectedUnit),
    [units, buildingState.selectedUnit]
  );

  const selectedLayout = useMemo(() => 
    selectedUnit?.layout,
    [selectedUnit]
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4, color: 'error.main' }}>
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      {view === 'building' ? (
        <BuildingSelector
          sections={sections}
          buildingState={buildingState}
          onSectionSelect={handleSectionSelect}
        />
      ) : (
        <Box>
          <Button
            onClick={handleBackToBuilding}
            startIcon={<ArrowBackIcon />}
            sx={{ mb: 2 }}
          >
            Вернуться к выбору секции
          </Button>

          <FloorPlans
            units={units}
            onUnitClick={handleUnitClick}
            viewSettings={viewSettings}
            selectedFloor={buildingState.selectedFloor || 1}
            onFloorChange={handleFloorSelect}
            sectionId={buildingState.selectedSection}
            floorPlanUrl={`/img/floor-plans/section-${buildingState.selectedSection}-floor-${buildingState.selectedFloor || 1}.webp`}
          />
        </Box>
      )}

      {buildingState.selectedLayout && selectedLayout && selectedUnit && (
        <LayoutViewer
          layout={selectedLayout}
          unit={selectedUnit}
        />
      )}
    </Container>
  );
};

export default UnitsGrid; 