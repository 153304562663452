import { CompanyInfo } from '../types/company';

export const companyData: CompanyInfo = {
  name: 'VIP Thailand Group',
  logo: '/assets/images/logo.png',
  coverImage: '/assets/images/cover.jpg',
  description: 'VIP Thailand Group was established in 2015 with the goal of providing high-quality properties to customers in Thailand and from around the world. Since then, we have completed 8 projects including boutique apartments, villa resorts, and stunning hotels.',
  foundedYear: 2015,
  website: 'www.vipthailandgroup.com',
  contacts: {
    email: 'info@vipthailandgroup.com',
    phone: '+66 123 456 789',
    address: 'Phuket, Thailand'
  },
  socialMedia: {
    facebook: 'https://facebook.com/vipthailandgroup',
    instagram: 'https://instagram.com/vipthailandgroup',
    linkedin: 'https://linkedin.com/company/vipthailandgroup'
  },
  stats: {
    totalProjects: 8,
    completedProjects: 6,
    ongoingProjects: 2,
    totalUnits: 840,
    soldUnits: 600,
    reservedUnits: 120,
    deliveryRate: 80
  },
  licenses: [
    {
      id: 'license-001',
      type: 'building',
      name: 'Building Permit',
      issuer: 'Ministry of Interior of Thailand',
      issuedDate: '2015-09-01',
      status: 'active'
    },
    {
      id: 'license-002',
      type: 'environmental',
      name: 'Leadership in Energy and Environmental Design',
      issuer: 'Thai Green Building Institute (TGBI)',
      issuedDate: '2018-07-01',
      status: 'active'
    },
    {
      id: 'license-003',
      type: 'safety',
      name: 'TISI Certification',
      issuer: 'Thai Industrial Standards Institute (TISI)',
      issuedDate: '2020-12-01',
      status: 'active'
    }
  ],
  brokerageAgreements: [
    {
      id: 'agreement-001',
      partner: 'Global Real Estate Agency',
      startDate: '2023-09-01',
      endDate: '2024-09-01',
      status: 'active',
      commission: 5,
      territory: ['Europe', 'Middle East']
    },
    {
      id: 'agreement-002',
      partner: 'Asian Property Network',
      startDate: '2023-10-01',
      endDate: '2024-10-01',
      status: 'active',
      commission: 4.5,
      territory: ['Asia', 'Australia']
    }
  ],
  projects: [
    {
      id: 'vip-space-odyssey',
      name: 'VIP Space Odyssey',
      location: {
        lat: 7.7749,
        lng: 98.3253
      }
    },
    // Добавьте остальные проекты
  ]
}; 