import React from 'react';
import { Card, CardContent, Box, Skeleton } from '@mui/material';

const PropertyCardSkeleton = () => {
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* Image placeholder with pulse animation */}
      <Box
        sx={{
          position: 'relative',
          paddingTop: '56.25%', // 16:9 aspect ratio
          backgroundColor: 'grey.100',
          overflow: 'hidden'
        }}
      >
        <Skeleton
          variant="rectangular"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            transform: 'none',
            animation: 'pulse 1.5s ease-in-out 0.5s infinite'
          }}
        />
        
        {/* Price chip placeholder */}
        <Skeleton 
          variant="rectangular"
          width={100}
          height={32}
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            borderRadius: 1
          }}
        />
        
        {/* Favorite button placeholder */}
        <Skeleton 
          variant="circular"
          width={40}
          height={40}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8
          }}
        />
      </Box>

      <CardContent sx={{ flexGrow: 1 }}>
        {/* Title */}
        <Skeleton variant="text" width="80%" height={32} sx={{ mb: 1 }} />
        
        {/* Location */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Skeleton variant="circular" width={20} height={20} sx={{ mr: 1 }} />
          <Skeleton variant="text" width="60%" />
        </Box>

        {/* Details */}
        <Box sx={{ mb: 2 }}>
          {[1, 2, 3].map((i) => (
            <Box key={i} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Skeleton variant="circular" width={20} height={20} sx={{ mr: 1 }} />
              <Skeleton variant="text" width="40%" />
            </Box>
          ))}
        </Box>

        {/* Tag */}
        <Skeleton 
          variant="rectangular" 
          width={80} 
          height={24} 
          sx={{ borderRadius: 1 }} 
        />
      </CardContent>
    </Card>
  );
};

export default PropertyCardSkeleton; 