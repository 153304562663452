import { createTheme } from '@mui/material/styles';

export const colors = {
    primary: {
        25: '#F5FAFF',
        50: '#EFF8FF',
        100: '#D1E9FF',
        200: '#B2DDFF',
        300: '#84CAFF',
        400: '#53B1FD',
        500: '#2E90FA',
        600: '#1570EF',
        700: '#175CD3',
        800: '#1849A9',
        900: '#194185',
    },
    gray: {
        25: '#FCFCFD',
        50: '#F9FAFB',
        100: '#F2F4F7',
        200: '#EAECF0',
        300: '#D0D5DD',
        400: '#98A2B3',
        500: '#667085',
        600: '#475467',
        700: '#344054',
        800: '#1D2939',
        900: '#101828',
    },
    success: {
        main: '#12B76A',
        light: '#32D583',
        dark: '#027A48',
    },
    error: {
        main: '#F04438',
        light: '#FDA29B',
        dark: '#B42318',
    },
};

const violetPalette = {
    50: '#F4F3FF',
    100: '#EBE9FE',
    200: '#D9D6FE',
    300: '#B7B2FE',
    400: '#9B95FD',
    500: '#7F56D9', // Основной
    600: '#6941C6', // Темный
    700: '#5E3AB7',
    800: '#4E2FA9',
    900: '#3E248F',
};

export const theme = createTheme({
    palette: {
        primary: {
            main: violetPalette[500],
            light: violetPalette[300],
            dark: violetPalette[700],
        },
        grey: colors.gray,
        success: {
            main: colors.success.main,
            light: colors.success.light,
            dark: colors.success.dark,
        },
        error: {
            main: colors.error.main,
            light: colors.error.light,
            dark: colors.error.dark,
        },
        background: {
            default: colors.gray[100],
            paper: '#FFFFFF',
        },
    },
    typography: {
        fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, sans-serif',
        h5: {
            fontSize: '24px',
            fontWeight: 500,
            color: colors.gray[900],
            lineHeight: '32px',
        },
        h6: {
            fontSize: '14px',
            fontWeight: 500,
            color: colors.gray[900],
            lineHeight: '20px',
            textTransform: 'none',
        },
        subtitle1: {
            fontSize: '14px',
            color: colors.gray[600],
            lineHeight: '20px',
        },
        subtitle2: {
            fontSize: '14px',
            fontWeight: 500,
            color: colors.gray[600],
            lineHeight: '20px',
            textTransform: 'capitalize',
        },
        body2: {
            fontSize: '14px',
            lineHeight: '20px',
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                    borderRadius: '12px',
                    border: `1px solid ${colors.gray[200]}`,
                    background: '#FFFFFF'
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                    height: '24px',
                    fontSize: '12px',
                    fontWeight: 500,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    backdropFilter: 'blur(4px)',
                    '& .MuiChip-label': {
                        padding: '0 12px',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: '8px',
                    fontWeight: 500,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: colors.gray[500],
                    '&:hover': {
                        backgroundColor: colors.gray[50],
                    },
                },
            },
        },
    },
    shape: {
        borderRadius: 8,
    },
});