import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';
import StatCard from '../../components/Dashboard/StatCard';
import ProjectsGrid from '../../components/Projects/ProjectsGrid';

const Dashboard = () => {
  const stats = {
    totalViewings: {
      value: 2048,
      change: -10,
      lastMonth: true
    },
    totalReservations: {
      value: 925,
      change: 15,
      lastMonth: true
    },
    totalSalesUnits: {
      value: 419,
      change: 5,
      lastMonth: true
    },
    totalSalesPercentage: {
      value: '55%',
      change: 8,
      lastMonth: true
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Box>
          <Typography variant="h5">Business analytics</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Track your sales efficiency
          </Typography>
        </Box>
        <IconButton 
          sx={{ 
            border: '1px solid', 
            borderColor: 'grey.200',
            borderRadius: 1,
            px: 2
          }}
        >
          <FileDownloadOutlined sx={{ mr: 1 }} />
          <Typography variant="button">Export</Typography>
        </IconButton>
      </Box>
      
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 3 }}>
        {Object.entries(stats).map(([key, data]) => (
          <StatCard key={key} {...data} title={key} />
        ))}
      </Box>

      <ProjectsGrid />
    </Box>
  );
};

export default Dashboard;