import { Material } from '../types/training';

export const mockMaterials: Material[] = [
  {
    id: 'mat-1',
    type: 'video',
    title: 'Введение в рынок недвижимости',
    url: '/materials/videos/market-intro.mp4'
  },
  {
    id: 'mat-2',
    type: 'pdf',
    title: 'Справочник по работе с клиентами',
    url: '/materials/docs/client-handbook.pdf'
  },
  {
    id: 'mat-3',
    type: 'presentation',
    title: 'Маркетинговые стратегии',
    url: '/materials/presentations/marketing.pptx'
  },
  {
    id: 'mat-4',
    type: 'video',
    title: 'Техники продаж премиальной недвижимости',
    url: '/materials/videos/premium-sales.mp4'
  },
  {
    id: 'mat-5',
    type: 'pdf',
    title: 'Юридическая документация',
    url: '/materials/docs/legal-docs.pdf'
  }
]; 