import React from 'react';
import {
  Box,
  Card,
  Typography,
  Stack,
  Button,
  IconButton,
  Chip,
  Link
} from '@mui/material';
import {
  Add as AddIcon,
  FileDownload as FileDownloadIcon,
  MoreVert as MoreVertIcon,
  Public as PublicIcon
} from '@mui/icons-material';
import { companyData } from '../../../data/company';

const statusColors = {
  active: { color: 'success', label: 'Active' },
  expired: { color: 'error', label: 'Expired' },
  pending: { color: 'warning', label: 'Pending' }
} as const;

const BrokerageAgreements = () => {
  return (
    <Card sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h6">
          Brokerage Agreements ({companyData.brokerageAgreements.length})
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          size="small"
        >
          Add Agreement
        </Button>
      </Box>

      <Stack spacing={2}>
        {companyData.brokerageAgreements.map((agreement) => (
          <Box
            key={agreement.id}
            sx={{
              p: 2,
              border: '1px solid',
              borderColor: 'grey.200',
              borderRadius: 1,
              '&:hover': {
                bgcolor: 'grey.50'
              }
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Stack spacing={1}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {agreement.partner}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <PublicIcon sx={{ fontSize: 16, color: 'grey.500' }} />
                  <Typography variant="body2" color="text.secondary">
                    {agreement.territory.join(', ')}
                  </Typography>
                </Stack>
              </Stack>
              <IconButton size="small">
                <MoreVertIcon />
              </IconButton>
            </Box>

            <Stack direction="row" spacing={2} alignItems="center">
              <Chip
                label={statusColors[agreement.status].label}
                color={statusColors[agreement.status].color}
                size="small"
              />
              <Typography variant="caption" color="text.secondary">
                {agreement.commission}% commission
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {agreement.startDate} - {agreement.endDate}
              </Typography>
              {agreement.documentUrl && (
                <Link
                  href={agreement.documentUrl}
                  target="_blank"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    ml: 'auto !important'
                  }}
                >
                  <FileDownloadIcon sx={{ fontSize: 16 }} />
                  <Typography variant="caption">Download</Typography>
                </Link>
              )}
            </Stack>
          </Box>
        ))}
      </Stack>
    </Card>
  );
};

export default BrokerageAgreements; 