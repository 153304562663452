import React, { useState } from 'react';
import { 
  Typography, 
  Box, 
  Button, 
  TextField, 
  Paper,
  Stepper,
  Step,
  StepLabel,
  Grid,
  IconButton,
  Divider,
  LinearProgress,
  Chip,
  Rating,
  Alert
} from '@mui/material';
import { 
  Add as AddIcon, 
  Delete as DeleteIcon,
  EmojiEvents as TrophyIcon,
  Stars as StarsIcon
} from '@mui/icons-material';
import { tableStyles } from '../../styles/tableStyles';

interface Question {
  id: string;
  text: string;
  options: string[];
  correctAnswer: string;
  points: number;
}

interface TestScore {
  totalPoints: number;
  earnedPoints: number;
  correctAnswers: number;
  totalQuestions: number;
  rating: number;
}

const TestGenerator: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [testName, setTestName] = useState('');
  const [description, setDescription] = useState('');
  const [questions, setQuestions] = useState<Question[]>([]);
  const [testScore, setTestScore] = useState<TestScore>({
    totalPoints: 0,
    earnedPoints: 0,
    correctAnswers: 0,
    totalQuestions: 0,
    rating: 0
  });

  const steps = ['Основная информация', 'Создание вопросов', 'Настройка баллов', 'Предпросмотр'];

  const handleAddQuestion = () => {
    const newQuestion: Question = {
      id: `q${questions.length + 1}`,
      text: '',
      options: ['', '', '', ''],
      correctAnswer: '',
      points: 10
    };
    setQuestions([...questions, newQuestion]);
    updateTotalScore([...questions, newQuestion]);
  };

  const handleRemoveQuestion = (index: number) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    setQuestions(newQuestions);
    updateTotalScore(newQuestions);
  };

  const handleQuestionChange = (index: number, field: keyof Question, value: string | string[] | number) => {
    const newQuestions = [...questions];
    newQuestions[index] = { ...newQuestions[index], [field]: value };
    setQuestions(newQuestions);
    if (field === 'points') {
      updateTotalScore(newQuestions);
    }
  };

  const updateTotalScore = (currentQuestions: Question[]) => {
    const total = currentQuestions.reduce((sum, q) => sum + (q.points || 0), 0);
    setTestScore(prev => ({
      ...prev,
      totalPoints: total,
      totalQuestions: currentQuestions.length
    }));
  };

  const calculateRating = (score: number) => {
    if (score >= 90) return 5;
    if (score >= 80) return 4;
    if (score >= 70) return 3;
    if (score >= 60) return 2;
    return 1;
  };

  const renderScoreSection = () => (
    <Paper elevation={3} sx={{ p: 3, mb: 3, bgcolor: 'primary.lighter' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Общий балл теста
          </Typography>
          <Typography variant="h4">
            {testScore.totalPoints} <small>очков</small>
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1" gutterBottom>
            Количество вопросов
          </Typography>
          <Chip
            icon={<StarsIcon />}
            label={`${testScore.totalQuestions} вопросов`}
            color="primary"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1" gutterBottom>
            Сложность теста
          </Typography>
          <Rating
            value={calculateRating(testScore.totalPoints / testScore.totalQuestions)}
            readOnly
            icon={<TrophyIcon fontSize="inherit" />}
            emptyIcon={<TrophyIcon fontSize="inherit" />}
          />
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Box sx={{ 
      ...tableStyles.root,
      maxWidth: 1200,
      mx: 'auto',
      p: 3
    }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Генератор тестов
        </Typography>

        {renderScoreSection()}

        <Stepper activeStep={activeStep} sx={{ my: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <Box sx={{ mt: 4 }}>
            <TextField
              fullWidth
              label="Название теста"
              variant="outlined"
              value={testName}
              onChange={(e) => setTestName(e.target.value)}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              label="Описание"
              variant="outlined"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Box>
        )}

        {activeStep === 1 && (
          <Box sx={{ mt: 4 }}>
            {questions.map((question, index) => (
              <Paper key={question.id} sx={{ p: 3, mb: 3, bgcolor: 'grey.50' }}>
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <TextField
                      fullWidth
                      label={`Вопрос ${index + 1}`}
                      value={question.text}
                      onChange={(e) => handleQuestionChange(index, 'text', e.target.value)}
                      sx={{ mb: 2 }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => handleRemoveQuestion(index)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  {question.options.map((option, optIndex) => (
                    <Grid item xs={6} key={optIndex}>
                      <TextField
                        fullWidth
                        label={`Вариант ${optIndex + 1}`}
                        value={option}
                        onChange={(e) => {
                          const newOptions = [...question.options];
                          newOptions[optIndex] = e.target.value;
                          handleQuestionChange(index, 'options', newOptions);
                        }}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      label="Правильный ответ"
                      value={question.correctAnswer}
                      onChange={(e) => handleQuestionChange(index, 'correctAnswer', e.target.value)}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="">Выберите правильный ответ</option>
                      {question.options.map((option, optIndex) => (
                        <option key={optIndex} value={option}>
                          {option || `Вариант ${optIndex + 1}`}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Paper>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddQuestion}
              sx={{ mt: 2 }}
            >
              Добавить вопрос
            </Button>
          </Box>
        )}

        {activeStep === 2 && (
          <Box sx={{ mt: 4 }}>
            <Alert severity="info" sx={{ mb: 3 }}>
              Установите количество баллов за каждый вопрос. Сложные вопросы могут иметь больше баллов.
            </Alert>
            {questions.map((question, index) => (
              <Paper key={question.id} sx={{ p: 3, mb: 3, bgcolor: 'grey.50' }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <Typography variant="subtitle1">
                      {`${index + 1}. ${question.text}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      type="number"
                      label="Баллы"
                      value={question.points}
                      onChange={(e) => handleQuestionChange(index, 'points', parseInt(e.target.value) || 0)}
                      InputProps={{ inputProps: { min: 0, max: 100 } }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Box>
        )}

        {activeStep === 3 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              {testName}
            </Typography>
            <Typography variant="body1" paragraph>
              {description}
            </Typography>
            <Divider sx={{ my: 3 }} />
            {questions.map((question, index) => (
              <Box key={question.id} sx={{ mb: 4 }}>
                <Typography variant="subtitle1" gutterBottom>
                  {`${index + 1}. ${question.text}`} 
                  <Chip 
                    label={`${question.points} баллов`}
                    size="small"
                    color="primary"
                    sx={{ ml: 2 }}
                  />
                </Typography>
                <Box sx={{ pl: 3 }}>
                  {question.options.map((option, optIndex) => (
                    <Typography
                      key={optIndex}
                      variant="body2"
                      sx={{
                        color: option === question.correctAnswer ? 'success.main' : 'text.primary',
                        mb: 1
                      }}
                    >
                      {`${String.fromCharCode(65 + optIndex)}. ${option}`}
                    </Typography>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          <Button
            variant="outlined"
            disabled={activeStep === 0}
            onClick={() => setActiveStep((prev) => prev - 1)}
          >
            Назад
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (activeStep === steps.length - 1) {
                console.log({ 
                  testName, 
                  description, 
                  questions,
                  totalScore: testScore
                });
              } else {
                setActiveStep((prev) => prev + 1);
              }
            }}
          >
            {activeStep === steps.length - 1 ? 'Сохранить тест' : 'Далее'}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default TestGenerator; 