import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { AppProvider } from './context';
import { Navigation } from './components';
import { routes } from './routes';

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box sx={{ display: 'flex' }}>
            <Navigation />
            <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
              <Routes>
                {routes.map((route) => 
                  route.children ? (
                    <Route key={route.path} path={route.path}>
                      {route.children.map((child) => (
                        <Route
                          key={`${route.path}/${child.path}`}
                          path={child.path}
                          element={child.element}
                        />
                      ))}
                    </Route>
                  ) : (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={route.element}
                    />
                  )
                )}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Box>
          </Box>
        </ThemeProvider>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App; 