import { useState, useEffect, useCallback } from 'react';
import { API_CONFIG } from '../config/api';

export const useFavorites = () => {
  const [favorites, setFavorites] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchFavorites = useCallback(async () => {
    const token = localStorage.getItem('token');
    
    try {
      setLoading(true);
      if (!token) {
        // Если нет токена, используем локальное хранилище
        const localFavorites = localStorage.getItem('favorites');
        setFavorites(localFavorites ? JSON.parse(localFavorites) : []);
        return;
      }

      const response = await fetch(`${API_CONFIG.BASE_URL}/favorites`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch favorites');
      }

      const data = await response.json();
      const favoriteIds = Array.isArray(data) ? data.map((f: any) => f.propertyId) : [];
      setFavorites(favoriteIds);
      
      // Сохраняем в локальное хранилище
      localStorage.setItem('favorites', JSON.stringify(favoriteIds));
    } catch (error) {
      console.error('Error fetching favorites:', error);
      // В случае ошибки используем локальное хранилище
      const localFavorites = localStorage.getItem('favorites');
      setFavorites(localFavorites ? JSON.parse(localFavorites) : []);
    } finally {
      setLoading(false);
    }
  }, []);

  const toggleFavorite = useCallback(async (propertyId: number) => {
    try {
      const newFavorites = favorites.includes(propertyId)
        ? favorites.filter(id => id !== propertyId)
        : [...favorites, propertyId];

      setFavorites(newFavorites);
      localStorage.setItem('favorites', JSON.stringify(newFavorites));

      const token = localStorage.getItem('token');
      if (token) {
        // Если есть токен, синхронизируем с сервером
        await fetch(`${API_CONFIG.BASE_URL}/favorites`, {
          method: favorites.includes(propertyId) ? 'DELETE' : 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ propertyId }),
        });
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  }, [favorites]);

  useEffect(() => {
    fetchFavorites();
  }, [fetchFavorites]);

  return { favorites, loading, toggleFavorite };
}; 