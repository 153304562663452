import { useState, useCallback, useMemo } from 'react';
import { clientRequestsData } from '../data/clientRequests';
import { ClientRequest, RequestStatus } from '../types/clientRequest';

interface ClientRequestFilters {
  search: string;
  status: string;
  property: string;
  assignee: string;
}

interface Pagination {
  page: number;
  perPage: number;
  total: number;
}

export const useClientRequests = () => {
  const [filters, setFilters] = useState<ClientRequestFilters>({
    search: '',
    status: '',
    property: '',
    assignee: ''
  });

  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    perPage: 10,
    total: clientRequestsData.length
  });

  const [selectedRequests, setSelectedRequests] = useState<string[]>([]);

  const filteredRequests = useMemo(() => {
    let result = [...clientRequestsData];

    if (filters.search) {
      const searchLower = filters.search.toLowerCase();
      result = result.filter(request => 
        request.contactInfo.name.toLowerCase().includes(searchLower) ||
        request.contactInfo.phone.includes(searchLower) ||
        request.propertyId.toLowerCase().includes(searchLower)
      );
    }

    if (filters.status) {
      result = result.filter(request => request.status === filters.status);
    }

    if (filters.property) {
      result = result.filter(request => request.propertyId === filters.property);
    }

    if (filters.assignee) {
      result = result.filter(request => request.assignee.id.toString() === filters.assignee);
    }

    return result;
  }, [filters]);

  const paginatedRequests = useMemo(() => {
    const start = (pagination.page - 1) * pagination.perPage;
    const end = start + pagination.perPage;
    return filteredRequests.slice(start, end);
  }, [filteredRequests, pagination]);

  const handleFilterChange = useCallback((key: keyof ClientRequestFilters, value: string) => {
    setFilters(prev => ({ ...prev, [key]: value }));
    setPagination(prev => ({ ...prev, page: 1 })); // Сброс на первую страницу при изменении фильтров
  }, []);

  const handlePageChange = useCallback((newPage: number) => {
    setPagination(prev => ({ ...prev, page: newPage }));
  }, []);

  const handleSelectAll = useCallback((checked: boolean) => {
    if (checked) {
      setSelectedRequests(paginatedRequests.map(request => request.id));
    } else {
      setSelectedRequests([]);
    }
  }, [paginatedRequests]);

  const handleSelectRequest = useCallback((requestId: string, checked: boolean) => {
    setSelectedRequests(prev => {
      if (checked) {
        return [...prev, requestId];
      }
      return prev.filter(id => id !== requestId);
    });
  }, []);

  const totalPages = Math.ceil(filteredRequests.length / pagination.perPage);

  return {
    requests: paginatedRequests,
    totalRequests: filteredRequests.length,
    filters,
    pagination: {
      ...pagination,
      total: filteredRequests.length,
      totalPages
    },
    selectedRequests,
    handleFilterChange,
    handlePageChange,
    handleSelectAll,
    handleSelectRequest
  };
}; 