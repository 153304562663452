import React from 'react';
import {
  Box,
  Card,
  Typography,
  Stack,
  Link,
  IconButton,
  Divider
} from '@mui/material';
import {
  Language as WebsiteIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  LocationOn as LocationIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon
} from '@mui/icons-material';
import { companyData } from '../../../data/company';

// Определяем тип для socialIcons
type SocialPlatform = 'facebook' | 'instagram' | 'linkedin';

const CompanyInfo = () => {
  const socialIcons: Record<SocialPlatform, typeof FacebookIcon> = {
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    linkedin: LinkedInIcon
  };

  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>Company Information</Typography>
      
      <Stack spacing={3}>
        {/* Basic Info */}
        <Box>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Founded
          </Typography>
          <Typography>
            {companyData.foundedYear}
          </Typography>
        </Box>

        {/* Contact Info */}
        <Stack spacing={2}>
          <Typography variant="subtitle2" color="text.secondary">
            Contacts
          </Typography>
          
          <Stack spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <WebsiteIcon color="action" />
              <Link href={`https://${companyData.website}`} target="_blank" underline="hover">
                {companyData.website}
              </Link>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <EmailIcon color="action" />
              <Link href={`mailto:${companyData.contacts.email}`} underline="hover">
                {companyData.contacts.email}
              </Link>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <PhoneIcon color="action" />
              <Link href={`tel:${companyData.contacts.phone}`} underline="hover">
                {companyData.contacts.phone}
              </Link>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <LocationIcon color="action" />
              <Typography>
                {companyData.contacts.address}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Divider />

        {/* Social Media */}
        <Box>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Social Media
          </Typography>
          <Stack direction="row" spacing={1}>
            {Object.entries(companyData.socialMedia).map(([platform, url]) => {
              const Icon = socialIcons[platform as SocialPlatform];
              return url ? (
                <IconButton
                  key={platform}
                  href={url}
                  target="_blank"
                  sx={{
                    color: 'grey.500',
                    '&:hover': { color: 'primary.main' }
                  }}
                >
                  <Icon />
                </IconButton>
              ) : null;
            })}
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};

export default CompanyInfo; 