import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Badge,
  Tooltip,
  Collapse
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Business as BusinessIcon,
  Home as HomeIcon,
  GridView as GridViewIcon,
  CalendarMonth as CalendarIcon,
  Message as MessageIcon,
  Person as PersonIcon,
  School as SchoolIcon,
  Add as AddIcon,
  Notifications as NotificationsIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  KeyboardArrowDown as ArrowDownIcon,
  Psychology as PsychologyIcon,
  Quiz as QuizIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';

// Моковые данные пользователя (потом заменить на реальные)
const mockUser = {
  name: 'John Doe',
  email: 'john.doe@example.com',
  role: 'Senior Agent',
  avatar: '/path/to/avatar.jpg',
  notifications: 3
};

const navigationItems = [
  { path: '/', label: 'Dashboard', icon: <DashboardIcon /> },
  { path: '/company', label: 'Company', icon: <BusinessIcon /> },
  { path: '/properties', label: 'Properties', icon: <HomeIcon /> },
  { path: '/units', label: 'Units', icon: <GridViewIcon /> },
  { path: '/calendar', label: 'Calendar', icon: <CalendarIcon /> },
  { path: '/messages', label: 'Messages', icon: <MessageIcon /> },
  { path: '/requests', label: 'Client Requests', icon: <PersonIcon /> },
  {
    path: '/agent-training',
    label: 'Agent Training',
    icon: <SchoolIcon />,
    subItems: [
      {
        path: '/agent-training',
        label: 'Training Dashboard',
        icon: <SchoolIcon />
      },
      {
        path: '/agent-training/test-generator',
        label: 'Standard Generator',
        icon: <QuizIcon />
      },
      {
        path: '/training/test-generator',
        label: 'AI Test Generator',
        icon: <PsychologyIcon />
      }
    ]
  }
];

const Navigation = () => {
  const location = useLocation();
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuClick = (path: string) => {
    setOpenSubMenu(openSubMenu === path ? null : path);
  };

  return (
    <Box sx={{ width: 240, flexShrink: 0 }}>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
        <Avatar src={mockUser.avatar} />
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle2">{mockUser.name}</Typography>
          <Typography variant="caption" color="text.secondary">
            {mockUser.role}
          </Typography>
        </Box>
        <IconButton size="small">
          <Badge badgeContent={mockUser.notifications} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton size="small" onClick={handleMenuClick}>
          <ArrowDownIcon />
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>

      <Divider />

      <List>
        {navigationItems.map((item) => (
          <React.Fragment key={item.path}>
            {item.subItems ? (
              <>
                <ListItemButton
                  onClick={() => handleSubMenuClick(item.path)}
                  selected={location.pathname.startsWith(item.path)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                  {openSubMenu === item.path ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                <Collapse in={openSubMenu === item.path} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem) => (
                      <ListItemButton
                        key={subItem.path}
                        component={Link}
                        to={subItem.path}
                        selected={location.pathname === subItem.path}
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>{subItem.icon}</ListItemIcon>
                        <ListItemText primary={subItem.label} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <ListItemButton
                component={Link}
                to={item.path}
                selected={location.pathname === item.path}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default Navigation; 