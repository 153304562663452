import React from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { tableStyles } from '../../styles/tableStyles'; // Импорт общих стилей
import { mockCertificates } from '../../data/mockTrainingData'; // Импорт моковых данных

const Certification: React.FC = () => {
  return (
    <Box sx={tableStyles.root}>
      <Typography variant="h4" gutterBottom>
        Certification
      </Typography>
      <Typography variant="h5" gutterBottom>
        Выданные сертификаты
      </Typography>
      <List>
        {mockCertificates.map((cert) => (
          <ListItem key={cert.id}>
            <ListItemText
              primary={`Сертификат ${cert.id}`}
              secondary={`Агент ID: ${cert.agentId}, Модуль ID: ${cert.moduleId}, Дата выдачи: ${cert.issueDate}, Действителен до: ${cert.validUntil}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Certification; 