import React from 'react';
import { Box, Typography, IconButton, Stack } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import ProjectCard from './ProjectCard';
import { projectImages } from '../../assets/images';
import ProjectCharts from '../Charts/ProjectCharts';

const projects = [
  {
    id: 'vip-space-odyssey',
    type: 'Condominiums',
    name: 'VIP Space Odyssey',
    location: {
      area: 'Nai Harn',
      city: 'Phuket',
      country: 'Thailand'
    },
    price: {
      min: 4.4,
      max: 9.0,
      currency: '฿'
    },
    details: {
      units: 600,
      deadline: 'Dec 2027',
      status: 'Off plan',
      distance: 600,
      beach: 'Rawai Beach'
    },
    guarantee: {
      percent: 6,
      years: 2
    },
    image: projectImages['vip-space-odyssey']
  },
  {
    id: 'vip-galaxy-villas',
    type: 'Condominiums',
    name: 'VIP Galaxy Villas',
    location: {
      area: 'Nai Harn',
      city: 'Phuket',
      country: 'Thailand'
    },
    price: {
      min: 12.6,
      max: 28.0,
      currency: '฿'
    },
    details: {
      units: 19,
      deadline: 'Dec 2027',
      status: 'Off plan',
      distance: 600,
      beach: 'Rawai Beach'
    },
    guarantee: {
      percent: 6,
      years: 2
    },
    discount: 40,
    image: projectImages['vip-galaxy-villas']
  },
  {
    id: 'vip-space-villas',
    type: 'Villas',
    name: 'VIP Space Villas',
    location: {
      area: 'Rawai',
      city: 'Phuket Town',
      country: 'Phuket'
    },
    price: {
      min: 4.4,
      max: 9.0,
      currency: '฿'
    },
    details: {
      units: 114,
      deadline: 'Dec 2026',
      status: 'Off plan',
      distance: 600,
      beach: 'Rawai Beach'
    },
    guarantee: {
      percent: 6,
      years: 2
    },
    image: projectImages['vip-space-villas']
  }
];

const ProjectsGrid = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Stack spacing={3}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          px: 0.5
        }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            All projects (4)
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton 
              sx={{ 
                border: '1px solid', 
                borderColor: 'grey.200',
                borderRadius: 1,
                p: 1
              }}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton 
              sx={{ 
                border: '1px solid', 
                borderColor: 'grey.200',
                borderRadius: 1,
                p: 1
              }}
            >
              <ChevronRight />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(3, 1fr)', 
          gap: 3,
          px: 0.5
        }}>
          {projects.map(project => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </Box>
        <ProjectCharts />
      </Stack>
    </Box>
  );
};

export default ProjectsGrid; 