import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Alert,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Chip,
  Avatar,
  Badge
} from '@mui/material';
import {
  PlayArrow as PlayArrowIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  ArrowBack as ArrowBackIcon,
  Timer as TimerIcon,
  School as SchoolIcon,
  EmojiEvents as TrophyIcon,
  Stars as StarsIcon,
  Lightbulb as LightbulbIcon
} from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import { mockCourseModules } from '../../data/mockTrainingData';
import { mockMaterials } from '../../data/materials';
import { CourseModule, Lesson } from '../../types/training';
import ProgressBlock from './ProgressBlock';

const CoursePage: React.FC = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<{ [key: string]: string }>({});
  const [testResults, setTestResults] = useState<{ [key: string]: boolean }>({});
  const [showTest, setShowTest] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [testCompleted, setTestCompleted] = useState(false);
  const [score, setScore] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [earnedAchievements, setEarnedAchievements] = useState<string[]>([]);

  const course = mockCourseModules.find(c => c.id === courseId) as CourseModule | undefined;
  const currentLesson = course?.lessons?.[activeStep] as Lesson | undefined;

  useEffect(() => {
    if (showTest && currentLesson?.test.timeLimit && !testCompleted) {
      setTimeLeft(currentLesson.test.timeLimit);
      const timer = setInterval(() => {
        setTimeLeft(prev => {
          if (prev === null || prev <= 1) {
            clearInterval(timer);
            if (prev === 1) submitTest();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [showTest, currentLesson, testCompleted]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleNext = () => {
    if (currentLesson?.test && !testResults[currentLesson.id]) {
      setShowTest(true);
    } else {
      setActiveStep(prev => prev + 1);
      setShowTest(false);
      setTestCompleted(false);
      setSelectedAnswers({});
    }
  };

  const handleBack = () => {
    setActiveStep(prev => prev - 1);
    setShowTest(false);
    setTestCompleted(false);
    setSelectedAnswers({});
  };

  const handleAnswerSelect = (questionId: string, answer: string) => {
    setSelectedAnswers(prev => ({
      ...prev,
      [questionId]: answer
    }));
  };

  const calculateScore = () => {
    let totalScore = 0;
    currentLesson?.test.questions.forEach(question => {
      if (selectedAnswers[question.id] === question.correctAnswer) {
        totalScore += question.points;
      }
    });
    return totalScore;
  };

  const submitTest = () => {
    const testScore = calculateScore();
    setScore(testScore);
    setTestCompleted(true);
    setShowResults(true);
    
    if (testScore === currentLesson?.test.questions.reduce((sum, q) => sum + q.points, 0)) {
      setEarnedAchievements(prev => [...prev, 'perfect_score']);
    }
    if (timeLeft && timeLeft > currentLesson?.test.timeLimit! / 2) {
      setEarnedAchievements(prev => [...prev, 'speed_master']);
    }
    
    if (currentLesson) {
      setTestResults(prev => ({
        ...prev,
        [currentLesson.id]: testScore >= currentLesson.test.requiredScore
      }));
    }
  };

  if (!course || !currentLesson) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>Курс не найден</Typography>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/agent-training')}
        >
          Вернуться к списку курсов
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/agent-training')}
        sx={{ mb: 3 }}
      >
        Вернуться к списку курсов
      </Button>

      {/* Блок прогресса */}
      <ProgressBlock
        progress={course.progress || { enrolled: 0, completed: 0, averageScore: 0 }}
        achievements={course.achievements || []}
        totalScore={course.totalScore}
        level={course.level}
      />

      {/* Основная информация о курсе */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h4" gutterBottom>
          {course.title}
        </Typography>
        <Typography color="text.secondary" gutterBottom>
          {course.description}
        </Typography>

        <Box sx={{ mt: 3 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {course.lessons.map((lesson, index) => (
              <Step key={lesson.id} completed={testResults[lesson.id]}>
                <StepLabel>{lesson.title}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 3 }}>
            {!showTest ? (
              // Контент урока
              <Box>
                <Typography variant="h5" gutterBottom>
                  {currentLesson.title}
                </Typography>
                <Box sx={{ my: 3 }}>
                  <ReactMarkdown>{currentLesson.content}</ReactMarkdown>
                </Box>
                {currentLesson.materials && currentLesson.materials.length > 0 && (
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" gutterBottom>
                      Материалы урока
                    </Typography>
                    <List>
                      {currentLesson.materials.map(materialId => {
                        const material = mockMaterials.find(m => m.id === materialId);
                        return material ? (
                          <ListItem key={material.id}>
                            <ListItemIcon>
                              <SchoolIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={material.title}
                              secondary={`Тип: ${material.type}`}
                            />
                          </ListItem>
                        ) : null;
                      })}
                    </List>
                  </Box>
                )}
              </Box>
            ) : (
              // Тест
              <Box>
                <Typography variant="h5" gutterBottom>
                  Тест по теме: {currentLesson.title}
                </Typography>
                {timeLeft !== null && !testCompleted && (
                  <Alert
                    icon={<TimerIcon />}
                    severity="info"
                    sx={{ mb: 2 }}
                  >
                    Оставшееся время: {formatTime(timeLeft)}
                  </Alert>
                )}
                {currentLesson.test.questions.map((question, index) => (
                  <Card key={question.id} sx={{ mb: 2 }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {index + 1}. {question.text}
                      </Typography>
                      <FormControl component="fieldset">
                        <RadioGroup
                          value={selectedAnswers[question.id] || ''}
                          onChange={(e) => handleAnswerSelect(question.id, e.target.value)}
                        >
                          {question.options.map((option, optIndex) => (
                            <FormControlLabel
                              key={optIndex}
                              value={option}
                              control={<Radio />}
                              label={option}
                              disabled={testCompleted}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                      {testCompleted && (
                        <Box sx={{ mt: 2 }}>
                          {selectedAnswers[question.id] === question.correctAnswer ? (
                            <Alert severity="success">
                              Правильный ответ! {question.explanation}
                            </Alert>
                          ) : (
                            <Alert severity="error">
                              Неправильно. Правильный ответ: {question.correctAnswer}
                              {question.explanation && `. ${question.explanation}`}
                            </Alert>
                          )}
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                ))}
              </Box>
            )}

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
              <Button
                onClick={handleBack}
                disabled={activeStep === 0}
                startIcon={<ArrowBackIcon />}
              >
                Назад
              </Button>
              <Box>
                {showTest && !testCompleted ? (
                  <Button
                    variant="contained"
                    onClick={submitTest}
                    disabled={Object.keys(selectedAnswers).length !== currentLesson.test.questions.length}
                  >
                    Завершить тест
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={showTest && !testCompleted}
                    endIcon={activeStep === course.lessons.length - 1 ? <CheckCircleIcon /> : <PlayArrowIcon />}
                  >
                    {activeStep === course.lessons.length - 1 ? 'Завершить курс' : 'Далее'}
                  </Button>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Прогресс урока
            </Typography>
            <LinearProgress
              variant="determinate"
              value={testCompleted ? 100 : showTest ? 50 : 0}
              sx={{ mb: 2 }}
            />
            <List>
              <ListItem>
                <ListItemIcon>
                  <SchoolIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Статус"
                  secondary={testCompleted ? 'Завершен' : showTest ? 'Тестирование' : 'Изучение материала'}
                />
              </ListItem>
              {testCompleted && (
                <ListItem>
                  <ListItemIcon>
                    {score >= currentLesson.test.requiredScore ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <WarningIcon color="error" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary="Результат теста"
                    secondary={`${score} баллов из ${currentLesson.test.questions.reduce(
                      (sum, q) => sum + q.points,
                      0
                    )}`}
                  />
                </ListItem>
              )}
            </List>
          </Paper>
        </Grid>
      </Grid>

      {/* Диалог с результатами теста */}
      <Dialog
        open={showResults}
        onClose={() => {
          setShowResults(false);
          if (score >= currentLesson.test.requiredScore) {
            if (activeStep === course.lessons.length - 1) {
              navigate('/agent-training');
            } else {
              handleNext();
            }
          }
        }}
      >
        <DialogTitle>
          {score >= currentLesson.test.requiredScore
            ? 'Поздравляем!'
            : 'Тест не пройден'}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {score >= currentLesson.test.requiredScore
              ? `Вы успешно прошли тест! Набрано ${score} баллов.`
              : `К сожалению, тест не пройден. Необходимо набрать больше баллов.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          {score >= currentLesson.test.requiredScore ? (
            <Button
              onClick={() => {
                setShowResults(false);
                if (activeStep === course.lessons.length - 1) {
                  navigate('/agent-training');
                } else {
                  handleNext();
                }
              }}
              variant="contained"
            >
              {activeStep === course.lessons.length - 1 ? 'Завершить курс' : 'Следующий урок'}
            </Button>
          ) : (
            <>
              <Button
                onClick={() => {
                  setShowResults(false);
                  setShowTest(false);
                  setTestCompleted(false);
                  setSelectedAnswers({});
                }}
              >
                Повторить материал
              </Button>
              <Button
                onClick={() => {
                  setShowResults(false);
                  setTestCompleted(false);
                  setSelectedAnswers({});
                }}
                variant="contained"
              >
                Пересдать тест
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CoursePage;