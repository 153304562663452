import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Button,
  Stack,
  Card,
  IconButton,
  Alert
} from '@mui/material';
import { 
  Upload as UploadIcon,
  Delete as DeleteIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { BuildingMarkupTool } from '../../components/admin/BuildingMarkupTool';
import { Polygon } from '../../components/admin/BuildingMarkupTool';

const BuildingMarkup = () => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [polygons, setPolygons] = useState<Polygon[]>([]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result as string);
        setError(null);
      };
      reader.onerror = () => {
        setError('Error reading file');
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClearImage = () => {
    setImageUrl(null);
    setPolygons([]);
  };

  const handleSaveMarkup = (savedPolygons: Polygon[]) => {
    setPolygons(savedPolygons);
    // TODO: Implement saving to backend
    console.log('Saved polygons:', savedPolygons);
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Box>
          <Typography variant="h5">Building Markup Tool</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Create interactive areas for building plans
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            component="label"
            startIcon={<UploadIcon />}
            disabled={!!imageUrl}
          >
            Upload Plan
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageUpload}
            />
          </Button>
          {imageUrl && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={() => handleSaveMarkup(polygons)}
            >
              Save Markup
            </Button>
          )}
        </Stack>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {!imageUrl ? (
        <Card
          sx={{
            p: 6,
            textAlign: 'center',
            border: '2px dashed',
            borderColor: 'divider',
            bgcolor: 'grey.50'
          }}
        >
          <Stack spacing={2} alignItems="center">
            <UploadIcon sx={{ fontSize: 48, color: 'text.secondary' }} />
            <Typography variant="h6">
              Upload a building plan to start markup
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Supported formats: PNG, JPG, WEBP
            </Typography>
            <Button
              variant="contained"
              component="label"
              startIcon={<UploadIcon />}
            >
              Select File
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleImageUpload}
              />
            </Button>
          </Stack>
        </Card>
      ) : (
        <Card sx={{ position: 'relative' }}>
          <IconButton
            onClick={handleClearImage}
            sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
          >
            <DeleteIcon />
          </IconButton>
          <BuildingMarkupTool
            imageUrl={imageUrl}
            initialPolygons={polygons}
            onSave={handleSaveMarkup}
          />
        </Card>
      )}
    </Box>
  );
};

export default BuildingMarkup; 