import { TrainingModule, Agent, Test, CourseModule, Material } from '../types/training';

export const mockTrainingModules: TrainingModule[] = [
  {
    id: 'module1',
    title: 'Основы работы с клиентами',
    description: 'Базовые принципы работы с клиентами в сфере недвижимости',
    contentUrl: '/training/client-basics'
  }
];

// Материалы для курсов
export const mockMaterials: Material[] = [
  {
    id: 'mat-1',
    type: 'video',
    title: 'Введение в рынок недвижимости',
    url: '/materials/videos/market-intro.mp4',
    duration: '15:00',
    thumbnail: '/thumbnails/market-intro.jpg'
  },
  {
    id: 'mat-2',
    type: 'pdf',
    title: 'Справочник терминов',
    url: '/materials/docs/terms-guide.pdf',
    pages: 25,
    thumbnail: '/thumbnails/terms-guide.jpg'
  },
  {
    id: 'mat-3',
    type: 'presentation',
    title: 'Типы недвижимости',
    url: '/materials/presentations/property-types.pptx',
    slides: 30,
    thumbnail: '/thumbnails/property-types.jpg'
  },
  {
    id: 'mat-4',
    type: 'interactive',
    title: 'Виртуальный тур по объекту',
    url: '/materials/interactive/virtual-tour',
    duration: '10:00',
    thumbnail: '/thumbnails/virtual-tour.jpg'
  },
  {
    id: 'mat-5',
    type: 'quiz',
    title: 'Практические задания',
    url: '/materials/quizzes/practice',
    questions: 15,
    thumbnail: '/thumbnails/practice-quiz.jpg'
  }
];

// Расширенные курсы с материалами и детальными тестами
export const mockCourseModules: CourseModule[] = [
  {
    id: 'course1',
    title: 'Основы работы с клиентами',
    description: 'Базовые принципы работы с клиентами в сфере недвижимости',
    level: 'Начальный',
    duration: '2 часа',
    totalScore: 100,
    contentUrl: '/courses/client-basics',
    requiredScore: 70,
    prerequisites: [],
    materials: ['mat-1', 'mat-2', 'mat-4'],
    progress: {
      enrolled: 45,
      completed: 32,
      averageScore: 85
    },
    lessons: [
      {
        id: 'lesson1-1',
        title: 'Введение в работу с клиентами',
        content: `
          # Основы работы с клиентами

          ## Ключевые принципы:
          - 🤝 Профессионализм
          - ✨ Честность
          - 📋 Ответственность
          - 👥 Индивидуальный подход

          ## Этапы работы:
          1. 📞 Первичный контакт
             * Установление доверия
             * Выявление базовых потребностей
             * Назначение встречи

          2. 🎯 Выявление потребностей
             * Детальное интервью
             * Анализ пожеланий
             * Определение бюджета

          3. 🏠 Презентация объектов
             * Подбор вариантов
             * Организация просмотров
             * Работа с возражениями

          4. 📝 Сопровождение сделки
             * Проверка документов
             * Организация сделки
             * Постпродажное обслуживание

          > 💡 **Важно:** Каждый клиент уникален, поэтому важно адаптировать свой подход под конкретную ситуацию.

          ### Практические примеры:
          * 🌟 Кейс 1: Продажа квартиры в новостройке
          * 🏢 Кейс 2: Поиск коммерческой недвижимости
          * 🏘️ Кейс 3: Работа с загородной недвижимостью
        `,
        materials: ['mat-1', 'mat-4'],
        estimatedTime: 45,
        interactiveElements: {
          virtualTour: true,
          practiceQuiz: true,
          casesStudy: true
        },
        test: {
          timeLimit: 1800,
          requiredScore: 7,
          questions: [
            {
              id: 'q1-1',
              text: 'Какой принцип является основополагающим в работе с клиентами?',
              options: [
                'Профессионализм',
                'Быстрая продажа',
                'Максимальная комиссия',
                'Минимум усилий'
              ],
              correctAnswer: 'Профессионализм',
              points: 10,
              explanation: 'Профессионализм - основа успешной работы с клиентами',
              hint: 'Подумайте о долгосрочной перспективе работы с клиентами'
            },
            {
              id: 'q1-2',
              text: 'Что важно сделать на этапе первичного контакта?',
              options: [
                'Установить доверительные отношения',
                'Сразу предложить объекты',
                'Запросить документы',
                'Назначить сделку'
              ],
              correctAnswer: 'Установить доверительные отношения',
              points: 15,
              explanation: 'Доверие клиента - основа успешного сотрудничества',
              hint: 'Первое впечатление очень важно'
            },
            {
              id: 'q1-3',
              text: 'Как лучше всего работать с возражениями клиента?',
              options: [
                'Выслушать и предложить решение',
                'Игнорировать',
                'Спорить с клиентом',
                'Снизить цену'
              ],
              correctAnswer: 'Выслушать и предложить решение',
              points: 15,
              explanation: 'Конструктивный диалог помогает найти оптимальное решение',
              hint: 'Клиент должен чувствовать, что его понимают'
            }
          ]
        }
      }
    ],
    achievements: [
      {
        id: 'first_client',
        title: 'Первый клиент',
        description: 'Успешно завершите первую сделку',
        icon: '🎯',
        points: 50
      },
      {
        id: 'perfect_score',
        title: 'Отличник',
        description: 'Получите 100% за тест',
        icon: '🏆',
        points: 100
      },
      {
        id: 'speed_master',
        title: 'Быстрый ответ',
        description: 'Завершите тест за половину отведенного времени',
        icon: '⚡',
        points: 75
      }
    ]
  },
  {
    id: 'course2',
    title: 'Маркетинг недвижимости',
    description: 'Современные методы продвижения объектов недвижимости',
    level: 'Средний',
    duration: '4 часа',
    totalScore: 150,
    contentUrl: '/courses/marketing',
    requiredScore: 75,
    prerequisites: ['course1'],
    materials: ['mat-2', 'mat-3'],
    progress: {
      enrolled: 35,
      completed: 22,
      averageScore: 82
    },
    lessons: [
      {
        id: 'lesson2-1',
        title: 'Digital-маркетинг в недвижимости',
        content: `
          # Digital-маркетинг

          ## Каналы продвижения:
          - Социальные сети
          - Email-маркетинг
          - Контекстная реклама
          - Таргетированная реклама

          ## Инструменты:
          1. Instagram & Facebook
          2. Google Ads
          3. Email-рассылки
          4. Лендинги
        `,
        materials: ['mat-3'],
        estimatedTime: 90,
        test: {
          timeLimit: 2700,
          requiredScore: 15,
          questions: [
            {
              id: 'q2-1',
              text: 'Какой канал наиболее эффективен для продвижения премиальной недвижимости?',
              options: [
                'Instagram и Facebook',
                'Газеты',
                'Радио',
                'Листовки'
              ],
              correctAnswer: 'Instagram и Facebook',
              points: 20,
              explanation: 'Социальные сети позволяют точно таргетировать целевую аудиторию'
            }
          ]
        }
      }
    ]
  },
  {
    id: 'course3',
    title: 'Инвестиции в недвижимость',
    description: 'Анализ инвестиционной привлекательности объектов',
    level: 'Продвинутый',
    duration: '6 часов',
    totalScore: 200,
    contentUrl: '/courses/investments',
    requiredScore: 85,
    materials: ['mat-1', 'mat-3'],
    progress: {
      enrolled: 25,
      completed: 18,
      averageScore: 88
    },
    lessons: [
      {
        id: 'lesson3-1',
        title: 'Оценка инвестиционного потенциала',
        content: `
          # Инвестиционный анализ

          ## Ключевые показатели:
          - ROI (Return on Investment)
          - Срок окупаемости
          - Потенциал роста стоимости
          - Риски инвестирования

          ## Методы оценки:
          1. Сравнительный анализ
          2. Доходный подход
          3. Затратный подход
          4. Анализ рынка
        `,
        materials: ['mat-1'],
        estimatedTime: 120,
        test: {
          timeLimit: 3600,
          requiredScore: 20,
          questions: [
            {
              id: 'q3-1',
              text: 'Какой показатель наиболее важен при оценке инвестиционной привлекательности?',
              options: [
                'ROI (Return on Investment)',
                'Только цена объекта',
                'Только локация',
                'Только состояние объекта'
              ],
              correctAnswer: 'ROI (Return on Investment)',
              points: 25,
              explanation: 'ROI позволяет оценить эффективность инвестиций в процентном соотношении'
            }
          ]
        }
      }
    ],
    prerequisites: ['course1', 'course2']
  }
];

export const mockAgents: Agent[] = [
  {
    id: 'agent1',
    name: 'Иван Иванов',
    email: 'ivan.ivanov@example.com',
    role: 'Senior Agent',
    completedModules: ['course1', 'course2'],
    certificates: ['cert1', 'cert2'],
    progress: {
      currentCourse: 'course3',
      lastActivity: '2024-03-15',
      testResults: [
        {
          courseId: 'course1',
          score: 95,
          completedDate: '2024-02-01',
          timeSpent: 115
        },
        {
          courseId: 'course2',
          score: 88,
          completedDate: '2024-03-01',
          timeSpent: 180
        }
      ]
    }
  }
];

export const mockTests: Test[] = [
  {
    timeLimit: 1800,
    requiredScore: 7,
    questions: [
      {
        id: 'q1',
        text: 'Какой основной принцип работы с клиентом?',
        options: [
          'Индивидуальный подход',
          'Максимальная прибыль',
          'Быстрая сделка',
          'Минимум контактов'
        ],
        correctAnswer: 'Индивидуальный подход',
        points: 10
      }
    ]
  }
];

export const mockCertificates = [
  {
    id: 'cert1',
    agentId: 'agent1',
    moduleId: 'course1',
    issueDate: '2024-01-01',
    validUntil: '2024-12-31'
  },
  {
    id: 'cert2',
    agentId: 'agent1',
    moduleId: 'course2',
    issueDate: '2024-02-01',
    validUntil: '2024-12-31'
  }
];

// Статистика по курсам
export const mockCourseStats = {
  totalEnrollments: 156,
  completionRate: 72,
  averageScore: 84,
  popularCourses: [
    {
      courseId: 'course1',
      enrollments: 45,
      averageRating: 4.5
    },
    {
      courseId: 'course2',
      enrollments: 28,
      averageRating: 4.8
    }
  ],
  monthlyProgress: [
    { month: 'Январь', completions: 12, newEnrollments: 18 },
    { month: 'Февраль', completions: 15, newEnrollments: 22 },
    { month: 'Март', completions: 18, newEnrollments: 25 }
  ]
};

// Отзывы о курсах
export const mockCourseReviews = [
  {
    id: 'review1',
    courseId: 'course1',
    agentId: 'agent1',
    rating: 5,
    comment: 'Отличный базовый курс, много практических примеров',
    date: '2024-02-15'
  },
  {
    id: 'review2',
    courseId: 'course2',
    agentId: 'agent2',
    rating: 4,
    comment: 'Полезные техники продаж, но хотелось бы больше практики',
    date: '2024-03-01'
  }
];

// Достижения
export const mockAchievements = [
  {
    id: 'ach1',
    title: 'Быстрый старт',
    description: 'Завершите первый курс менее чем за 3 дня',
    icon: '🚀',
    points: 100
  },
  {
    id: 'ach2',
    title: 'Отличник',
    description: 'Получите 95+ баллов в любом курсе',
    icon: '🏆',
    points: 150
  }
];

// Прогресс по достижениям агентов
export const mockAgentAchievements = {
  'agent1': ['ach1', 'ach2'],
  'agent2': ['ach1']
}; 