import { useState, useEffect } from 'react';
import { Building, Section } from '../types/building';

export const useBuilding = (buildingId: string) => {
  const [building, setBuilding] = useState<Building | null>(null);
  const [sections, setSections] = useState<Section[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBuilding = async () => {
      if (!buildingId) return;
      
      setLoading(true);
      try {
        const response = await fetch(`/api/buildings/${buildingId}`);
        if (!response.ok) throw new Error('Failed to fetch building data');
        
        const data = await response.json();
        setBuilding(data);
        setSections(data.sections);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Unknown error');
      } finally {
        setLoading(false);
      }
    };

    fetchBuilding();
  }, [buildingId]);

  return { building, sections, loading, error };
}; 