import React, { useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Stack,
  TextField,
  Select,
  MenuItem,
  Chip,
  InputAdornment,
  IconButton
} from '@mui/material';
import {
  Search as SearchIcon,
  Close as CloseIcon,
  TuneRounded as FilterIcon
} from '@mui/icons-material';

interface Filter {
  key: string;
  value: string;
  label: string;
}

const ProjectFilters = () => {
  const [filters, setFilters] = useState({
    search: '',
    type: '',
    priceRange: '',
    status: ''
  });

  const [activeFilters, setActiveFilters] = useState<Filter[]>([]);

  const handleFilterChange = (key: string, value: string, label?: string) => {
    setFilters(prev => ({ ...prev, [key]: value }));
    if (value && !activeFilters.find(f => f.key === key)) {
      setActiveFilters(prev => [...prev, { 
        key, 
        value,
        label: label || value
      }]);
    }
  };

  const handleRemoveFilter = (filterKey: string) => {
    setActiveFilters(prev => prev.filter(f => f.key !== filterKey));
    setFilters(prev => ({ ...prev, [filterKey]: '' }));
  };

  return (
    <Card sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h6">
          Project Filters
        </Typography>
        <IconButton>
          <FilterIcon />
        </IconButton>
      </Box>

      <Stack spacing={3}>
        {/* Search and Filters */}
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            placeholder="Search projects"
            value={filters.search}
            onChange={(e) => handleFilterChange('search', e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
            }}
            size="small"
          />
          <Select
            value={filters.type}
            onChange={(e) => handleFilterChange('type', e.target.value, 
              e.target.value === 'condominiums' ? 'Condominiums' : 'Villas')}
            displayEmpty
            size="small"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value="">Property Type</MenuItem>
            <MenuItem value="condominiums">Condominiums</MenuItem>
            <MenuItem value="villas">Villas</MenuItem>
          </Select>
          <Select
            value={filters.priceRange}
            onChange={(e) => handleFilterChange('priceRange', e.target.value)}
            displayEmpty
            size="small"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value="">Price Range</MenuItem>
            <MenuItem value="0-5">฿0M - ฿5M</MenuItem>
            <MenuItem value="5-10">฿5M - ฿10M</MenuItem>
            <MenuItem value="10+">฿10M+</MenuItem>
          </Select>
          <Select
            value={filters.status}
            onChange={(e) => handleFilterChange('status', e.target.value)}
            displayEmpty
            size="small"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value="">Status</MenuItem>
            <MenuItem value="off-plan">Off Plan</MenuItem>
            <MenuItem value="under-construction">Under Construction</MenuItem>
            <MenuItem value="ready">Ready to Move</MenuItem>
          </Select>
        </Stack>

        {/* Active Filters */}
        {activeFilters.length > 0 && (
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {activeFilters.map(filter => (
              <Chip
                key={filter.key}
                label={filter.label}
                onDelete={() => handleRemoveFilter(filter.key)}
                deleteIcon={<CloseIcon />}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        )}

        {/* Results Summary */}
        <Box sx={{ pt: 2 }}>
          <Typography variant="body2" color="text.secondary">
            {activeFilters.length > 0 
              ? `${activeFilters.length} filters applied`
              : 'No filters applied'}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

export default ProjectFilters; 