import { RouteObject } from 'react-router-dom';
import Properties from './pages/Properties/Properties';
import PropertyDetails from './pages/Properties/PropertyDetails';
import Dashboard from './pages/Dashboard/Dashboard';
import CompanyPage from './pages/CompanyPage/CompanyPage';
import Calendar from './pages/Calendar/Calendar';
import Messages from './pages/Messages/Messages';
import ClientRequests from './pages/ClientRequests/ClientRequests';
import UnitsGrid from './pages/Units/UnitsGrid';
import BuildingMarkup from './pages/BuildingMarkup/BuildingMarkup';
import TestGenerator from './pages/TestGenerator/TestWizard';
import { TrainingDashboard, TestGenerator as AgentTestGenerator, Certification, CoursePage, TestPage } from './components/AgentTraining';
import { NewObjectPage } from './components/AgentTraining/NewObjectPage';

// Конфигурация маршрутов
export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Dashboard />
  },
  {
    path: '/company',
    element: <CompanyPage />
  },
  {
    path: '/properties',
    element: <Properties />
  },
  {
    path: '/properties/:id',
    element: <PropertyDetails />
  },
  {
    path: '/units',
    element: <UnitsGrid />
  },
  {
    path: '/calendar',
    element: <Calendar />
  },
  {
    path: '/messages',
    element: <Messages />
  },
  {
    path: '/requests',
    element: <ClientRequests />
  },
  {
    path: '/tools',
    children: [
      {
        path: 'building-markup',
        element: <BuildingMarkup />
      }
    ]
  },
  {
    path: '/agent-training',
    children: [
      {
        path: '',
        element: <TrainingDashboard />
      },
      {
        path: 'test-generator',
        element: <AgentTestGenerator />
      },
      {
        path: 'certification',
        element: <Certification />
      },
      {
        path: 'course/:courseId',
        element: <CoursePage />
      },
      {
        path: 'test/:courseId/:lessonId',
        element: <TestPage />
      }
    ]
  },
  {
    path: '/training',
    children: [
      {
        path: '',
        element: <TrainingDashboard />
      },
      {
        path: 'test-generator',
        element: <TestGenerator />,
        handle: {
          title: 'AI генератор тестов'
        }
      }
    ]
  },
  {
    path: '/new-object',
    element: <NewObjectPage />,
    // Добавляем защиту маршрута если необходимо
  }
];

// Конфигурация меню навигации
export const menuItems = [
  {
    title: 'Главная',
    path: '/',
    icon: 'Dashboard'
  },
  {
    title: 'Компания',
    path: '/company',
    icon: 'Business'
  },
  {
    title: 'Объекты',
    path: '/properties',
    icon: 'Apartment'
  },
  {
    title: 'Квартиры',
    path: '/units',
    icon: 'HomeWork'
  },
  {
    title: 'Календарь',
    path: '/calendar',
    icon: 'CalendarToday'
  },
  {
    title: 'Сообщения',
    path: '/messages',
    icon: 'Message'
  },
  {
    title: 'Заявки',
    path: '/requests',
    icon: 'Assignment'
  },
  {
    title: 'Добавить объект',
    path: '/new-object',
    icon: 'Add'
  }
];

// Добавляем отдельную группу для обучения и тестирования
export const trainingMenuItems = [
  {
    title: 'Обучение',
    items: [
      {
        title: 'Панель обучения',
        path: '/agent-training',
        icon: 'School'
      },
      {
        title: 'Создать тест',
        items: [
          {
            title: 'Стандартный генератор',
            path: '/agent-training/test-generator',
            icon: 'Quiz'
          },
          {
            title: 'AI генератор тестов',
            path: '/training/test-generator',
            icon: 'Psychology'
          }
        ]
      },
      {
        title: 'Сертификация',
        path: '/agent-training/certification',
        icon: 'EmojiEvents'
      }
    ]
  }
];

// Добавляем отдельную группу для инструментов
export const toolsMenuItems = [
  {
    title: 'Инструменты',
    items: [
      {
        title: 'Разметка зданий',
        path: '/tools/building-markup',
        icon: 'Edit'
      }
    ]
  }
];

// Экспортируем все конфигурации
export { menuItems as navigationConfig };
export { toolsMenuItems as toolsConfig };
export { trainingMenuItems as trainingConfig }; 