import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Checkbox,
  Button,
  Stack,
  CircularProgress,
  FormControlLabel,
  Alert
} from '@mui/material';
import { TestData } from '../TestWizard';

interface QuestionListProps {
  testData: TestData;
  setTestData: (data: TestData | ((prev: TestData) => TestData)) => void;
  onNext: () => void;
  onBack: () => void;
}

const QuestionList: React.FC<QuestionListProps> = ({ testData, setTestData, onNext, onBack }) => {
  const [loading, setLoading] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  const API_URL = process.env.REACT_APP_TEST_GENERATOR_API || 'http://localhost:3005';

  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`${API_URL}/api/generate-questions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            propertyName: testData.propertyName,
            description: testData.description,
            topics: testData.selectedTopics,
            difficulty: testData.difficulty,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.questions) {
          setTestData(prev => ({
            ...prev,
            questions: data.questions
          }));
          // По умолчанию выбираем все вопросы
          setSelectedQuestions(data.questions.map((q: { id: string }) => q.id));
        } else {
          throw new Error('Invalid response format');
        }
      } catch (error) {
        console.error('Error fetching questions:', error);
        setError('Не удалось загрузить вопросы. Пожалуйста, попробуйте позже.');
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [API_URL, setTestData, testData.propertyName, testData.description, testData.selectedTopics, testData.difficulty]);

  const handleQuestionToggle = (questionId: string) => {
    const newSelectedQuestions = selectedQuestions.includes(questionId)
      ? selectedQuestions.filter(id => id !== questionId)
      : [...selectedQuestions, questionId];

    setSelectedQuestions(newSelectedQuestions);

    // Обновляем основной state только с выбранными вопросами
    setTestData(prev => {
      const filteredQuestions = prev.questions.filter(q => newSelectedQuestions.includes(q.id));
      return {
        ...prev,
        questions: filteredQuestions
      };
    });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight={400}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
        <Button onClick={onBack}>Назад</Button>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Выберите вопросы для теста
      </Typography>

      <Stack spacing={2} sx={{ mb: 3 }}>
        {testData.questions.map((question) => (
          <Card key={question.id}>
            <CardContent>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedQuestions.includes(question.id)}
                    onChange={() => handleQuestionToggle(question.id)}
                  />
                }
                label={
                  <Typography variant="subtitle1">
                    {question.question}
                  </Typography>
                }
              />
              
              <Box sx={{ ml: 4, mt: 1 }}>
                {question.options.map((option, index) => (
                  <Typography
                    key={index}
                    variant="body2"
                    color={index === question.correctAnswer ? 'success.main' : 'text.primary'}
                  >
                    {String.fromCharCode(65 + index)}. {option}
                    {index === question.correctAnswer && ' ✓'}
                  </Typography>
                ))}
              </Box>
            </CardContent>
          </Card>
        ))}
      </Stack>

      <Stack direction="row" spacing={2}>
        <Button onClick={onBack}>
          Назад
        </Button>
        <Button
          variant="contained"
          onClick={onNext}
          disabled={selectedQuestions.length === 0}
        >
          Далее
        </Button>
      </Stack>
    </Box>
  );
};

export default QuestionList; 