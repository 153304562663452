import React from 'react';
import { Box, Typography, Stack, Divider } from '@mui/material';
import { CalendarToday, Pool } from '@mui/icons-material';

const DetailItem = ({ icon: Icon, label, value }) => (
  <Box>
    <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 0.5 }}>
      <Icon sx={{ fontSize: 16, color: 'grey.500' }} />
      <Typography variant="caption" color="text.secondary">
        {label}
      </Typography>
    </Stack>
    <Typography variant="body2">{value}</Typography>
  </Box>
);

const ProjectDetails = ({ details }) => {
  const { units, deadline, status, distance, beach } = details;

  return (
    <Stack
      direction="row"
      spacing={3}
      divider={<Divider orientation="vertical" flexItem />}
      sx={{ mt: 2 }}
    >
      <DetailItem
        icon={CalendarToday}
        label="Units"
        value={`${units} units`}
      />
      <DetailItem
        icon={CalendarToday}
        label={status}
        value={deadline}
      />
      <DetailItem
        icon={Pool}
        label={beach}
        value={`${distance} m`}
      />
    </Stack>
  );
};

export default ProjectDetails; 