import React, { useState, useEffect } from 'react';
import { Box, ToggleButton, ToggleButtonGroup, Alert, Snackbar } from '@mui/material';
import { ViewList, Map as MapIcon, Favorite } from '@mui/icons-material';
import PropertiesMap from './PropertiesMap';
import PropertiesList from './PropertiesList';
import { useFavorites } from '../../hooks/useFavorites';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import { API_CONFIG } from '../../config/api';
import SwipeProperties from './SwipeProperties';

const Properties = () => {
  const [viewMode, setViewMode] = useState<'list' | 'map' | 'swipe'>('list');
  const [properties, setProperties] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { favorites, toggleFavorite } = useFavorites();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  // Загрузка данных
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const query = qs.stringify({
          populate: '*',
          pagination: {
            pageSize: 100
          }
        }, {
          encodeValuesOnly: true
        });

        const response = await fetch(`${API_CONFIG.BASE_URL}/objects?${query}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch properties');
        }
        
        const data = await response.json();
        setProperties(data.data || []);
      } catch (error) {
        console.error('Error fetching properties:', error);
        setError('Failed to load properties. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  const handleViewModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: 'list' | 'map' | 'swipe',
  ) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const handleMarkerClick = (propertyId: number) => {
    navigate(`/properties/${propertyId}`);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={handleViewModeChange}
          aria-label="view mode"
        >
          <ToggleButton value="list" aria-label="list view">
            <ViewList />
          </ToggleButton>
          <ToggleButton value="map" aria-label="map view">
            <MapIcon />
          </ToggleButton>
          <ToggleButton value="swipe" aria-label="swipe view">
            <Favorite />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {viewMode === 'list' ? (
        <PropertiesList 
          properties={properties}
          favorites={favorites}
          onToggleFavorite={toggleFavorite}
          loading={loading}
        />
      ) : viewMode === 'map' ? (
        <PropertiesMap
          properties={properties}
          favorites={favorites}
          onMarkerClick={handleMarkerClick}
        />
      ) : (
        <SwipeProperties onToggleFavorite={toggleFavorite} />
      )}

      {error && (
        <Snackbar 
          open={!!error} 
          autoHideDuration={6000} 
          onClose={() => setError(null)}
        >
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default Properties;