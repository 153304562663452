import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  IconButton,
  InputAdornment,
  Stack
} from '@mui/material';
import {
  Search as SearchIcon,
  FileDownloadOutlined,
  Add as AddIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { useClientRequests } from '../../hooks/useClientRequests';

const statusColors = {
  viewing_request: { color: 'primary', label: 'Viewing request' },
  viewed: { color: 'success', label: 'Viewed' },
  reservation: { color: 'warning', label: 'Reservation' },
  pending: { color: 'info', label: 'Pending' },
  signed: { color: 'success', label: 'Signed' },
  paid: { color: 'success', label: 'Paid' },
  cancelled: { color: 'error', label: 'Cancelled' },
  reserved: { color: 'success', label: 'Reserved' },
  closing: { color: 'warning', label: 'Closing' }
} as const;

const ClientRequests = () => {
  const {
    requests,
    totalRequests,
    filters,
    pagination,
    selectedRequests,
    handleFilterChange,
    handlePageChange,
    handleSelectAll,
    handleSelectRequest
  } = useClientRequests();

  const [activeFilters, setActiveFilters] = useState<Array<{ key: string; value: string }>>([]);

  const handleFilterChangeWithChip = (key: string, value: string) => {
    handleFilterChange(key as keyof typeof filters, value);
    if (value && !activeFilters.find(f => f.key === key)) {
      setActiveFilters(prev => [...prev, { key, value }]);
    }
  };

  const handleRemoveFilter = (filterKey: string) => {
    setActiveFilters(prev => prev.filter(f => f.key !== filterKey));
    handleFilterChange(filterKey as keyof typeof filters, '');
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Box>
          <Typography variant="h5">Client requests</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            View and manage client requests
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            startIcon={<FileDownloadOutlined />}
          >
            Export
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
          >
            Add client
          </Button>
        </Stack>
      </Box>

      {/* Tabs */}
      <Box sx={{ mb: 3 }}>
        <Stack direction="row" spacing={1}>
          <Chip 
            label={`All requests (${totalRequests})`}
            onClick={() => {}}
            color="primary"
            variant="filled"
          />
          <Chip 
            label="Recently updated (22)"
            onClick={() => {}}
            variant="outlined"
          />
        </Stack>
      </Box>

      {/* Filters */}
      <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
        <TextField
          placeholder="Search for requests"
          value={filters.search}
          onChange={(e) => handleFilterChangeWithChip('search', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
          sx={{ width: 300 }}
          size="small"
        />
        <Select
          value={filters.status}
          onChange={(e) => handleFilterChangeWithChip('status', e.target.value)}
          displayEmpty
          size="small"
          sx={{ minWidth: 200 }}
        >
          <MenuItem value="">Booking status</MenuItem>
          {Object.entries(statusColors).map(([key, { label }]) => (
            <MenuItem key={key} value={key}>{label}</MenuItem>
          ))}
        </Select>
        <Select
          value={filters.property}
          onChange={(e) => handleFilterChangeWithChip('property', e.target.value)}
          displayEmpty
          size="small"
          sx={{ minWidth: 200 }}
        >
          <MenuItem value="">Property</MenuItem>
          <MenuItem value="P0001">VIP Space Odyssey</MenuItem>
          <MenuItem value="P0002">VIP Galaxy Villas</MenuItem>
        </Select>
        <Select
          value={filters.assignee}
          onChange={(e) => handleFilterChangeWithChip('assignee', e.target.value)}
          displayEmpty
          size="small"
          sx={{ minWidth: 200 }}
        >
          <MenuItem value="">Assignee</MenuItem>
          <MenuItem value="1">Olivia Rhye</MenuItem>
          <MenuItem value="2">Phoenix Baker</MenuItem>
        </Select>
      </Stack>

      {/* Active Filters */}
      {activeFilters.length > 0 && (
        <Stack direction="row" spacing={1} sx={{ mb: 3 }}>
          {activeFilters.map(filter => (
            <Chip
              key={filter.key}
              label={filter.value}
              onDelete={() => handleRemoveFilter(filter.key)}
              deleteIcon={<CloseIcon />}
            />
          ))}
        </Stack>
      )}

      {/* Table */}
      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <input 
                  type="checkbox"
                  checked={selectedRequests.length === requests.length}
                  onChange={(e) => handleSelectAll(e.target.checked)}
                />
              </TableCell>
              <TableCell>Request №</TableCell>
              <TableCell>Contact info</TableCell>
              <TableCell>Agency</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Property ID</TableCell>
              <TableCell>Assignee</TableCell>
              <TableCell>Viewing date</TableCell>
              <TableCell>Reservation date</TableCell>
              <TableCell>Last Update</TableCell>
              <TableCell padding="none" />
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((request) => (
              <TableRow key={request.id}>
                <TableCell padding="checkbox">
                  <input 
                    type="checkbox"
                    checked={selectedRequests.includes(request.id)}
                    onChange={(e) => handleSelectRequest(request.id, e.target.checked)}
                  />
                </TableCell>
                <TableCell>{request.id}</TableCell>
                <TableCell>
                  <Box>
                    <Typography variant="body2">{request.contactInfo.name}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {request.contactInfo.phone}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{request.agency || '—'}</TableCell>
                <TableCell>
                  <Chip
                    label={statusColors[request.status].label}
                    color={statusColors[request.status].color}
                    size="small"
                  />
                </TableCell>
                <TableCell>{request.propertyId}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Avatar
                      src={request.assignee.avatar}
                      sx={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">{request.assignee.name}</Typography>
                  </Box>
                </TableCell>
                <TableCell>{request.viewingDate || '—'}</TableCell>
                <TableCell>{request.reservationDate || '—'}</TableCell>
                <TableCell>{request.lastUpdate}</TableCell>
                <TableCell padding="none">
                  <IconButton size="small">
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, py: 2 }}>
        <Stack direction="row" spacing={1}>
          {Array.from({ length: pagination.totalPages }, (_, i) => i + 1).map((page) => (
            <Button
              key={page}
              variant={page === pagination.page ? 'contained' : 'text'}
              size="small"
              onClick={() => handlePageChange(page)}
            >
              {page}
            </Button>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default ClientRequests; 