import React, { createContext, useContext, useState } from 'react';

interface Notification {
  id: number;
  type: 'success' | 'error' | 'warning' | 'info';
  message: string;
}

interface UserPreferences {
  currency: string;
  language: string;
  timezone: string;
}

interface AppContextType {
  theme: string;
  setTheme: (theme: string) => void;
  notifications: Notification[];
  addNotification: (notification: Omit<Notification, 'id'>) => void;
  removeNotification: (id: number) => void;
  userPreferences: UserPreferences;
  setUserPreferences: React.Dispatch<React.SetStateAction<UserPreferences>>;
}

const AppContext = createContext<AppContextType | null>(null);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState('light');
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [userPreferences, setUserPreferences] = useState<UserPreferences>({
    currency: '฿',
    language: 'en',
    timezone: 'Asia/Bangkok'
  });

  const addNotification = (notification: Omit<Notification, 'id'>) => {
    setNotifications(prev => [...prev, { id: Date.now(), ...notification }]);
  };

  const removeNotification = (id: number) => {
    setNotifications(prev => prev.filter(n => n.id !== id));
  };

  const value = {
    theme,
    setTheme,
    notifications,
    addNotification,
    removeNotification,
    userPreferences,
    setUserPreferences
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within AppProvider');
  }
  return context;
}; 