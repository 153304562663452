import { useState, useEffect } from 'react';
import { API_CONFIG } from '../config/api';

interface FilterOptions {
  types: Set<string>;
  locations: Set<string>;
  developers: Set<string>;
  finishings: Set<string>;
  house_classes: Set<string>;
  price_range: {
    min: number;
    max: number;
  };
  regions: Set<string>;
}

export const usePropertyFilters = () => {
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    types: new Set(),
    locations: new Set(),
    developers: new Set(),
    finishings: new Set(),
    house_classes: new Set(),
    price_range: {
      min: 0,
      max: 100000000
    },
    regions: new Set()
  });

  const [loading, setLoading] = useState(true);

  // Значения фильтров по умолчанию
  const defaultFilters = {
    search: '',
    type: '',
    location: '',
    developer: '',
    finishing: '',
    house_class: '',
    priceRange: [0, 100000000] as [number, number],
    region: ''
  };

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        setLoading(true);
        
        const response = await fetch(`${API_CONFIG.BASE_URL}/objects?pagination[pageSize]=1000&populate=*`);
        const data = await response.json();

        const options: FilterOptions = {
          types: new Set(),
          locations: new Set(),
          developers: new Set(),
          finishings: new Set(),
          house_classes: new Set(),
          price_range: {
            min: Infinity,
            max: -Infinity
          },
          regions: new Set()
        };

        data.data.forEach((item: any) => {
          const { attributes } = item;
          
          if (attributes.type) options.types.add(attributes.type);
          if (attributes.location) options.locations.add(attributes.location);
          if (attributes.developer) options.developers.add(attributes.developer);
          if (attributes.finishing) options.finishings.add(attributes.finishing);
          if (attributes.house_class) options.house_classes.add(attributes.house_class);
          if (attributes.region) options.regions.add(attributes.region);
          
          if (attributes.price) {
            const price = Number(attributes.price);
            if (!isNaN(price)) {
              options.price_range.min = Math.min(options.price_range.min, price);
              options.price_range.max = Math.max(options.price_range.max, price);
            }
          }
        });

        // Округляем цены до миллионов
        options.price_range.min = Math.floor(options.price_range.min / 1000000) * 1000000;
        options.price_range.max = Math.ceil(options.price_range.max / 1000000) * 1000000;

        setFilterOptions(options);
      } catch (error) {
        console.error('Error fetching filter options:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFilterOptions();
  }, []);

  const buildFilterQuery = (filters: any) => {
    const query: any = {
      filters: {}
    };

    // Поиск через Strapi API
    if (filters.search?.trim()) {
      query.filters.$or = [
        { name: { $containsi: filters.search.trim() } },
        { description: { $containsi: filters.search.trim() } },
        { address: { $containsi: filters.search.trim() } }
      ];
    }

    if (filters.type) {
      query.filters.type = { $eq: filters.type };
    }

    if (filters.location) {
      query.filters.location = { $eq: filters.location };
    }

    if (filters.developer) {
      query.filters.developer = { $eq: filters.developer };
    }

    if (filters.finishing) {
      query.filters.finishing = { $eq: filters.finishing };
    }

    if (filters.house_class) {
      query.filters.house_class = { $eq: filters.house_class };
    }

    if (filters.region) {
      query.filters.region = { $eq: filters.region };
    }

    if (filters.priceRange && Array.isArray(filters.priceRange) && filters.priceRange.length === 2) {
      query.filters.price = {
        $gte: filters.priceRange[0],
        $lte: filters.priceRange[1]
      };
    }

    return query;
  };

  return {
    filterOptions,
    loading,
    buildFilterQuery,
    defaultFilters
  };
}; 