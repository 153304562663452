import React from 'react';
import { Box, Card, Typography, IconButton } from '@mui/material';
import { MoreVert, ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import SmallChart from '../Charts/SmallChart';
import { alpha } from '@mui/material/styles';

const StatCard = ({ title, value, change }) => {
  const theme = useTheme();
  
  const percentStyles = {
    success: {
      color: theme.palette.success.main,
      backgroundColor: alpha(theme.palette.success.main, 0.1),
      padding: '2px 8px',
      borderRadius: '4px'
    },
    error: {
      color: theme.palette.error.main,
      backgroundColor: alpha(theme.palette.error.main, 0.1),
      padding: '2px 8px',
      borderRadius: '4px'
    }
  };

  const displayValue = title === 'totalSalesPercentage' ? '+17%' : value;
  const displayChange = title === 'totalSalesPercentage' ? 17 : change;
  const isPositive = title === 'totalSalesPercentage' ? true : change > 0;

  return (
    <Card
      sx={{
        p: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: `1px solid ${theme.palette.grey[200]}`,
        boxShadow: 'none',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="subtitle2" sx={{ color: theme.palette.grey[600] }}>
          {title}
        </Typography>
        <IconButton size="small">
          <MoreVert fontSize="small" />
        </IconButton>
      </Box>

      <Box>
        <Typography variant="h5" sx={{ mb: 1 }}>
          {displayValue}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 2 }}>
          {isPositive ? (
            <ArrowUpward
              sx={{
                fontSize: 16,
                color: theme.palette.success.main,
              }}
            />
          ) : (
            <ArrowDownward
              sx={{
                fontSize: 16,
                color: theme.palette.error.main,
              }}
            />
          )}
          <Typography
            variant="body2"
            sx={{
              ...percentStyles[isPositive ? 'success' : 'error'],
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {Math.abs(displayChange)}% vs last month
          </Typography>
        </Box>
        <SmallChart isPositive={isPositive} />
      </Box>
    </Card>
  );
};

export default StatCard; 