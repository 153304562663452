import React from 'react';
import { Box } from '@mui/material';
import PropertyMap from '../../components/Map/PropertyMap';

interface PropertiesMapProps {
  properties: any[];
  favorites: number[];
  onMarkerClick: (id: number) => void;
}

const PropertiesMap: React.FC<PropertiesMapProps> = ({ 
  properties, 
  favorites, 
  onMarkerClick 
}) => {
  return (
    <Box sx={{ height: 'calc(100vh - 200px)' }}>
      <PropertyMap 
        properties={properties}
        favorites={favorites}
        onMarkerClick={onMarkerClick}
      />
    </Box>
  );
};

export default PropertiesMap; 