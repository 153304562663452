import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  Chip,
  IconButton,
  CardMedia,
  Skeleton,
  Fade
} from '@mui/material';
import {
  LocationOn,
  Home,
  Business,
  Favorite,
  FavoriteBorder,
  Square as SquareIcon
} from '@mui/icons-material';

interface PropertyCardProps {
  property: any;
  isFavorite: boolean;
  onToggleFavorite: () => void;
}

const PropertyCard = ({ property, isFavorite, onToggleFavorite }: PropertyCardProps) => {
  const navigate = useNavigate();
  const { attributes: p } = property;
  const [currentImage, setCurrentImage] = useState(0);
  const [showGallery, setShowGallery] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const images = p.image?.data || [];

  const handleCardClick = () => {
    navigate(`/properties/${property.id}`);
  };

  const handleFavoriteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onToggleFavorite();
  };

  const handleGalleryClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleImageLoad = useCallback(() => {
    setImageLoading(false);
  }, []);

  const imageUrl = useCallback((img: any, isThumbnail = false) => {
    if (!img) return '/placeholder.jpg';
    
    if (isThumbnail && img.attributes.formats?.thumbnail?.url) {
      return `${process.env.REACT_APP_MEDIA_URL}${img.attributes.formats.thumbnail.url}`;
    }
    
    return `${process.env.REACT_APP_MEDIA_URL}${img.attributes.url}`;
  }, []);

  const handleMouseEnter = useCallback(() => {
    setShowGallery(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowGallery(false);
  }, []);

  return (
    <Card 
      onClick={handleCardClick}
      sx={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: 6,
        }
      }}
    >
      <Box 
        sx={{ 
          position: 'relative',
          paddingTop: '56.25%', // 16:9 aspect ratio
          backgroundColor: 'grey.100',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* Thumbnail для быстрой загрузки */}
        <CardMedia
          component="img"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            filter: imageLoading ? 'blur(10px)' : 'none',
            transition: 'filter 0.3s ease-in-out',
          }}
          src={imageUrl(images[currentImage], true)}
          alt={p.name}
        />

        {/* Основное изображение */}
        <Fade in={!imageLoading} timeout={300}>
          <CardMedia
            component="img"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              opacity: imageLoading ? 0 : 1,
              transition: 'opacity 0.3s ease-in-out',
            }}
            src={imageUrl(images[currentImage])}
            alt={p.name}
            onLoad={handleImageLoad}
          />
        </Fade>

        {/* Галерея */}
        {images.length > 1 && showGallery && (
          <Fade in={showGallery} timeout={200}>
            <Box
              onClick={handleGalleryClick}
              sx={{
                position: 'absolute',
                bottom: 8,
                left: 8,
                right: 8,
                bgcolor: 'rgba(0, 0, 0, 0.7)',
                borderRadius: 1,
                p: 1,
                zIndex: 1,
              }}
            >
              <Stack 
                direction="row" 
                spacing={1}
                sx={{
                  overflowX: 'auto',
                  '&::-webkit-scrollbar': { display: 'none' },
                  msOverflowStyle: 'none',
                  scrollbarWidth: 'none',
                }}
              >
                {images.map((img: any, index: number) => (
                  <Box
                    key={index}
                    onMouseEnter={() => setCurrentImage(index)}
                    sx={{
                      width: 40,
                      height: 40,
                      flexShrink: 0,
                      borderRadius: 1,
                      overflow: 'hidden',
                      border: index === currentImage ? '2px solid white' : 'none',
                      opacity: index === currentImage ? 1 : 0.6,
                      transition: 'all 0.2s ease-in-out',
                      '&:hover': { opacity: 1 }
                    }}
                  >
                    <Box
                      component="img"
                      src={imageUrl(img, true)}
                      alt=""
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                ))}
              </Stack>
            </Box>
          </Fade>
        )}

        {/* Остальные элементы (кнопка избранного, цена) */}
        <IconButton
          onClick={handleFavoriteClick}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            bgcolor: 'background.paper',
            '&:hover': { bgcolor: 'grey.100' },
            zIndex: 1
          }}
        >
          {isFavorite ? <Favorite color="error" /> : <FavoriteBorder />}
        </IconButton>

        {p.price && (
          <Chip
            label={`${new Intl.NumberFormat('ru-RU').format(Number(p.price))} ₽`}
            sx={{
              position: 'absolute',
              top: 8,
              left: 8,
              bgcolor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              fontWeight: 'bold',
              zIndex: 1
            }}
          />
        )}
      </Box>

      <CardContent sx={{ flexGrow: 1 }}>
        <Stack spacing={2}>
          <Box>
            <Typography variant="h6" gutterBottom>
              {p.name}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <LocationOn fontSize="small" color="action" />
              <Typography variant="body2" color="text.secondary">
                {p.address?.split(',')[0]}
              </Typography>
            </Stack>
          </Box>

          <Stack spacing={1}>
            {p.type && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Home fontSize="small" color="action" />
                <Typography variant="body2">{p.type}</Typography>
              </Stack>
            )}
            
            {p.developer && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Business fontSize="small" color="action" />
                <Typography variant="body2">{p.developer}</Typography>
              </Stack>
            )}

            {p.square && (
              <Stack direction="row" spacing={1} alignItems="center">
                <SquareIcon fontSize="small" color="action" />
                <Typography variant="body2">{p.square} м²</Typography>
              </Stack>
            )}
          </Stack>

          {p.finishing && (
            <Chip 
              label={p.finishing}
              size="small"
              sx={{ alignSelf: 'flex-start' }}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PropertyCard;