import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Card, Typography, IconButton, Stack, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocationOn, Favorite, Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { API_CONFIG } from '../../config/api';
import SwipeCardSkeleton from '../../components/Properties/SwipeCardSkeleton';

const SwipeContainer = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 100px)',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  }
}));

const CardContainer = styled(Box)({
  width: '100%',
  maxWidth: 400,
  height: '70vh',
  position: 'relative',
  margin: '0 auto'
});

interface SwipeCardProps {
  transform?: string;
  isDragging?: boolean;
  isActive?: boolean;
}

const SwipeCard = styled(Card)<SwipeCardProps>(({ theme, transform, isDragging, isActive }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  borderRadius: theme.shape.borderRadius * 2,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  boxShadow: theme.shadows[8],
  transform: transform || 'none',
  transition: isDragging ? 'none' : 'all 0.3s ease',
  cursor: 'grab',
  '&:active': {
    cursor: 'grabbing'
  },
  touchAction: 'none',
  userSelect: 'none',
  '&:hover': isActive ? {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[12],
  } : {},
}));

const CardContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  background: 'linear-gradient(to top, rgba(0,0,0,0.8), transparent)',
  padding: theme.spacing(2),
  color: 'white'
}));

const ActionButtons = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(4),
  left: '50%',
  transform: 'translateX(-50%)',
  flexDirection: 'row',
  gap: theme.spacing(2),
  zIndex: 1000
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.grey[100]
  },
  padding: theme.spacing(2)
}));

const SWIPE_THRESHOLD = 100;

const SwipeProperties = ({ onToggleFavorite }: { onToggleFavorite: (id: number) => void }) => {
  const [properties, setProperties] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const cardRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [mouseDown, setMouseDown] = useState(false);
  const [preloadedImages, setPreloadedImages] = useState<string[]>([]);
  const [activeCard, setActiveCard] = useState<number | null>(null);

  const preloadImages = useCallback((propertyList: any[]) => {
    propertyList.forEach((property) => {
      const image = property.attributes.image?.data?.[0];
      if (image) {
        const url = `${API_CONFIG.MEDIA_URL}${image.attributes.url}`;
        const img = new Image();
        img.src = url;
        setPreloadedImages(prev => [...prev, url]);
      }
    });
  }, []);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${API_CONFIG.BASE_URL}/objects?populate=*`);
        const data = await response.json();
        const propertiesData = data.data || [];
        setProperties(propertiesData);
        preloadImages(propertiesData);
      } catch (error) {
        console.error('Error fetching properties:', error);
      } finally {
        setTimeout(() => setIsLoading(false), 500);
      }
    };

    fetchProperties();
  }, [preloadImages]);

  const handleDragStart = useCallback((e: React.MouseEvent | React.TouchEvent) => {
    if (isAnimating) return;
    
    setIsDragging(true);
    setMouseDown(true);
    
    const point = 'touches' in e ? e.touches[0] : e;
    setDragStart({ x: point.clientX, y: point.clientY });
    
    e.preventDefault();
  }, [isAnimating]);

  const handleDragMove = useCallback((e: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging || isAnimating || !mouseDown) return;
    
    const point = 'touches' in e ? e.touches[0] : e;
    const offsetX = point.clientX - dragStart.x;
    const offsetY = point.clientY - dragStart.y;
    
    setDragOffset({ x: offsetX, y: offsetY });
    e.preventDefault();
  }, [isDragging, isAnimating, mouseDown, dragStart.x, dragStart.y]);

  const handleSwipe = useCallback((direction: string) => {
    if (currentIndex >= properties.length || isAnimating) return;

    setIsAnimating(true);
    const property = properties[currentIndex];
    
    if (direction === 'right') {
      onToggleFavorite(property.id);
    }

    if (currentIndex + 3 < properties.length) {
      preloadImages(properties.slice(currentIndex + 3, currentIndex + 6));
    }

    setDragOffset({
      x: direction === 'right' ? window.innerWidth : -window.innerWidth,
      y: 0
    });

    setTimeout(() => {
      setCurrentIndex(prev => prev + 1);
      setDragOffset({ x: 0, y: 0 });
      setIsAnimating(false);
    }, 300);
  }, [currentIndex, properties, isAnimating, onToggleFavorite, preloadImages]);

  const handleDragEnd = useCallback(() => {
    if (!isDragging || isAnimating) return;
    
    setIsDragging(false);
    setMouseDown(false);

    if (Math.abs(dragOffset.x) > SWIPE_THRESHOLD) {
      const direction = dragOffset.x > 0 ? 'right' : 'left';
      handleSwipe(direction);
    } else {
      setDragOffset({ x: 0, y: 0 });
    }
  }, [isDragging, isAnimating, dragOffset.x, handleSwipe]);

  useEffect(() => {
    const handleGlobalMouseMove = (e: MouseEvent) => {
      if (mouseDown) {
        handleDragMove(e as unknown as React.MouseEvent);
      }
    };

    const handleGlobalMouseUp = () => {
      if (mouseDown) {
        handleDragEnd();
      }
    };

    if (mouseDown) {
      document.addEventListener('mousemove', handleGlobalMouseMove);
      document.addEventListener('mouseup', handleGlobalMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleGlobalMouseMove);
      document.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, [mouseDown, handleDragMove, handleDragEnd]);

  const handleCardClick = (propertyId: number) => {
    if (!isDragging && !isAnimating) {
      navigate(`/properties/${propertyId}`);
    }
  };

  const getImageUrl = (property: any) => {
    const image = property.attributes.image?.data?.[0];
    return image 
      ? `${API_CONFIG.MEDIA_URL}${image.attributes.url}`
      : '/placeholder.jpg';
  };

  const handleMouseEnter = useCallback((index: number) => {
    if (index === 0) {
      setActiveCard(index);
    }
  }, []);

  const handleMouseLeave = useCallback(() => {
    setActiveCard(null);
  }, []);

  if (isLoading) {
    return (
      <SwipeContainer>
        <CardContainer>
          <SwipeCardSkeleton />
        </CardContainer>
      </SwipeContainer>
    );
  }

  const currentProperty = properties[currentIndex];
  if (!currentProperty) {
    return (
      <SwipeContainer>
        <Typography variant="h6">No more properties to show</Typography>
      </SwipeContainer>
    );
  }

  const rotation = (dragOffset.x / SWIPE_THRESHOLD) * 15;
  const transform = `translate(${dragOffset.x}px, ${dragOffset.y}px) rotate(${rotation}deg)`;

  return (
    <SwipeContainer>
      <CardContainer>
        {properties.slice(currentIndex, currentIndex + 3).map((property, index) => (
          <SwipeCard
            key={property.id}
            ref={index === 0 ? cardRef : undefined}
            transform={index === 0 ? transform : `scale(${1 - index * 0.05}) translateY(${index * 10}px)`}
            isDragging={index === 0 && isDragging}
            isActive={index === activeCard}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onMouseDown={index === 0 ? handleDragStart : undefined}
            onTouchStart={index === 0 ? handleDragStart : undefined}
            onTouchMove={index === 0 ? handleDragMove : undefined}
            onTouchEnd={index === 0 ? handleDragEnd : undefined}
            onClick={() => index === 0 && !isDragging && handleCardClick(property.id)}
            sx={{
              backgroundImage: `url(${getImageUrl(property)})`,
              zIndex: properties.length - index
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {property.attributes.name}
              </Typography>
              <Stack spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <LocationOn fontSize="small" />
                  <Typography variant="body2">
                    {property.attributes.address?.split(',')[0]}
                  </Typography>
                </Stack>
                {property.attributes.price && (
                  <Chip
                    label={`${new Intl.NumberFormat('ru-RU').format(Number(property.attributes.price))} ₽`}
                    sx={{ alignSelf: 'flex-start', bgcolor: 'primary.main', color: 'white' }}
                  />
                )}
              </Stack>
            </CardContent>
          </SwipeCard>
        ))}
      </CardContainer>

      <ActionButtons>
        <ActionButton 
          onClick={() => handleSwipe('left')} 
          color="error"
          disabled={isAnimating}
        >
          <Close fontSize="large" />
        </ActionButton>
        <ActionButton 
          onClick={() => handleSwipe('right')} 
          color="success"
          disabled={isAnimating}
        >
          <Favorite fontSize="large" />
        </ActionButton>
      </ActionButtons>
    </SwipeContainer>
  );
};

export default SwipeProperties; 