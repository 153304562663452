import React from 'react';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
  Chip,
  InputAdornment,
  Slider,
  Typography,
  Button
} from '@mui/material';
import { Search, RestartAlt } from '@mui/icons-material';

interface PropertyFiltersProps {
  filters: any;
  filterOptions: any;
  onFilterChange: (key: string, value: any) => void;
  onReset: () => void;
}

const PropertyFilters = ({ filters, filterOptions, onFilterChange, onReset }: PropertyFiltersProps) => {
  const [activeFilters, setActiveFilters] = React.useState<Array<{ key: string; value: string }>>([]);

  const handleFilterChange = (key: string, value: any) => {
    const newValue = value?.trim?.() !== undefined ? value.trim() : value;
    onFilterChange(key, newValue);
    
    if (newValue && !activeFilters.find(f => f.key === key)) {
      setActiveFilters(prev => [...prev, { key, value: newValue.toString() }]);
    } else if (!newValue) {
      setActiveFilters(prev => prev.filter(f => f.key !== key));
    } else {
      setActiveFilters(prev => prev.map(f => f.key === key ? { ...f, value: newValue.toString() } : f));
    }
  };

  const handleRemoveFilter = (filterKey: string) => {
    setActiveFilters(prev => prev.filter(f => f.key !== filterKey));
    onFilterChange(filterKey, '');
  };

  const handleReset = () => {
    setActiveFilters([]);
    onReset();
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <TextField
          placeholder="Поиск по названию, описанию или адресу"
          value={filters.search}
          onChange={(e) => handleFilterChange('search', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          fullWidth
        />

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Регион</InputLabel>
          <Select
            value={filters.region}
            onChange={(e) => handleFilterChange('region', e.target.value)}
            label="Регион"
          >
            <MenuItem value="">Все регионы</MenuItem>
            {Array.from(filterOptions.regions).map((region) => (
              <MenuItem key={region as string} value={region as string}>
                {region as string}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          startIcon={<RestartAlt />}
          onClick={handleReset}
          variant="outlined"
          color="primary"
        >
          Сбросить фильтры
        </Button>
      </Stack>

      <Box sx={{ px: 2 }}>
        <Typography gutterBottom>Ценовой диапазон</Typography>
        <Slider
          value={filters.priceRange}
          onChange={(_, newValue) => handleFilterChange('priceRange', newValue)}
          valueLabelDisplay="auto"
          min={filterOptions.price_range.min}
          max={filterOptions.price_range.max}
          step={1000000}
          valueLabelFormat={(value) => `${(value / 1000000).toFixed(1)}M ₽`}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="caption">
            {(filterOptions.price_range.min / 1000000).toFixed(1)}M ₽
          </Typography>
          <Typography variant="caption">
            {(filterOptions.price_range.max / 1000000).toFixed(1)}M ₽
          </Typography>
        </Box>
      </Box>

      {activeFilters.length > 0 && (
        <Stack direction="row" spacing={1} flexWrap="wrap">
          {activeFilters.map(filter => (
            <Chip
              key={filter.key}
              label={filter.value}
              onDelete={() => handleRemoveFilter(filter.key)}
              sx={{ m: 0.5 }}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default PropertyFilters; 