import { Box, Typography } from '@mui/material';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default marker icon
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

interface MapLocationProps {
  location: {
    lat: number;
    lng: number;
  };
  onChange: (location: { lat: number; lng: number }) => void;
}

const LocationMarker = ({ location, onChange }: MapLocationProps) => {
  useMapEvents({
    click(e) {
      onChange({ lat: e.latlng.lat, lng: e.latlng.lng });
    },
  });

  return location.lat !== 0 ? (
    <Marker position={[location.lat, location.lng]} />
  ) : null;
};

export const MapLocation = ({ location, onChange }: MapLocationProps) => {
  const defaultCenter = { lat: 55.7558, lng: 37.6173 }; // Moscow coordinates
  const center = location.lat !== 0 ? location : defaultCenter;

  return (
    <Box>
      <Typography variant="subtitle2" mb={1}>
        Кликните на карте, чтобы указать местоположение объекта
      </Typography>
      <Box sx={{ height: 400, border: '1px solid #e0e0e0', borderRadius: 1 }}>
        <MapContainer
          center={[center.lat, center.lng]}
          zoom={13}
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <LocationMarker location={location} onChange={onChange} />
        </MapContainer>
      </Box>
      {location.lat !== 0 && (
        <Typography variant="caption" color="textSecondary" mt={1}>
          Координаты: {location.lat.toFixed(6)}, {location.lng.toFixed(6)}
        </Typography>
      )}
    </Box>
  );
}; 