import React from 'react';
import {
  Box,
  Card,
  Typography,
  Stack,
  Button,
  IconButton,
  Chip,
  Link
} from '@mui/material';
import {
  Add as AddIcon,
  FileDownload as FileDownloadIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material';
import { companyData } from '../../../data/company';

const statusColors = {
  active: { color: 'success', label: 'Active' },
  expired: { color: 'error', label: 'Expired' },
  pending: { color: 'warning', label: 'Pending' }
} as const;

const LicenseList = () => {
  return (
    <Card sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h6">
          Licenses & certifications ({companyData.licenses.length})
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          size="small"
        >
          Add License
        </Button>
      </Box>

      <Stack spacing={2}>
        {companyData.licenses.map((license) => (
          <Box
            key={license.id}
            sx={{
              p: 2,
              border: '1px solid',
              borderColor: 'grey.200',
              borderRadius: 1,
              '&:hover': {
                bgcolor: 'grey.50'
              }
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Stack spacing={1}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {license.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {license.issuer}
                </Typography>
              </Stack>
              <IconButton size="small">
                <MoreVertIcon />
              </IconButton>
            </Box>

            <Stack direction="row" spacing={2} alignItems="center">
              <Chip
                label={statusColors[license.status].label}
                color={statusColors[license.status].color}
                size="small"
              />
              <Typography variant="caption" color="text.secondary">
                Issued: {license.issuedDate}
              </Typography>
              {license.expiryDate && (
                <Typography variant="caption" color="text.secondary">
                  Expires: {license.expiryDate}
                </Typography>
              )}
              {license.documentUrl && (
                <Link
                  href={license.documentUrl}
                  target="_blank"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    ml: 'auto !important'
                  }}
                >
                  <FileDownloadIcon sx={{ fontSize: 16 }} />
                  <Typography variant="caption">Download</Typography>
                </Link>
              )}
            </Stack>
          </Box>
        ))}
      </Stack>
    </Card>
  );
};

export default LicenseList; 