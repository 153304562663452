import React from 'react';
import { Chip, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ProjectBadge = ({ type, value, position = 'top-left' }) => {
  const theme = useTheme();

  const getPositionStyles = () => {
    const positions = {
      'top-left': { top: 16, left: 16 },
      'top-right': { top: 16, right: 16 },
      'bottom-left': { bottom: 16, left: 16 },
      'bottom-right': { bottom: 16, right: 16 },
    };
    return positions[position];
  };

  const getBadgeStyles = () => {
    const styles = {
      guarantee: {
        bgcolor: 'rgba(255, 255, 255, 0.9)',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
      },
      discount: {
        bgcolor: theme.palette.error.main,
        color: 'white',
      },
    };
    return styles[type];
  };

  return (
    <Box sx={{ position: 'absolute', ...getPositionStyles() }}>
      <Chip
        label={value}
        sx={{
          ...getBadgeStyles(),
          height: 28,
          fontSize: '0.875rem',
          fontWeight: 500,
        }}
      />
    </Box>
  );
};

export default ProjectBadge; 