import React, { useEffect, useRef, useCallback } from 'react';
import { Grid, Box, CircularProgress } from '@mui/material';
import { usePropertyFilters } from '../../hooks/usePropertyFilters';
import { PropertyCard, PropertyCardSkeleton } from '../../components/Properties';
import PropertyFilters from './PropertyFilters';
import { API_CONFIG } from '../../config/api';
import qs from 'qs';

interface PropertiesListProps {
  properties: any[];
  favorites: number[];
  onToggleFavorite: (id: number) => void;
  loading: boolean;
}

const PropertiesList = ({ 
  properties: initialProperties, 
  favorites, 
  onToggleFavorite,
  loading: initialLoading 
}: PropertiesListProps) => {
  const [properties, setProperties] = React.useState(initialProperties);
  const [loading, setLoading] = React.useState(initialLoading);
  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(true);
  const loader = useRef(null);

  const { filterOptions, defaultFilters, buildFilterQuery } = usePropertyFilters();
  const [filters, setFilters] = React.useState(defaultFilters);

  const fetchProperties = async (pageNumber: number) => {
    try {
      setLoading(true);
      const filterQuery = buildFilterQuery(filters);
      const query = qs.stringify({
        ...filterQuery,
        pagination: {
          page: pageNumber,
          pageSize: 9
        },
        populate: '*'
      }, {
        encodeValuesOnly: true
      });

      const response = await fetch(`${API_CONFIG.BASE_URL}/objects?${query}`);
      const data = await response.json();

      if (pageNumber === 1) {
        setProperties(data.data);
      } else {
        setProperties(prev => [...prev, ...data.data]);
      }

      setHasMore(data.meta.pagination.page < data.meta.pagination.pageCount);
    } catch (error) {
      console.error('Error fetching properties:', error);
    } finally {
      setLoading(false);
    }
  };

  // Обработчик для Intersection Observer
  const handleObserver = useCallback((entries: IntersectionObserverEntry[]) => {
    const target = entries[0];
    if (target.isIntersecting && hasMore && !loading) {
      setPage(prev => prev + 1);
    }
  }, [hasMore, loading]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    
    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => observer.disconnect();
  }, [handleObserver]);

  // Загрузка при изменении фильтров или страницы
  useEffect(() => {
    if (page === 1) {
      fetchProperties(1);
    } else {
      setPage(1);
    }
  }, [filters]);

  useEffect(() => {
    if (page > 1) {
      fetchProperties(page);
    }
  }, [page]);

  const handleFilterChange = (key: string, value: any) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const handleReset = () => {
    setFilters(defaultFilters);
    setPage(1);
  };

  // Добавляем массив для шиммеров
  const skeletonArray = Array(6).fill(null);

  return (
    <Box>
      <PropertyFilters 
        filters={filters}
        filterOptions={filterOptions}
        onFilterChange={handleFilterChange}
        onReset={handleReset}
      />
      
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {loading && page === 1 ? (
          // Показываем шиммеры только при первой загрузке
          skeletonArray.map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={`skeleton-${index}`}>
              <PropertyCardSkeleton />
            </Grid>
          ))
        ) : properties.length > 0 ? (
          // Показываем карточки объектов
          properties.map((property) => (
            <Grid item xs={12} sm={6} md={4} key={property.id}>
              <PropertyCard 
                property={property}
                isFavorite={favorites.includes(property.id)}
                onToggleFavorite={() => onToggleFavorite(property.id)}
              />
            </Grid>
          ))
        ) : (
          // Показываем сообщение, если нет объектов
          <Box sx={{ p: 3, textAlign: 'center', width: '100%' }}>
            No properties found
          </Box>
        )}
      </Grid>

      {/* Loader для бесконечной прокрутки */}
      {!loading && hasMore && (
        <Box 
          ref={loader}
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            p: 4
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* Индикатор загрузки при подгрузке следующей страницы */}
      {loading && page > 1 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default PropertiesList;