import React, { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  Button,
  Stack,
  CircularProgress,
  Typography,
  Alert
} from '@mui/material';
import { TestData } from '../TestWizard';

interface TopicListProps {
  testData: TestData;
  setTestData: (data: TestData) => void;
  onNext: () => void;
  onBack: () => void;
}

const TopicList: React.FC<TopicListProps> = ({ testData, setTestData, onNext, onBack }) => {
  const [loading, setLoading] = useState(false);
  const [topics, setTopics] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  const API_URL = process.env.REACT_APP_TEST_GENERATOR_API || 'http://localhost:3005';

  useEffect(() => {
    const fetchTopics = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`${API_URL}/api/generate-topics`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            propertyName: testData.propertyName,
            description: testData.description,
            difficulty: testData.difficulty,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.topics && Array.isArray(data.topics)) {
          setTopics(data.topics);
        } else {
          throw new Error('Invalid response format');
        }
      } catch (error) {
        console.error('Error fetching topics:', error);
        setError('Не удалось загрузить темы. Пожалуйста, попробуйте позже.');
      } finally {
        setLoading(false);
      }
    };

    fetchTopics();
  }, [testData.propertyName, testData.description, testData.difficulty, API_URL]);

  const handleTopicToggle = (topic: string) => () => {
    const currentIndex = testData.selectedTopics.indexOf(topic);
    const newSelectedTopics = [...testData.selectedTopics];

    if (currentIndex === -1) {
      newSelectedTopics.push(topic);
    } else {
      newSelectedTopics.splice(currentIndex, 1);
    }

    setTestData({
      ...testData,
      selectedTopics: newSelectedTopics,
    });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight={400}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
        <Button onClick={onBack}>Назад</Button>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Выберите темы для теста
      </Typography>
      
      {topics.length > 0 ? (
        <List>
          {topics.map((topic) => (
            <ListItem
              key={topic}
              dense
              button
              onClick={handleTopicToggle(topic)}
            >
              <Checkbox
                edge="start"
                checked={testData.selectedTopics.includes(topic)}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={topic} />
            </ListItem>
          ))}
        </List>
      ) : (
        <Alert severity="info" sx={{ mb: 2 }}>
          Нет доступных тем. Попробуйте изменить параметры поиска.
        </Alert>
      )}

      <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
        <Button onClick={onBack}>
          Назад
        </Button>
        <Button
          variant="contained"
          onClick={onNext}
          disabled={testData.selectedTopics.length === 0}
        >
          Далее
        </Button>
      </Stack>
    </Box>
  );
};

export default TopicList; 